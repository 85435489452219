import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import {
    empty
} from '__globals/global-functions';
import * as GC from "../../../../../__globals/GLOBAL-CONSTANTS";
import StandardContent from "../../../../Variations/CustomContent/StandardContent";
import CustomCarousel from "../../../../Variations/CustomContent/CustomCarousel";

export const CustomSelect = props => {

    /* LOCAL VARIABLES */
    const [currentValue, setCurrentValue] = useState('');
    const onChangeCurrentValue = e => {
        //alert(JSON.stringify(e.target));
        //props.handleSelectChange( props.name, props.dependent );
        setCurrentValue(e.target.value) ;
    }

    const [tabIndex, setTabIndex] = useState(1);

    const [placeholder, setPlaceholder] = useState("CUSTOM SELECT");

    const [errorMsgRequired, setErrorMsgRequired] = useState("Please include your custom text.");

    const [required, setRequired] = useState(true);

    const [subName, setSubName] = useState("subName");

    const [optionList, setOptionList] = useState( [] );


    /* FUNCTIONS */
    /* Checks the validations for this element */
    const checkValidation = () => {
        if ( required ) {
            if ( empty(currentValue) ) {
                return errorMsgRequired;
            } else {
                return null;
            }
        }
    }

    const getOptions = () => {
        let index = -1;
        let items = optionList.map((option, key) => {
            return (
                <option value={option.key} key={option.key}>{option.value}</option>
            );
        });
        return items;
    }


    /* HOOKS */
    /* Check validation and send data to the parent whenever the currentValue changes */
    useEffect(() => {
        let valMessage = checkValidation();
        let isValid = true;
        if ( !empty(valMessage) ) {
            isValid = false;
        }
        let vJSON = {};
        vJSON.name = props.name;
        vJSON.subName = props.fieldsInUse[props.name].subName;
        vJSON.subKey = props.fieldsInUse[props.name].subKey;
        vJSON.val = currentValue;
        vJSON.isValid = isValid;
        vJSON.valMessage = valMessage;
        props.updateFormValues( vJSON );
    }, [currentValue, errorMsgRequired] );

    /* Set additional fields from the promotion "fieldsInUse" config */
    useEffect(() => {
        if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
            let subName = props.fieldsInUse[props.name].subName;
            setSubName( subName );
            if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
                setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
            }
            if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
                setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
            }
            if ( !empty(props.fieldsInUse[props.name].required) ) {
                setRequired( props.fieldsInUse[props.name].required );
            }
            if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
                setTabIndex( props.fieldsInUse[props.name].tabIndex );
            }
            if ( !empty(props.fieldsInUse[props.name].optionList) ) {
                setOptionList( props.fieldsInUse[props.name].optionList );
            }
        }
    }, [props.fieldsInUse] );

    return (
        <div id={"dv_" + props.name} className={"cls-" + props.name}>
            <div id="custom-select" className="select-wrapper">
                <select
                    className="NOTselected"
                    id="select"
                    name={props.name}
                    tabIndex={tabIndex}
                    value={currentValue}
                    onChange={onChangeCurrentValue}
                    required>
                    <option value=''>{placeholder}</option>
                    {getOptions()}
                </select>
            </div>
        </div>
    );


}

export default CustomSelect;

