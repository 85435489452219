import axios from 'axios';
import {	logIt,
			inspectFormData } from '__globals/global-functions';

const promotionDomain = window.REACT_APP_PROMOTION_DOMAIN;
const protocol = "https://";


export async function postLoginUser( loginData )
{
    const url = protocol + promotionDomain + '/promotion-login';
    const {data} = await axios.post(url, loginData);
    return data;
}

export async function postForgotPassword( loginData )
{
    const url = protocol + promotionDomain + '/promotion-forgot-password';
    const {data} = await axios.post(url, loginData);
    return data;
}

export async function postResetPassword( loginData )
{
    const url = protocol + promotionDomain + '/promotion-change-password';
    const {data} = await axios.post(url, loginData);
    return data;
}

export async function postRegisterUser( registerData ) {
    const url = protocol + promotionDomain + '/promotion-register';
    const {data} = await axios.post(url, registerData);
    return data;
}

export async function postRetrieveSecurityQuestion( jsonData )
{
    const url = protocol + promotionDomain + '/promotion-security-question';
    logIt( "URL at postRetrieveSecurityQuestion", url, false );
    const {data} = await axios.post(url, jsonData);
    return data;
}

export async function postRetrieveAccountData( tokenJSON )
{
    const url = protocol + promotionDomain + '/retrieve-account-data';
    logIt( "URL at postRetrieveAccountData", url, false );
    const {data} = await axios.post(url, tokenJSON);
    return data;
}

export async function postUpdateAccountData( accountData ) {
	const url = protocol + promotionDomain + '/update-account-data';
    const {data} = await axios.post(url, accountData);
    return data;
}

export async function postRetrieveGroupedEntries( jsonData )
{
	const url = protocol + promotionDomain + '/retrieve-grouped-entries';
    const {data} = await axios.post(url, jsonData);
    return data;
}

export async function postRetrievePromotionEntries( jsonData )
{
	const url = protocol + promotionDomain + '/retrieve-promotion-entries';
    const {data} = await axios.post(url, jsonData);
    return data;
}



