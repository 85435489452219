import React from 'react';
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

import {
    logIt,
    DEBUG,
    empty,
    isTestMode,
    doesVariationExist,
    isVariationOn,
    isNoReg,
    isPickYourPrize,
    useSplash,
    isPageAlwaysAllowed,
    isRafflePromo,
    isFileUpload, getSlashSourceFromUrlString
} from '__globals/global-functions';

import {
    hasPromoStarted,
    hasPromoEnded
} from '__globals/date-functions';
import {buildError} from "../../__globals/error-handling";
import * as GC from "../../__globals/GLOBAL-CONSTANTS";
import {
    isPromocracyOn, isRaffleTicketValidatorCode,
    retrievePromocracyParticipant,
    retrieveRaffleLocalStorage
} from "__globals/promocracy-functions";
import {PROMO_TYPE_IW_POKER_DICE} from "../../__globals/GLOBAL-CONSTANTS";


const PageFlow = ( props ) => {

    const promotion = useSelector(state => state.promotion);
    const PROMO_CONFIG = promotion.configuration;
    const CONFIG = useSelector(state => state.promotion.CONFIG);

    /** HANDLE NAVIGATING TO ALWAYS ALLOWED PAGES **/
    if ( props.action?.toUpperCase()?.trim() === "NAVIGATE" ) {
        if ( !empty(props.value) && isPageAlwaysAllowed(props.value) ) {
            let pageURL = "/" + props.value;
            //alert( pageURL );
            window.scroll(0, 0);
            return (<Navigate to={pageURL}/>);
        }
    }

    /** HANDLE PROMO START AND END **/
    if ( !isTestMode(PROMO_CONFIG) ) {
        if ( !hasPromoStarted(CONFIG.startDateMS) ) {
            return( <Navigate to="/coming-soon" /> );
        }
        if ( hasPromoEnded(CONFIG.endDateMS) ) {
            return( <Navigate to="/promo-ended" /> );
        }
    }

    /** HANDLE ERRORS **/
    if ( empty(CONFIG) ) {
        return( <Navigate to="/error" /> );
    }
    if ( !empty(props.error) ) {
        if ( !empty(props.error)  && props.error.status === "error" ) {
            if (props.error.errors[0].message === "Already Entered") {
                if (PROMO_CONFIG.type.value === GC.PROMO_TYPE_SIMPLE_SWEEPS) {
                    return( <Navigate to="/already-entered" /> );
                } else {
                    return( <Navigate to="/already-played" /> );
                }
            } else if (props.error.errors[0].message === "Already Won") {
                return (<Navigate to="/already-won"/>);

            } else if ( props.error.errors[0].message === "entry.verify" ) {
                return (<Navigate to="/verify-entry" />);

            } else {
                return( <Navigate to="/error" /> );
            }
        }
    }

    /** HANDLE NAVIGATE ACTION **/
    if ( props.action?.toUpperCase()?.trim() === "NAVIGATE" ) {
        let pageURL = "/" + props.value;
        //alert( pageURL );
        window.scroll( 0, 0 );
        return( <Navigate to={pageURL} /> );
    }

    /** HANDLE PRE ENTRY FLOW **/
    if ( props.page === GC.PAGE_APP) {
        if ( isRafflePromo(promotion) ) {
            if ( isRaffleTicketValidatorCode(promotion, promotion.configuration.source) ) {
                return (<Navigate to="/ticket-validator"/>);
            }
            if ( !empty(retrieveRaffleLocalStorage()) ) {
                return (<Navigate to="/raffle-scanned"/>);
            }
        }
        if (useSplash(promotion)) {
            return (<Navigate to="/splash"/>);
        }
    }

    /** HANDLE ENTRY FLOW **/
    if ( props.page === GC.PAGE_APP || props.page === GC.PAGE_SPLASH || props.page === GC.PAGE_GNPREDIR ) {
        if ( isNoReg(promotion) ) {
            return( <Navigate to="/enter-nr" /> );
        } else {
            return( <Navigate to="/enter" /> );
        }
    } else if ( props.page === GC.PAGE_GNPREDIR ) {
        return( <Navigate to="/enter" /> );
    }

    /** HANDLE POST ENTRY FLOW **/
     if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG ) {
         if ( isRafflePromo(promotion) ) {
             if ( !empty(promotion.source) ) {
                 return (<Navigate to="/raffle-scanned"/>);
             } else {
                 if ( !empty(retrieveRaffleLocalStorage()) ) {
                     return (<Navigate to="/raffle-scanned"/>);
                 } else {
                     return (<Navigate to="/raffle-scan"/>);
                 }
             }
         }
		if ( isPickYourPrize(promotion) ) {
            if ( isPromocracyOn(promotion.configuration) ) {
                let promocracyParticipant = retrievePromocracyParticipant( promotion.configuration, promotion.configuration.source );
                if ( empty(promocracyParticipant) ) {
                    return( <Navigate to="/pick-your-prize" /> );
                }
            }
		}
         if ( isFileUpload(promotion) ) {
             return( <Navigate to="/file-upload" /> );
         }
	}
    if ( props.page === GC.PAGE_RAFFLE_SCAN ) {
        return( <Navigate to="/raffle-scanned" /> );
    }
    if ( props.page === GC.PAGE_RAFFLE_SCANNED ) {
        return( <Navigate to="/raffle-scan" /> );
    }
    if ( props.page === GC.PAGE_ALREADY_SCANNED ) {
        return( <Navigate to="/raffle-scan" /> );
    }
    if ( props.page === GC.PAGE_FILE_UPLOAD ) {
        return( <Navigate to="/thank-you" /> );
    }

     /** HANDLE INSTANT WIN FLOWS **/
    if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_SIMPLE_SWEEPS ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/thank-you" /> );
        }
    } else if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_IW_MATCH_CONFIGURABLE ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/iw-match-c" /> );
        } else if ( props.page === GC.PAGE_IW_MATCH_CONFIGURABLE ) {
            return( <Navigate to="/thank-you" /> );
        }
    } else if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_IW_MATCH ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/iw-match" /> );
        } else if ( props.page === GC.PAGE_IW_MATCH ) {
            if ( props.promocracyLoopBack === true ) {
                return (<Navigate to="/iw-match"/>);
            } else {
                return (<Navigate to="/thank-you"/>);
            }
        }
    } else if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_IW_WHEEL ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/iw-wheel" /> );
        } else if ( props.page === GC.PAGE_IW_WHEEL ) {
            return( <Navigate to="/thank-you" /> );
        }
    } else if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_IW_SLOT ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/iw-slot" /> );
        } else if ( props.page === GC.PAGE_IW_SLOT ) {
            return( <Navigate to="/thank-you" /> );
        }
    } else if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_IW_NO_CLICK_REVEAL ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/iw-reveal" /> );
        } else if ( props.page === GC.PAGE_IW_NO_CLICK_REVEAL ) {
            return( <Navigate to="/thank-you" /> );
        }
    } else if ( PROMO_CONFIG.type.value === GC.PROMO_TYPE_IW_POKER_DICE ) {
        if ( props.page === GC.PAGE_ENTER || props.page === GC.PAGE_ENTER_NOREG || props.page === GC.PAGE_PICK_PRIZE ) {
            return( <Navigate to="/iw-poker-dice" /> );
        } else if ( props.page === GC.PROMO_TYPE_IW_POKER_DICE ) {
            return( <Navigate to="/thank-you" /> );
        }
    }

    /** DEFAULT TO ENTER PAGE **/
    return( <Navigate to="/enter" /> );

}

export default PageFlow;

