import axios from 'axios';
// import { toast } from 'react-toastify';
import { getToken } from './AuthenticationService';

function setupAxios() {
    axios.interceptors.request.use(function (config) 
    {
//    	alert("config")
//    	let token = getToken();
//        if (token) {
//            config.headers.Authorization = `Bearer ${token}`;
//        }

        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) 
    {
        // if(response && response.headers && response.headers['evoque-authorization'])
        // {
        //     window.localStorage.EvoqueTokenStore = response.headers['evoque-authorization'];
        // }

        return response;
    }, function (error) 
    {
        // check for errorHandle config
        if(Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') && error.config.errorHandle === false) 
        {
            return Promise.reject(error);
        }

        // if has response show the error
//        if (error.response && error.response.data && error.response.data.errors)
//        {
//            toast.error(error.response.data.errors[0].message);
//        }
//        else
//        {
//            toast.error(`${error}`);
//        }

        return Promise.reject(error);
    });
}

export default setupAxios;