import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	logIt,
	empty,
	getRandomUrlAppendage
} from '__globals/global-functions';

import { addCss, addCSSClass, removeCSSClass } from '__globals/css-functions';

import {
	getJsonContent,
	setContent
} from '__globals/copy-functions';

import {
	goNextPage
} from '__globals/page-flow';

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

// import {
// 	usePrompt
// } from '__globals/usePrompt.tsx';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

import _IWGameResult from "_pages/InstantWin/_Results/_IWGameResult/_IWGameResult";

import {sendAction} from "_features/external-integration/external-integration-slice";
import PageFlow from "../../__routes/PageFlow";

let addOn = getRandomUrlAppendage("rd");

export const IWGameSlot = props => {

	logIt( "INSTANT WIN GAME SLOT PAGE IS CALLED", "", false );

	const dispatch = useDispatch();

	const [nextPage, setNextPage] = useState(undefined);

	const state = useSelector( state => state );
	//logIt( "STATE at IWGameSlot", state, true );

	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at IWGameSlot", promotion, true );

	let entry = useSelector(state => state.entry);
	//logIt( "ENTRY at IWGameSlot", entry, true);

	let gameClaim = useSelector(state => state.entry.gameClaim);
	//logIt( "GAME CLAIM at IWGameSlot", gameClaim, true);

	let variables = useSelector(state => state.entry.variables);
	//logIt( "VARIABLES at IWGameSlot", gameClaim, true);

	let error = useSelector(state => state.entry.error);
	//logIt( "ERROR AT ENTER", error, true );

	let entryUpdated = useSelector(state => state.entry.entryUpdated);

	let winFormUpdated = useSelector(state => state.entry.winFormUpdated);
	//logIt( "winFormUpdated AT IWGameSlot", error, true );

	/** LOCAL VARIABLES */
	const [allowUsePrompt, setAllowUsePrompt] = useState( false);

	const [showTitle, setShowTitle] = useState( false);
	const [showCopy, setShowCopy] = useState( true);

	const [resultImage, setResultImage] = useState( '' );
	const [resultCSS, setResultCSS] = useState( '' );

	const [reelsSpun, setReelsSpun] = useState( false );
	const [doGoNext, setDoGoNext] = useState( false );
	const [goNext, setGoNext] = useState(false);

	const [animationGo, setAnimationGo] = useState( false );

	//const prompt = usePrompt(  (allowUsePrompt && !reelsSpun), "If you leave this page, you will invalidate your game play.  Choose 'Cancel' to return and finish the game. Click 'OK' to continue leaving.");

	/** HELPER FUNCTIONS **/
	const addCss = (css) => {
		var fileref=document.createElement("link");
		fileref.setAttribute("rel", "stylesheet")
		fileref.setAttribute("type", "text/css")
		fileref.setAttribute("href", `https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/css/${css}`);
		document.getElementsByTagName("head")[0].appendChild(fileref);
	}

	const spinReels = () => {
		addCSSClass( "button_spin", "spin-btn-hide" );
		if ( promotion.configuration.config.games.slot.reelCount >= 1 ) {
			addCSSClass( "slot_strip_one", "slot-strip-one" );
		}
		if ( promotion.configuration.config.games.slot.reelCount >= 2 ) {
			addCSSClass( "slot_strip_two", "slot-strip-two" );
		}
		if ( promotion.configuration.config.games.slot.reelCount >= 3 ) {
			addCSSClass( "slot_strip_three", "slot-strip-three" );
		}
		if ( promotion.configuration.config.games.slot.reelCount >= 4 ) {
			addCSSClass( "slot_strip_four", "slot-strip-four" );
		}
		addCSSClass( "spin_button", "btn-spin-out" );
		addCSSClass( "slot_game_content", "div-move-behind" );
		setReelsSpun( true );
		showResults();
	}

	const showResults = () => {
		setTimeout(function() {
			addCSSClass( "game_results_screen", "result-screen-fade-in" );
			addCSSClass( "game_results_options", "result-options-fade-in" );
			addCSSClass( "game_slot", "result-game-done" );
			//setReelsSpun( false );
			setAnimationGo( true );
		}, promotion.configuration.config.games.slot.resultDelay);
	}

	const showForm = () => {
		addCSSClass( "redeem_form", "result-redeem-fade-in" );
		addCSSClass( "redeem_button", "btn-redeem-out" );
		addCSSClass( "form_win_screen_show_results_button", "btn-redeem-out" );
	}

	const handleAction = ( action, value ) => {
		if ( action === "THANKYOU" ) {
			setReelsSpun( true );
		}
		dispatch( sendAction(promotion, action, value) );
	}
	/** END HELPER FUNCTIONS **/


	/** USE EFFECT HOOKS **/
	useEffect(() => {
		if ( !empty(promotion) ) {

			let btnSpinReels = getJsonContent( promotion, GC.PAGE_IW_SLOT, "spinButton", "<h2>SPIN REELS</h2>");
			setContent("spin_button", btnSpinReels);

			setAllowUsePrompt( promotion.configuration?.config?.games?.allowUsePrompt );
			setShowTitle( promotion.configuration?.config?.pages?.gamePage?.showTitle );
			setShowCopy( promotion.configuration?.config?.pages?.gamePage?.showCopy );

		}
	}, [promotion]);

	useEffect(() => {
		if ( !empty(gameClaim) ) {
			setResultImage( gameClaim.parameters.resultImage );
			setResultCSS( gameClaim.parameters.resultCss );
		}
	}, [gameClaim]);

	useEffect(() => {
		if ( (promotion.retrieved) && !empty(resultCSS) ) {
			addCss( resultCSS );
		}
	}, [resultCSS]);

	useEffect(() => {
		if ( !empty(error) ) {
			setGoNext( true );
		}
	}, [error] );

	// useEffect(() => {
	// 	if ( !empty(entryUpdated) && (entryUpdated === true) ) {
	// 		goNextPage( promotion, GC.PAGE_IW_SLOT, error );
	// 	}
	// }, [entryUpdated] );

	{/** Put in copy based on show flags */}
	useEffect(() => {
		if ( showTitle && !empty(promotion) ) {
			let titleCopy = getJsonContent(promotion, GC.PAGE_IW_SLOT, "title", "<h2>DEFAULT TITLE</h2>", entry);
			setContent("title_copy", titleCopy);
		}
	}, [showTitle]);
	useEffect(() => {
		if ( showCopy && !empty(promotion) ) {
			let copyCopy = getJsonContent(promotion, GC.PAGE_IW_SLOT, "copy", "<h2>DEFAULT COPY</h2>", entry);
			setContent("copy_copy", copyCopy);
		}
	}, [showCopy]);

	useEffect(() => {
		if ( !empty(doGoNext) && (doGoNext === true) ) {
			setGoNext( true );
		}
	}, [doGoNext] );

	useEffect(() => {
		if ( !empty(winFormUpdated) && (winFormUpdated === true) ) {
			setReelsSpun( true );
			setDoGoNext( true );
		}
	}, [winFormUpdated] );

	/** END USE EFFECT HOOKS **/

	if ( goNext ) {

		return( <PageFlow page={GC.PAGE_IW_SLOT} error={error} /> );

	} else {

		return (

			<React.Fragment>

				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>

				<div id="game_slot">
					<table>
						<tbody>
						<tr>
							<td className="page-visual">
								<div className="view-desktop">
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
								</div>
								<div className="view-phone" style={{display: 'none'}}>
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/game-screen-play.jpg${addOn}`}/>
								</div>
							</td>
							<td className="page-content">

								<div id='slot_game_content'>
									{showTitle && <div id="title_copy">Title One</div>}
									{showCopy && <div id="copy_copy">Copy One</div>}
									<div id="spin_button" onClick={spinReels}>Spin Reels</div>
								</div>

								<div id='game_slot_board'>

									<div id='dv_no_refresh_instructions'>Do not refresh your browser, you will lose this
										game play.
									</div>

									<div id="slot_mask">
										<div className="view-desktop"><img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-slot-mask.png${addOn}`}/>
										</div>
										<div className="view-mobile"><img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/game-slot-mask.png${addOn}`}/>
										</div>
									</div>

									<div id='slot_wrapper'>

										{(promotion.configuration.config.games.slot.reelCount >= 1) &&
											<div id='slot_strip_one'>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
											</div>}

										{(promotion.configuration.config.games.slot.reelCount >= 2) &&
											<div id='slot_strip_two'>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
											</div>}

										{(promotion.configuration.config.games.slot.reelCount >= 3) &&
											<div id='slot_strip_three'>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
											</div>}

										{(promotion.configuration.config.games.slot.reelCount >= 1) &&
											<div id='slot_strip_four'>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-randomizer.jpg${addOn}`}/>
												</div>
												<div><img
													src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
												</div>
											</div>}

									</div>
								</div>

								{/** Component holding all the game results **/}
								<_IWGameResult promotion={promotion} gameClaim={gameClaim} entry={entry}
											   handleAction={handleAction} showForm={showForm}
											   animationGo={animationGo}/>

								<div id="game_results_screen">
									<div className="view-desktop">
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
									</div>
									<div className="view-phone" style={{display: 'none'}}>
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/${resultImage}`}/>
									</div>
								</div>


							</td>
						</tr>
						</tbody>
					</table>

					<CustomContent/>

				</div>

			</React.Fragment>

		);

	}

}



