import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import { logIt, empty } from '__globals/global-functions';
import {getMonthDayYearDateFormat, getYearMonthDayFormat} from "./date-functions";

export function checkPromocracyVariation( promotionConfig, newVars ) {
    if ( isPromocracyOn(promotionConfig) ) {
        let promocracyParticipant = retrievePromocracyParticipant( promotionConfig, newVars["source"] );
        if ( !empty(promocracyParticipant) ) {
            newVars["promocracyOn"] = true;
            newVars["promocracyKey"] = promocracyParticipant["key"];
            newVars["promocracyLogoImage"] = promocracyParticipant["logoImage"];
            newVars["promocracyDisplayName"] = promocracyParticipant["displayName"];
            newVars["promocracyOverallSponsorAddOn"] = retrievePromocracyOverallSponsorAddOn( promotionConfig );
        } else {
            newVars["promocracyOn"] = false;
            newVars["promocracyDisplayName"] = retrievePromocracyDefaultDisplayName( promotionConfig );
            newVars["promocracyOverallSponsorAddOn"] = "";
        }
    }
    return newVars;
}

export function isPromocracyOn( promoConfig ) {
    if ( promoConfig?.config?.variations?.promocracy?.promocracyOn === true ) {
        return true;
    }
    return false;
}

export function retrievePromocracyParticipant( promoConfig, participantKey ) {
    let foundParticipant;
    if ( !empty(participantKey) ) {
        let participants = promoConfig?.config?.variations?.promocracy?.participants;
        let items = participants.map((participant, key) => {
            if (participant.key.toUpperCase() == participantKey.toUpperCase()) {
                foundParticipant = participant;
            }
        });
    }
    return foundParticipant;
}

export function retrievePromocracyDefaultDisplayName( promoConfig ) {
    if ( !empty(promoConfig?.config?.variations?.promocracy?.defaultDisplayName) ) {
        return promoConfig?.config?.variations?.promocracy?.defaultDisplayName;
    }
    return "The Promotion Sponsor";
}

export function retrievePromocracyOverallSponsorAddOn( promoConfig ) {
    if ( !empty(promoConfig?.config?.variations?.promocracy?.overallSponsorAddOn) ) {
        return promoConfig?.config?.variations?.promocracy?.overallSponsorAddOn;
    }
    return "";
}


/** PROMOCRACY INSTANT WIN FUNCTIONS **/
const PROMOCRACY_STORAGE_KEY = "RSP_promocracy-storage";
export function retrievePromocracyPlayCount() {
    let today = new Date();
    let ymdDate = getYearMonthDayFormat( today );
    let promocracyStore = retrieve( PROMOCRACY_STORAGE_KEY );
    let playCount = countPlaysForDate( promocracyStore, ymdDate );
    return playCount;
}

function countPlaysForDate( promocracyStore, countDate ) {
    let playCount = 0;
    let items = promocracyStore.iwPlays.map((play, key) => {
        if ( play.date === countDate ) {
            playCount++;
        }
    });
    return playCount;
}
/** END PROMOCRACY INSTANT WIN FUNCTIONS **/


/** RAFFLE FUNCTIONS **/

const RAFFLE_STORAGE_KEY = "RSP_raffle-storage";

export function isRaffleTicketValidatorCode( promotion, scanData ) {
    let validatorCode = promotion.configuration.config.variations.raffle.raffleValidatorCode;
    if ( scanData.toUpperCase() === validatorCode.toUpperCase() ) {
        return true;
    }
    return false;
}

export function createAddEntryForTicketProviderScan( providerScan ) {
    let addEntry = {"entry":{}};
    addEntry.entry.ticketProvider = providerScan.key;
    addEntry.entry.ticketsProvided = providerScan.ticketsPerScan;
    return addEntry;
}

export function storeTicketScanInLocalStorage( scanData ) {
    let storedRaffleData = retrieve( RAFFLE_STORAGE_KEY );
    logIt("storedRaffleData in storeTicketScanInLocalStorage", storedRaffleData, true );
    if ( !empty(storedRaffleData) ) {
        addProviderToLocalStore( scanData );
    } else {
        let raffleStorageData = createLocalStorageForRaffle( scanData );
        store( RAFFLE_STORAGE_KEY, raffleStorageData );
    }
    return true;
}

export function retrieveRaffleLocalStorage() {
    let storedRaffleData = retrieve( RAFFLE_STORAGE_KEY );
    return storedRaffleData;
}

export function retrieveRaffleTicketTotal() {
    let storedRaffleData = retrieve( RAFFLE_STORAGE_KEY );
    let ticketCount = 0;
    if ( !empty(storedRaffleData) ) {
        ticketCount = countTickets( storedRaffleData );
    }
    return ticketCount;
}

export function clearRaffleTicketStorage() {
    clear( RAFFLE_STORAGE_KEY );
    let test = retrieve( RAFFLE_STORAGE_KEY );
    logIt( "TEST AFTER CLEAR", test, true );
}

export function hasProviderBeenScanned( scanData ) {
    let storedRaffleData = retrieve( RAFFLE_STORAGE_KEY );
    let ticketProviderFound = false;
    if ( !empty(storedRaffleData) ) {
        let tScans = storedRaffleData.ticketScans;
        let items = tScans.map((tScan, key) => {
            if (tScan.ticketProvider === scanData.key) {
                ticketProviderFound = true;
            }
        });
    }
    return ticketProviderFound;
}

function createLocalStorageForRaffle( scanData ) {
    let localStorageData = {};
    localStorageData.domain = window.location.hostname;
    localStorageData.ticketScans = [];
    localStorageData.ticketScans[0] = {"ticketProvider":scanData.key,"ticketsProvided":scanData.ticketsPerScan}
    return localStorageData;
}

function countTickets( raffleData ) {
    let tScans = raffleData.ticketScans;
    let ticketTotal = 0;
    let items = tScans.map((tScan, key) => {
        logIt( "STORED SCAN: ", tScan, true );
        if ( !empty(tScan.ticketProvider) ) {
            ticketTotal += tScan.ticketsProvided;
        }
    });
    return ticketTotal;
}

function addProviderToLocalStore( scanData ) {
    let tempStore = retrieve( RAFFLE_STORAGE_KEY );
    logIt( "tempStore at addProviderToLocalStore", tempStore, true );
    let l = tempStore.ticketScans.length;
    tempStore.ticketScans.push({"ticketProvider":scanData.key,"ticketsProvided":scanData.ticketsPerScan});
    //alert( JSON.stringify(tempStore));
    store( RAFFLE_STORAGE_KEY, tempStore );
}

/** END RAFFLE FUNCTIONS **/

/** LOCAL STORAGE FUNCTIONS **/
function store( key, data ) {
    localStorage.setItem( key, JSON.stringify(data) );
}

function retrieve( key ) {
    let lStore = JSON.parse(localStorage.getItem(key));
    return lStore;
}

function clear( key ) {
    localStorage.removeItem( key );
}
/** END LOCAL STORAGE FUNCTIONS **/

