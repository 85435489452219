import { createSlice } from '@reduxjs/toolkit';

import { logIt } from '__globals/global-functions';

import { 	getGalleryImages,
			postImageStatus} from '_api/UserContentAPI';

const userContentSlice = createSlice({
	  name: 'usercontent',
	  initialState:{},
	  reducers: {
		  processGallery( state, action ) {
			  if ( state.gallery ){
				  state.gallery = state.gallery.concat(action.payload);
			  } else {
				  state.gallery = action.payload;
			  }
			  logIt( "GALLERY at processGallery", state.gallery, true );
		  },
		  processGalleryError(state, action) {
			  state.error = action.payload;
			  logIt( "processGalleryError PAYLOAD", action.payload, true );
		  },
		  processErrMsgDisplay(state, action) {
			  logIt( "processErrMsgDisplay PAYLOAD", action.payload, false );
			  state.errorMsgDisplay = action.payload;
		  },
		  processClearStatus(state, action) {
			  logIt( "processClearStatus STATE", state, true );
			  state.error = undefined;
			  state.result = undefined;
		  },
		  processImageStatus(state, action) {
			  // TODO: complete this section
			  state.error = action.payload;
			  logIt( "processImageStatus PAYLOAD", action.payload, true );
		  },
	  }
});

export const {
	processGallery,
	processErrMsgDisplay,
	processGalleryError,
	processClearStatus,
	processImageStatus
} = userContentSlice.actions;

export const fetchGallery = ( entryGroupId, token, page, size ) => async (dispatch, getState) => {
	if (getState().usercontent.galleryImages === undefined || getState().usercontent.galleryImages.length === 0) {	
		
		//const promotion = await getPromotion(domain);
		
		//alert(entryGroupId)
		const result = await getGalleryImages( entryGroupId, token, page, size );
		logIt( "RESULT in fetchGallery", result, false );
	    if ( result.status === "error" ) {
	    	dispatch(processGalleryError(result));
	    } else {
	    	dispatch( processGallery( result ) );
	    }
	}
}

export const setImageStatus = ( statusObj ) => async (dispatch, getState) => {
	const result = await postImageStatus();
	logIt( "RESULT in setImageStatus", result, false );
    if ( result.status === "error" ) {
    	dispatch(processGalleryError(result));
    } else {
    	dispatch( processImageStatus( result ) );
    }
}

export default userContentSlice.reducer;



