import { createSlice } from '@reduxjs/toolkit';

import { logIt } from '__globals/global-functions';

import { 	
	postSendEmail
} from '_api/EmailAPI';

const emailSlice = createSlice({
	name: 'email',
	initialState:{},
	reducers: {
		processSendEmail( state, action ) {
			  //logIt( "GALLERY at processGallery", state.gallery, true );
		},
		processServerError(state, action) {
			state.error = action.payload;
			logIt( "processServerError PAYLOAD", action.payload, true );
		}
	}
});

export const {
	processSendEmail,
	processServerError
} = emailSlice.actions;

export const sendEmail = ( emailJSON ) => async (dispatch, getState) => {
	const result = await postSendEmail( emailJSON );
	logIt( "RESULT in sendEmail", result, false );
    if ( result.status === "error" ) {
    	dispatch(processServerError(result));
    } else {
    	dispatch( processSendEmail( result ) );
    }
}


export default emailSlice.reducer;



