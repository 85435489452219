import React, { useCallback } from 'react';
import { Marker } from '@vis.gl/react-google-maps';

/**
 * Wrapper Component for an AdvancedMarker for a single tree.
 */
export const SingleMarker = (props) => {


    const { tree, onClick, setMarkerRef } = props;

    console.log(props)

    const handleClick = useCallback(() => onClick(tree), [onClick, tree]);
    const ref = useCallback((marker) =>
        setMarkerRef(marker, tree.key),
        [setMarkerRef, tree.key]
    );

    return (
        <Marker position={tree.position} ref={ref} onClick={handleClick}>
            <span className="marker-clustering-tree">🌳</span>
        </Marker>
    );
};
