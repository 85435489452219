import React, { useState, useEffect } from 'react';

import {
    logIt,
    empty,
    getRandomUrlAppendage
} from '__globals/global-functions';
import {updateEntry} from "../../../../_features/entries/entry-slice";
import {PageCopy} from "../../../../_components/PageCopy/PageCopy";
import {getJsonContent, setContent} from "../../../../__globals/copy-functions";

const addOn = getRandomUrlAppendage("rd");

export const PromocracyChooser = props => {

    const [promocracyParticipants, setPromocracyParticipants] = useState(undefined);
    const [maxEntryCount, setMaxEntryCount] = useState(1);



    useEffect(() => {
        if ( !empty(props.promotion) ) {
            let participants = props.promotion?.configuration?.config?.variations?.promocracy?.participants;
            logIt( "PARTICIPANTS at PromocracyChooser", participants, true );
            setPromocracyParticipants( participants );

            let maxIWEntries = props.promotion?.configuration?.config?.variations?.promocracy?.maxIWEntries;
            logIt( "MAX IW ENTRIES at PromocracyChooser", maxIWEntries, true );
            setMaxEntryCount( maxIWEntries );

            let instructions = getJsonContent(props.promotion, "PromocracyChooser", "instructions", "<h2>DEFAULT TITLE</h2>", props.entry );
            //alert(instructions)
            setContent("dv_promocracy_chooser_instructions", instructions);
        }
    }, [props.promotion]);

    // useEffect(() => {
    //     if ( !empty(promocracyParticipants) && !empty(maxEntryCount) ) {
    //         let plays = retrievePromocracyPlayCount();
    //         if ( plays >= maxEntryCount ) {
    //             alert( "max entries reached" );
    //         } else {
    //             alert( "more entries" );
    //         }
    //     }
    // }, [promocracyParticipants, maxEntryCount] );

    const getPromocracyParticipantGrid = () => {
        let index = -1;
        let items = promocracyParticipants.map((pParticipant, key) => {
            index++;
            //if ( showButtons ) {
                return (
                    <div key={pParticipant.key} onClick={() => props.handlePromocracyParticipantPick(pParticipant.key)}>
                        <div className="cls-promocracy-participant-logo">
                            <img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/promocracy_logos/${props.promotion.configuration.config.variations.promocracy?.participants[index].logoImage}`}/>
                        </div>
                        {/*<div className="pick-prize-description">*/}
                        {/*    {promotion.configuration.config.variations.pickYourPrize.prizes[index].prizeDescription}*/}
                        {/*</div>*/}
                        {/*<div className="pick-prize-btn">*/}
                        {/*    {promotion.configuration.config.variations.pickYourPrize.prizes[index].buttonCopy}*/}
                        {/*</div>*/}
                    </div>
                );
            // } else {
            //     return (
            //         <div key={pPick.key} onClick={() => handlePrizePick(pPick.key)}>
            //             <div className="pick-prize-img">
            //                 <img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}all/${promotion.configuration.config.variations.pickYourPrize.prizes[index].imageName}`}/>
            //             </div>
            //             <div className="pick-prize-description">
            //                 {promotion.configuration.config.variations.pickYourPrize.prizes[index].prizeDescription}
            //             </div>
            //         </div>
            //     );
            //}
        });
        return items;
    }

    return (
        <React.Fragment>
            {props.promotion?.variables?.promocracyOn && props.chooserOn && <div id="dv_promocracy_chooser">

                <div id="dv_promocracy_chooser_instructions">

                </div>
                <div id="dv_promocracy_chooser_participants">
                    <div id="dv_promocracy_participant_grid">
                        {getPromocracyParticipantGrid()}
                    </div>
                    <img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/promocracy_logos/${props.promotion.variables.promocracyLogoImage}${addOn}`}/>

                </div>

            </div>}
        </React.Fragment>
    );

}