import axios from 'axios';
import {	logIt,
			inspectFormData } from '__globals/global-functions';
			
const hostName = window.REACT_APP_PROMOTION_DOMAIN;
const prefix = "https://";

/*
 * HOW TO SPOOF AN ERROR
{
    "message": null,
    "errors": [
        {
            "field": "",
            "message": "Already Entered"
        }
    ],
    "status": "error"
}
 */

export async function getVerifyGWPCode( code, token )
{
    const url = prefix + hostName + '/verify-gwp-code/' + code + '?token=' + token;
    const {data} = await axios.get( url );
    return data;
}

export async function getConfirmGWPCode( code, token )
{
    const url = prefix + hostName + '/gwp-confirm-code/' + code + '?token=' + token;
    const {data} = await axios.get( url );
    return data;
}

//    // spoof error
//   let data = {
//        "message": null,
//        "errors": [
//            {
//                "field": "",
//                "message": "Code Not Valid"
//            }
//        ],
//        "status": "error"
//    }
//   if ( code === "ABC123" ) {
//	   data = {
//		  codeValid: true,
//		  codeGroup: "cooler_sweatshirt"
//	   }
//   } else if ( code === "DEF456" ) {
//	   data = {
//		  codeValid: true,
//		  codeGroup: "sweatshirt"
//	   }
//   }
//    return data;
