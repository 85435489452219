import React, {useEffect, useState, useRef} from 'react';
import {useDispatch} from "react-redux";

import {
    empty, logIt,
} from "__globals/global-functions";

import {
    getJsonContent,
    setContent,
    getJsonResultCopy
} from "__globals/copy-functions";

import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import {checkDateVariations} from "../../../../__globals/date-functions";
import {updateEntry} from "../../../../_features/entries/entry-slice";


export const ThankYouECard = props => {

    logIt( "THANK YOU E-CARD IS CALLED", "", false );

    const dispatch = useDispatch();

    // LOCAL VARIABLES
    const [showTitle, setShowTitle] = useState( false);
    const [showResultCopy, setShowResultCopy] = useState( true);
    const [showCopy, setShowCopy] = useState( false);
    const [showActionOne, setShowActionOne] = useState( true);
    const [showActionTwo, setShowActionTwo] = useState( false);
    const [showActionThree, setShowActionThree] = useState( false);


    const handleActionButton1 = () => {
        let action = props.gameClaim?.parameters?.thankYouScreen?.actionOne?.action;
        let value = props.gameClaim?.parameters?.thankYouScreen?.actionOne?.value;
        props.handleAction( action, value );
    }

    const handleActionButton2 = () => {
        let action = props.gameClaim?.parameters?.thankYouScreen?.actionTwo?.action;
        let value = props.gameClaim?.parameters?.thankYouScreen?.actionTwo?.value;
        props.handleAction( action, value );
    }

    const handleActionButton3 = () => {
        let action = props.gameClaim?.parameters?.thankYouScreen?.actionThree?.action;
        let value = props.gameClaim?.parameters?.thankYouScreen?.actionThree?.value;
        props.handleAction( action, value );
    }

    /** Sets the result css from the game claim */
    useEffect(() => {
        if ( !empty(props.gameClaim) ) {

            setShowTitle( props.gameClaim?.parameters?.thankYouScreen?.showTitle );
            setShowResultCopy( props.gameClaim?.parameters?.thankYouScreen?.showResultCopy );
            setShowCopy( props.gameClaim?.parameters?.thankYouScreen?.showCopy );
            setShowActionOne( props.gameClaim?.parameters?.thankYouScreen?.actionOne?.show );
            setShowActionTwo( props.gameClaim?.parameters?.thankYouScreen?.actionTwo?.show );
            setShowActionThree( props.gameClaim?.parameters?.thankYouScreen?.actionThree?.show );

            if (!props.gameClaim.losing) {
                let cStart = "";
                if (!empty(props.gameClaim.parameters["couponStartDate"])) {
                    cStart = props.gameClaim.parameters["couponStartDate"];
                }
                let fDate = checkDateVariations(cStart);
                if (checkDateVariations !== false) {
                    cStart = fDate;
                }
                let cEnd = "";
                if (!empty(props.gameClaim.parameters["couponEndDate"])) {
                    cEnd = props.gameClaim.parameters["couponEndDate"];
                }
                let pWon = props.gameClaim.parameters["prizeWon"];
                let addEntryObj = {
                    entry: {
                        "couponStartDate": cStart,
                        "couponEndDate": cEnd,
                        "prizeWon": pWon
                    }
                }
                if ( props.gameClaim !== undefined && props.gameClaim.parameters.emailSend !== undefined) {
                    if ( !props.entryUpdated ) {
                        let emailSend = {};
                        emailSend.emailTemplate = props.gameClaim.parameters.emailSend.emailTemplate;
                        emailSend.emailSubject = props.gameClaim.parameters.emailSend.emailSubject;
                        dispatch( updateEntry( addEntryObj, emailSend ) );
                    }
                } else {
                    dispatch( updateEntry( addEntryObj ) );
                }
            }
        }
    }, [props.gameClaim]);

    useEffect(() => {
        if ( !empty(props.gameClaim) ) {
            setShowTitle( props.gameClaim?.parameters?.thankYouScreen?.showTitle );
            setShowResultCopy( props.gameClaim?.parameters?.thankYouScreen?.showResultCopy );
            setShowCopy( props.gameClaim?.parameters?.thankYouScreen?.showCopy );
            setShowActionOne( props.gameClaim?.parameters?.thankYouScreen?.actionOne?.show );
            setShowActionTwo( props.gameClaim?.parameters?.thankYouScreen?.actionTwo?.show );
            setShowActionThree( props.gameClaim?.parameters?.thankYouScreen?.actionThree?.show );
        }
    }, [props.promotion]);

    {/** Put in copy based on show flags */}
    useEffect(() => {
        if ( showTitle && !empty(props.promotion) ) {
            let tyECardTitle = getJsonContent(props.promotion, GC.TY_ECARD, "title", "<h2>DEFAULT TITLE</h2>", props.entry );
            setContent("ty_e_card_title", tyECardTitle);
        }
    }, [showTitle]);
    useEffect(() => {
        if ( showResultCopy && !empty(props.promotion) && !empty(props.gameClaim) ) {
            let resultCopy = getJsonResultCopy(props.promotion, GC.TY_ECARD, props.gameClaim, "<h2>ERROR</h2>", props.entry );
            setContent("ty_e_card_result_copy", resultCopy);
        }
    }, [showResultCopy]);
    useEffect(() => {
        if ( showCopy && !empty(props.promotion) ) {
            let tyECardCopy = getJsonContent(props.promotion, GC.TY_ECARD, "copy", "<h2>DEFAULT COPY</h2>", props.entry );
            setContent("ty_e_card_copy", tyECardCopy);
        }
    }, [showCopy]);
    useEffect(() => {
        if ( showActionOne && !empty(props.promotion) ) {
            let tyECardButton1 = getJsonContent(props.promotion, GC.TY_ECARD, "button1", "<h2>DEFAULT BTN 1</h2>", props.entry );
            setContent("ty_e_card_button_1", tyECardButton1);
//action1Ref.current.click.handler = handleActionButton1;
        }
    }, [showActionOne]);
    useEffect(() => {
        if ( showActionTwo && !empty(props.promotion) ) {
            let tyECardButton2 = getJsonContent(props.promotion, GC.TY_ECARD, "button2", "<h2>DEFAULT BTN 2</h2>", props.entry );
            setContent("ty_e_card_button_2", tyECardButton2);
        }
    }, [showActionTwo]);
    useEffect(() => {
        if ( showActionThree && !empty(props.promotion) ) {
            let tyECardButton3 = getJsonContent(props.promotion, GC.TY_ECARD, "button3", "<h2>DEFAULT BTN 3</h2>", props.entry );
            setContent("ty_e_card_button_3", tyECardButton3);
        }
    }, [showActionThree]);
    {/** END of put in copy based on show flags */}

    // RETURN THE GAME RESULT
    return (
        <div id='dv_thank_you_e_coupon' >
            {showTitle && <div id="ty_e_card_title">Title One</div>}
            {showCopy && <div id="ty_e_card_copy">Copy One</div>}
            {showResultCopy && <div id="ty_e_card_result_copy">Result Copy</div>}
            {showActionOne && <div id="ty_e_card_button_1" onClick={handleActionButton1}>Action One</div>}
            {showActionTwo && <div id="ty_e_card_button_2" onClick={handleActionButton2}>Action Two</div>}
            {showActionThree && <div id="ty_e_card_button_3" onClick={handleActionButton3}>Action Three</div>}
        </div>
    );
}

export default ThankYouECard;