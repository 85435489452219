import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

import {empty, logIt, replaceVars, showHiderules} from '__globals/global-functions';
import {getJsonContent, setContent} from "../../__globals/copy-functions";
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {addCSSClass, removeCSSClass} from "../../__globals/css-functions";

var sanitizeHtml = require('sanitize-html');

export const Footer = props => {

	const promotion = useSelector(state => state.promotion);
	
	const date = new Date();
	const year = date.getFullYear();
	
	useEffect(() => {
		if ( !empty(promotion) ) {
			if (
				promotion?.configuration?.promoCopy !== undefined &&
				promotion?.configuration?.promoCopy?.Footer !== undefined
			) {

				let footerPre = getJsonContent(promotion, "Footer", "footerPre", "<h2>DEFAULT FOOTER PRE</h2>");
				setContent("footer_pre", footerPre);

				let footerRules = getJsonContent(promotion, "Footer", "footerRules", "<h2>DEFAULT FOOTER RULES</h2>");
				setContent("footer_rules", footerRules);

				let footerPost = getJsonContent(promotion, "Footer", "footerPost", "<h2>DEFAULT FOOTER POST</h2>");
				setContent("footer_post", footerPost);

			}
		}
	}, promotion );
		
	return (
		<div id="footer">
			<div className="clear"></div>
			<div id="footer_copy">
				<div id="footer_pre">x</div>
				<div id="footer_rules_wrapper" onClick={showHiderules}><div id="footer_rules">x</div></div>
				<div id="footer_post">x</div>
			</div>
            <div className="clear"></div>
			<div id="poweredby">
				Powered by <a href="http://www.readysetpromo.com" target="_blank">ReadySetPromo.com</a>
			</div>
            <div id="administeredby">
				Administered by <a href={promotion.configuration.config.administeredByURL} target="_blank" link="link4">{promotion.configuration.config.administeredByName}</a> 
			</div> 
		</div>
	);
}

