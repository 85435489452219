import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
    empty, getRandomUrlAppendage, logIt
} from '__globals/global-functions';

import { CustomContent } from '_pages/Variations/CustomContent/CustomContent';
import { PromocracyLogo } from "_components/PromocracyLogo/PromocracyLogo";

import {
    getJsonContent, getJsonResultCopy,
    setContent
} from "__globals/copy-functions";

import { executeAction } from "__globals/actions";
import MapComponent from './MapComponent';

import * as GC from "__globals/GLOBAL-CONSTANTS";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'RSP',
        page: 'LocalMap',
        checkLoginIframe: false
    },
    dataLayerName: 'RSP'
}


export const LocalMap = props => {

    logIt( GC.PAGE_LOCAL_MAP + " PAGE IS CALLED", null, false );

    TagManager.dataLayer(tagManagerArgs);

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at LocalMap", promotion, true );

    // LOCAL VARIABLES
    const [showTitle, setShowTitle] = useState(false);
    const [showCopy, setShowCopy] = useState(true);
    const [showActionOne, setShowActionOne] = useState(false);
    const [showActionTwo, setShowActionTwo] = useState(false);
    const [showActionThree, setShowActionThree] = useState(false);

    const [actionOne, setActionOne] = useState('');
    const [valueOne, setValueOne] = useState('');

    const [actionTwo, setActionTwo] = useState('');
    const [valueTwo, setValueTwo] = useState('');

    const [actionThree, setActionThree] = useState('');
    const [valueThree, setValueThree] = useState('');

    const [promocracyOn, setPromocracyOn] = useState(false);
    const [promocracyMapJSON, setPromocracyMapJSON] = useState(false);

    const handleActionButton1 = () => {
        executeAction(actionOne, valueOne);
    }

    const handleActionButton2 = () => {
        executeAction(actionTwo, valueTwo);
    }

    const handleActionButton3 = () => {
        executeAction(actionThree, valueThree);
    }

    /** Sets the page copy from config and any action buttons */
    useEffect(() => {
        if (!empty(promotion)) {

            setShowTitle(promotion.configuration?.config?.pages?.localMap?.showTitle);
            setShowCopy(promotion.configuration?.config?.pages?.localMap?.showCopy);
            setShowActionOne(promotion.configuration?.config?.pages?.localMap?.actionOne?.show);
            setShowActionTwo(promotion.configuration?.config?.pages?.localMap?.actionTwo?.show);
            setShowActionThree(promotion.configuration?.config?.pages?.localMap?.actionThree?.show);

            setActionOne(promotion.configuration?.config?.pages?.localMap?.actionOne?.action);
            setValueOne(promotion.configuration?.config?.pages?.localMap?.actionOne?.value);
            setActionTwo(promotion.configuration?.config?.pages?.localMap?.actionTwo?.action);
            setValueTwo(promotion.configuration?.config?.pages?.localMap?.actionTwo?.value);
            setActionThree(promotion.configuration?.config?.pages?.localMap?.actionThree?.action);
            setValueThree(promotion.configuration?.config?.pages?.localMap?.actionThree?.value);

            setPromocracyOn(promotion.configuration.config?.variations?.promocracy?.promocracyOn);

        }
    }, [promotion]);

    useEffect(() => {
        if (promocracyOn === true) {
            let copiedPromocracyMap = JSON.parse(JSON.stringify(promotion.configuration.promocracyMap));
            logIt("PROMOCRACY MAP JSON at useEffect", copiedPromocracyMap, true);
            if (!empty(copiedPromocracyMap)) {
                setPromocracyMapJSON(copiedPromocracyMap);
            }
        }
    }, [promocracyOn]);

    {/** Put in copy based on show flags */ }
    useEffect(() => {
        if (showTitle && !empty(promotion)) {
            let titleCopy = getJsonContent(promotion, "LocalMap", "title", "<h2>DEFAULT TITLE</h2>");
            setContent("title_copy", titleCopy);
        }
    }, [showTitle]);
    useEffect(() => {
        if (showCopy && !empty(promotion)) {
            let copyCopy = getJsonContent(promotion, "LocalMap", "copy", "<h2>DEFAULT COPY</h2>");
            setContent("copy_copy", copyCopy);
        }
    }, [showCopy]);
    useEffect(() => {
        if (showActionOne && !empty(promotion)) {
            let button1Copy = getJsonContent(promotion, "LocalMap", "button1", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_1", button1Copy);
        }
    }, [showActionOne]);
    useEffect(() => {
        if (showActionTwo && !empty(promotion)) {
            let button2Copy = getJsonContent(promotion, "LocalMap", "button2", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_2", button2Copy);
        }
    }, [showActionTwo]);
    useEffect(() => {
        if (showActionThree && !empty(promotion)) {
            let button3Copy = getJsonContent(promotion, "LocalMap", "button3", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_3", button3Copy);
        }
    }, [showActionThree]);
    {/** END of put in copy based on show flags */ }

    return (
        <React.Fragment>
            <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title} />
            <div id="map_page">

                <table>
                    <tbody>
                        <tr>
                            <td className="page-visual">
                                <div className="view-desktop"><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/local-map-screen.jpg`} /></div>
                                <div className="view-phone" style={{ display: 'none' }}><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/local-map-screen.jpg`} /></div>
                            </td>
                            <td className="page-content">

                                <div id='map_content' >
                                    {showTitle && <div id="title_copy">Title One</div>}
                                    {showCopy && <div id="copy_copy">Copy One</div>}
                                    {showActionOne && <div id="button_1" onClick={handleActionButton1}>Action One</div>}
                                    {showActionTwo && <div id="button_2" onClick={handleActionButton2}>Action Two</div>}
                                    {showActionThree && <div id="button_3" onClick={handleActionButton3}>Action Three</div>}
                                </div>

                                {/** MARIA MAP COMPONENT HERE **/}
                                <div id="map-container" style={{ display: "flex", maxWidth: "100%", marginBottom: "50px" }}>
                                    <MapComponent promocracyMapJSON={promocracyMapJSON} />
                                </div>

                                <PromocracyLogo promotion={promotion} />

                            </td>
                        </tr>
                    </tbody>
                </table>



                <CustomContent />

            </div>
        </React.Fragment>

    );

}

