import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {TileBlock} from './TileBlock/TileBlock'; 

import { 
	logIt,
	empty,
} from '__globals/global-functions';

import {
	addCSSClass,
	removeCSSClass,
} from '__globals/css-functions';

import {
	postEmailSendEntry 
} from '_features/entries/entry-slice';

import {
	notifyStartGame,
	notifyEndGame,
	sendAction
} from '_features/external-integration/external-integration-slice';

import {GameIntroLottieAnimationWrapper} from "_pages/Variations/Animation/LottieAnimation/GameIntroLottieAnimationWrapper";

// import {
// 	usePrompt
// } from '__globals/usePrompt.tsx';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

import _IWGameResult from "_pages/InstantWin/_Results/_IWGameResult/_IWGameResult";
import {getJsonContent, setContent} from "../../../__globals/copy-functions";
import {goNextPage} from "../../../__globals/page-flow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {setFormattedTime} from '__globals/date-functions';
import PageFlow from "../../__routes/PageFlow";


/**
 * NAME: IWGameMatchConfigurable
 * DESCRIPTION: Base functional component of the match style game that can be 
 * 				configured via the Game Claim config.
 * AUTHOR: Chuck Gorder
 * CREATED: 2022-06-17
 * 
 */
export const IWGameMatchConfigurable = props => {
	
	logIt( "IWGameMatchConfigurable IS CALLED", "", false );

	const dispatch = useDispatch();
	
	const promotion = useSelector(state => state.promotion);
	//logIt( "promotion at IWGameMatchConfigurable", promotion, true );
	
	let entry = useSelector(state => state.entry);
	//logIt( "entry at IWGameMatchConfigurable", entry, true);

	let gameClaim = useSelector(state => state.entry.gameClaim);
	logIt( "GAME CLAIM at IWGameMatchConfigurable", gameClaim, true);

	let winFormUpdated = useSelector(state => state.entry.winFormUpdated);
	//logIt( "winFormUpdated AT IWGameMatchConfigurable", error, true );

	/** LOCAL VARIABLES **/
	const [allowUsePrompt, setAllowUsePrompt] = useState( false);
	const [gameStartTime, setGameStartTime] = useState( null);

	const [showTitle, setShowTitle] = useState( false);
	const [showCopy, setShowCopy] = useState( true);

	const [tileConfig, setTileConfig] = useState("");
	const [resultImage, setResultImage] = useState("");
	const [resultCss, setResultCss] = useState("");
	const [integrationData, setIntegrationData] = useState("[integration data]");

	// const [gameBoardShowTime, setGameBoardShowTime] = useState(promotion.configuration.config?.games?.gameIntroAnimation?.gameBoardShowTime || 3000);
	// const [gameMaskFadeTime, setGameMaskFadeTime] = useState(promotion.configuration.config?.games?.gameIntroAnimation?.gameMaskFadeTime || 5000);
	// const [gameIntroAnimationFadeTime, setGameIntroAnimationFadeTime] = useState(promotion.configuration.config?.games?.gameIntroAnimationFadeTime || 7000);


	const [introAnimationOn, setIntroAnimationOn] = useState(promotion.configuration.config?.games?.gameIntroAnimation?.animationOn || false);
	const [introAnimationType, setIntroAnimationType] = useState(promotion.configuration.config?.games?.gameIntroAnimation?.animationType || false);

	const [introAnimationCssFadeTime, setIntroAnimationCssFadeTime] = useState(promotion.configuration.config?.games?.match?.gameIntroAnimation?.isCss.fadeTime || false);

	const [flipCount, setFlipCount] = useState( 0 );

	const [animationGo, setAnimationGo] = useState( false );

	const [goNext, setGoNext] = useState(false);

	const [isMobile, setIsMobile] = useState(
		typeof window !== 'undefined' && window.innerWidth < 1024
	);

	//const prompt = usePrompt( (allowUsePrompt && (flipCount >= 0)), "If you leave this page, you will invalidate your game play.  Choose 'Cancel' to return and finish the game. Click 'OK' to continue leaving.");


	/** FUNCTIONS */
	/** Add particular css files to the page */
	const addCss = (css) => {
		var fileref=document.createElement("link");
		fileref.setAttribute("rel", "stylesheet")
		fileref.setAttribute("type", "text/css")
		fileref.setAttribute("href", `https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/css/${css}`);
		document.getElementsByTagName("head")[0].appendChild(fileref);
	}
	
	/* Show the results of this game play */
	const showResults = (actionLog) => {
		setTimeout(function() {
			addCSSClass( "game_results_screen", "result-screen-fade-in" );
			addCSSClass( "game_results_options", "result-options-fade-in" );
			addCSSClass( "game_match", "result-game-done" );
			setIntegrationData( JSON.stringify(actionLog, null, 4) + " UID: " + promotion.configuration.uid );
			hideLosingCards();
			highlightWinner(actionLog);
			setAnimationGo( true );
			dispatch( notifyEndGame(promotion, gameClaim, actionLog, entry.token, gameStartTime) );
		}, promotion.configuration.config.games.match.resultDelay);			
	}

	const hideLosingCards = () => {
		for ( let i=0; i < tileConfig.totalCards; i++ ) {
			//let divName = "card_" + (i+1);
			let divName = "card_" + tileConfig.cards[i].cardKey;
			if ( tileConfig.cards[i].winSequence < 0 ) {
				addCSSClass(divName, "hide");
			}
		}
	}
	
	/* Move a winning tile, test */
	const highlightWinner = (actionLog) => {
		setTimeout(function() {
			for(let i = 0; i < actionLog.length; i++) {
				if ( Number(actionLog[i].winSequence) > 0 ) {
					let divName = "card_" + actionLog[i].key;
					let cName = "win" + actionLog[i].winSequence;
					addCSSClass( divName, cName );
				} else {
					let divName = "card_" + actionLog[i].key;
					let cName = "lose";
					addCSSClass( divName, cName );
				}
			}
			addCSSClass( "card_grid_wrapper", "game-done" );
			
		}, promotion.configuration.config.games.match.winResultDelay);	
	}
	
	/* Show the prize redemption form */
	const showForm = () => {
		addCSSClass( "redeem_form", "result-redeem-fade-in" );
		addCSSClass( "redeem_button", "btn-redeem-out" );
		addCSSClass( "form_win_screen_show_results_button", "btn-redeem-out" );
	}

	{/** Put in copy based on show flags **/}
	useEffect(() => {
		if ( showTitle && !empty(promotion) ) {
			let titleCopy = getJsonContent(promotion, GC.PAGE_IW_MATCH, "title", "<h2>DEFAULT TITLE</h2>", entry);
			setContent("title_copy", titleCopy);
		}
	}, [showTitle]);
	useEffect(() => {
		if ( showCopy && !empty(promotion) ) {
			let copyCopy = getJsonContent(promotion, GC.PAGE_IW_MATCH, "copy", "<h2>DEFAULT COPY</h2>", entry);
			setContent("copy_copy", copyCopy);
		}
	}, [showCopy]);

	{/** EXTERNAL INTEGRATION CALLS */}
	const sendNotifyStartGame = () => {
		let gst = setFormattedTime();
		setGameStartTime( gst );
		// DECIDED NOT TO HAVE A SEPARATE GAME START CALL
		// THEN PUT IT BACK IN, BUT ONLY FOR THE FRONT END
		dispatch( notifyStartGame(promotion, entry, gst, false) );
	}

	// const sendNotifyEndGame = ( actionLog ) => {
	// 	dispatch( notifyEndGame(actionLog) );
	// }

	const handleAction = ( action, value ) => {
		if ( action === "THANKYOU" ) {
			setFlipCount(-1);
		}
		dispatch( sendAction(promotion, action, value) );
	}
	{/** END EXTERNAL INTEGRATION CALLS */}

    
	/** Sets the tile configuration from the game claim */
	useEffect(() => {
		if ( !empty(gameClaim) ) {
			setTileConfig( gameClaim.parameters.tileConfig );
			setResultImage( gameClaim.parameters.resultImage );
			setResultCss( gameClaim.parameters.resultCss );
			setShowTitle( promotion.configuration?.config?.pages?.gamePage?.showTitle );
			setShowCopy( promotion.configuration?.config?.pages?.gamePage?.showCopy );
		}
    }, [gameClaim]);

	useEffect(() => {
		if ( !empty(promotion) ) {
			setAllowUsePrompt( promotion.configuration?.config?.games?.allowUsePrompt );
			setShowTitle( promotion.configuration?.config?.pages?.gamePage?.showTitle );
			setShowCopy( promotion.configuration?.config?.pages?.gamePage?.showCopy );
		}
	}, [promotion]);
	
	/** Sets the result css from the game claim */
	useEffect(() => {
		if ( !empty(resultCss) ) {
			addCss( resultCss );
		}
    }, [resultCss]);
	


	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 1024);
		}

		if (typeof window !== 'undefined') {
			handleResize();
		}

		window.addEventListener('resize', handleResize);
		return () => {
			// remove event listener when the component is unmounted to not cause any memory leaks
			// otherwise the event listener will continue to be active
			window.removeEventListener('resize', handleResize);
		};
		// add `isMobile` state variable as a dependency so that
		// it is called every time the window is resized
	}, [isMobile]);

	useEffect(() => {
		if ( flipCount === -1  ) {
			setGoNext( true );
		}
	}, [flipCount]);

	useEffect(() => {
		if ( !empty(winFormUpdated) && (winFormUpdated === true) ) {
			setFlipCount( -1 );
		}
	}, [winFormUpdated] );

	if ( goNext ) {

		return( <PageFlow page={GC.PAGE_IW_MATCH_CONFIGURABLE} error={error} /> );

	} else {

		return (

			<React.Fragment>

				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>

				<div id="game_match">

					<table>
						<tbody>
						<tr>
							<td className="page-visual">
								<div className="view-desktop">
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg`}/>
								</div>
								<div className="view-phone" style={{display: 'none'}}>
									<img
										src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/game-screen-play.jpg`}/>
								</div>
							</td>
							<td className="page-content">

								{(introAnimationOn && (introAnimationType === "lottie")) &&
									<GameIntroLottieAnimationWrapper
										promotion={promotion}
										introAnimationOn={introAnimationOn}
									/>}

								<div id="dv_game_mask">
									<div className="view-desktop">
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/iw-game-intro-mask.jpg`}/>
									</div>
									<div className="view-phone" style={{display: 'none'}}>
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/iw-game-intro-mask.jpg`}/>
									</div>
								</div>

								<div id='match_game_content'>
									{showTitle && <div id="title_copy">Title One</div>}
									{showCopy && <div id="copy_copy">Copy One</div>}
								</div>

								<div id="game_match_board" className="hide">
									<div id="game_cards">

										<TileBlock
											totalCards={tileConfig.totalCards}
											cards={tileConfig.cards}
											promoName={promotion.configuration.name}
											showResults={showResults}
											sendNotifyStartGame={sendNotifyStartGame}
											setFlipCount={setFlipCount}
										/>

									</div>
								</div>


								{/** Component holding all the game results **/}
								<_IWGameResult promotion={promotion} gameClaim={gameClaim} entry={entry}
											   handleAction={handleAction} showForm={showForm}
											   animationGo={animationGo}/>

								<div id="game_results_screen">
									<div className="view-desktop">
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
									</div>
									<div className="view-phone" style={{display: 'none'}}>
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/${resultImage}`}/>
									</div>
								</div>


							</td>
						</tr>
						</tbody>
					</table>

					<CustomContent/>

				</div>

			</React.Fragment>

		);

	}
	
}

export default IWGameMatchConfigurable;

