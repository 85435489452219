import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import {
    empty,
    logIt
} from "__globals/global-functions";

import {
    getJsonContent,
    setContent,
    getJsonResultCopy
} from '__globals/copy-functions';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import {addGameClaimVariables} from "../../../../_features/entries/entry-slice";

export const LoseStandard = props => {

    logIt( "WIN LOSE STANDARD PAGE IS CALLED", null, false );

    const dispatch = useDispatch();

    // LOCAL VARIABLES
    const [showTitle, setShowTitle] = useState( false);
    const [showResultCopy, setShowResultCopy] = useState( true);
    const [showCopy, setShowCopy] = useState( false);
    const [showActionOne, setShowActionOne] = useState( true);
    const [showActionTwo, setShowActionTwo] = useState( false);
    const [showActionThree, setShowActionThree] = useState( false);

    const handleActionButton1 = () => {
        let action = props.gameClaim?.parameters?.resultScreen?.actionOne?.action;
        let value = props.gameClaim?.parameters?.resultScreen?.actionOne?.value;
        props.handleAction( action, value );
    }

    const handleActionButton2 = () => {
        let action = props.gameClaim?.parameters?.resultScreen?.actionTwo?.action;
        let value = props.gameClaim?.parameters?.resultScreen?.actionTwo?.value;
        props.handleAction( action, value );
    }

    const handleActionButton3 = () => {
        let action = props.gameClaim?.parameters?.resultScreen?.actionThree?.action;
        let value = props.gameClaim?.parameters?.resultScreen?.actionThree?.value;
        props.handleAction( action, value );
    }

    /** Sets show flags from the config */
    useEffect(() => {
        if ( !empty(props.gameClaim) && !empty(props.promotion) ) {
            setShowTitle( props.gameClaim?.parameters?.resultScreen?.showTitle );
            setShowResultCopy( props.gameClaim?.parameters?.resultScreen?.showResultCopy );
            setShowCopy( props.gameClaim?.parameters?.resultScreen?.showCopy );
            setShowActionOne( props.gameClaim?.parameters?.resultScreen?.actionOne?.show );
            setShowActionTwo( props.gameClaim?.parameters?.resultScreen?.actionTwo?.show );
            setShowActionThree( props.gameClaim?.parameters?.resultScreen?.actionThree?.show );
            dispatch( addGameClaimVariables(props.gameClaim) );
        }
    }, [props.gameClaim, props.promotion]);

    {/** Put in copy based on show flags */}
    useEffect(() => {
        if ( showTitle && !empty(props.promotion) ) {
            let aiLoseScreenTitle = getJsonContent(props.promotion, GC.RESULT_LOSE, "title", "<h2>DEFAULT TITLE</h2>", props.entry );
            setContent("standard_lose_screen_title", aiLoseScreenTitle);
        }
    }, [showTitle]);
    useEffect(() => {
        if ( showResultCopy && !empty(props.promotion) && !empty(props.gameClaim) ) {
            let resultCopy = getJsonResultCopy(props.promotion, GC.RESULT_LOSE, props.gameClaim, "<h2>ERROR</h2>", props.entry );
            setContent("standard_lose_screen_result_copy", resultCopy);
        }
    }, [showResultCopy]);
    useEffect(() => {
        if ( showCopy && !empty(props.promotion) ) {
            let aiLoseScreenCopy = getJsonContent(props.promotion, GC.RESULT_LOSE, "copy", "<h2>DEFAULT COPY</h2>", props.entry );
            setContent("standard_lose_screen_copy", aiLoseScreenCopy);
        }
    }, [showCopy]);
    useEffect(() => {
        if ( showActionOne && !empty(props.promotion) ) {
            let aiLoseScreenButton1 = getJsonContent(props.promotion, GC.RESULT_LOSE, "button1", "<h2>DEFAULT BTN 1</h2>", props.entry );
            setContent("standard_lose_screen_button_1", aiLoseScreenButton1);
        }
    }, [showActionOne]);
    useEffect(() => {
        if ( showActionTwo && !empty(props.promotion) ) {
            let aiLoseScreenButton2 = getJsonContent(props.promotion, GC.RESULT_LOSE, "button2", "<h2>DEFAULT BTN 2</h2>", props.entry );
            setContent("standard_lose_screen_button_2", aiLoseScreenButton2);
        }
    }, [showActionTwo]);
    useEffect(() => {
        if ( showActionThree && !empty(props.promotion) ) {
            let aiLoseScreenButton3 = getJsonContent(props.promotion, GC.RESULT_LOSE, "button3", "<h2>DEFAULT BTN 3</h2>", props.entry );
            setContent("standard_lose_screen_button_3", aiLoseScreenButton3);
        }
    }, [showActionThree]);
    {/** END of put in copy based on show flags */}

    // RETURN THE GAME RESULT
    return (
        <div id='lose_standard' >
            {showTitle && <div id="standard_lose_screen_title">Title One</div>}
            {showCopy && <div id="standard_lose_screen_copy">Copy One</div>}
            {showResultCopy && <div id="standard_lose_screen_result_copy">Result Copy</div>}
            {showActionOne && <div id="standard_lose_screen_button_1" onClick={handleActionButton1}>Action One</div>}
            {showActionTwo && <div id="standard_lose_screen_button_2" onClick={handleActionButton2}>Action Two</div>}
            {showActionThree && <div id="standard_lose_screen_button_3" onClick={handleActionButton3}>Action Three</div>}
        </div>
    );
}


export default LoseStandard;