import { combineReducers } from 'redux';
import entryReducer from '_features/entries/entry-slice';
import promotionReducer from '_features/promotion/promotion-slice';
import entryGroupReducer from '_features/entry-group/entry-group-slice';
import userContentReducer from '_features/user-content/user-content-slice';
import gwpSliceReducer from '_features/gift-with-purchase/gift-with-purchase-slice';
import authenticationReducer from '_features/authentication/authentication-slice';
import challengePromoReducer from '_features/challenge-promo/challenge-promo-slice';
import emailReducer from '_features/emails/email-slice';
import externalIntegrationReducer from '_features/external-integration/external-integration-slice';

//import fileUploadReducer from 'redux-file-upload';

export default combineReducers({
    entry: entryReducer,
    promotion: promotionReducer,
    entrygroup: entryGroupReducer,
    usercontent: userContentReducer,
//    fileupload: fileUploadReducer,
    gwp: gwpSliceReducer,
    authentication: authenticationReducer,
    challengepromo: challengePromoReducer,
    email: emailReducer,
    externalintegration: externalIntegrationReducer
});