import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {ProviderImage} from "_pages/Raffle/ProviderImage/ProviderImage";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";

import {
    empty,
    logIt
} from '__globals/global-functions';
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {
    hasProviderBeenScanned,
    retrievePromocracyParticipant,
    retrieveRaffleLocalStorage,
    retrieveRaffleTicketTotal, storeTicketScanInLocalStorage
} from "__globals/promocracy-functions";
import {IFrameInsert} from "../../../_components/IFrameInsert/IFrameInsert";
import {Navigate} from "react-router-dom";

export const RaffleScanned = props => {

    logIt(GC.PAGE_RAFFLE_SCANNED + " PAGE IS CALLED", null, false);
    const PAGE_DIV_NAME = "raffle_scanned"; // use underscores
    const PAGE_KEY = "raffleScanned"; // use camel case
    const PAGE_IMAGE_KEY = "raffle-scanned"; // use dashes
    const PAGE_JSON_KEY = "RaffleScanned"; // use init caps

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_RAFFLE_SCANNED, promotion, true );

    const lastTicketProvider = useSelector(state => state.entry.lastTicketProvider);
    logIt( "LAST TICKET PROVIDER at " + GC.PAGE_RAFFLE_SCANNED, lastTicketProvider, true );

    const lastTicketsProvided = useSelector(state => state.entry.lastTicketsProvided);
    logIt( "LAST TICKETS PROVIDED at " + GC.PAGE_RAFFLE_SCANNED, lastTicketsProvided, true );

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const [provider, setProvider] = useState( '' );
    const [totalTickets, setTotalTickets] = useState( 0 );
    const [isReset, setIsReset] = useState( false );

    const handleReturnAction = () => {
        setGoNext( true );
    }

    const buildProviderImageName = () => {
        if ( !empty(provider) ) {
            return provider.logoImage;
        }
    }

    const doAction = (a, v) => {
        if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
            setAction(a);
            setValue(v);
            setGoNext( true );
        } else {
            dispatch(executeAction(a, v));
        }
    }

    useEffect(() => {
        if ( !empty(promotion) ) {
            if ( !empty(lastTicketProvider) ) {
                let tt = retrieveRaffleTicketTotal();
                setTotalTickets(tt);
                let p = retrievePromocracyParticipant(promotion.configuration, lastTicketProvider);
                setProvider(p);
            }
        }
    }, [lastTicketProvider] );

    useEffect( () => {
        if ( !empty(promotion) ) {
            if ( empty(lastTicketProvider) ) {
                if (!empty(promotion.configuration.source)) {
                    let ticketProvider = retrievePromocracyParticipant(promotion.configuration, promotion.configuration.source);
                    if (hasProviderBeenScanned(ticketProvider)) {
                        logIt("TICKET PROVIDER WAS ALREADY STORED", ticketProvider, true);
                        doAction("NAVIGATE", "already-scanned");
                    } else {
                        logIt("STORING TICKET PROVIDER", ticketProvider, true);
                        storeTicketScanInLocalStorage(ticketProvider)
                    }
                    setProvider(ticketProvider);
                    let tt = retrieveRaffleTicketTotal();
                    setTotalTickets(tt);
                } else {
                    let tt = retrieveRaffleTicketTotal();
                    setTotalTickets(tt);
                }
            }
        }
    }, [promotion])

    if (goNext) {

        return (<PageFlow page={GC.PAGE_RAFFLE_SCANNED} error={null} action={action} value={value}/>);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />
                                    {(empty(provider)) ? (
                                            <React.Fragment>
                                                <div id="dv_visited_provider">Welcome Back!</div>
                                                <div id="dv_total_tickets">TOTAL TICKETS: <span>{totalTickets}</span></div>
                                                <div id="dv_scan_again_button" onClick={handleReturnAction}>Scan Again</div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div id="dv_visited_provider">You Visited: <span>{provider.displayName}</span></div>
                                                <div id="dv_tickets_earned">Tickets Earned: <span>{lastTicketsProvided}</span></div>
                                                <div id="dv_provider_image"><ProviderImage promotion={promotion} providerImageName={buildProviderImageName()}/></div>
                                                <div id="dv_total_tickets">TOTAL TICKETS: <span>{totalTickets}</span></div>
                                                <div id="dv_scan_again_button" onClick={handleReturnAction}>Scan Again</div>
                                            </React.Fragment>
                                        )}

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                </div>

                                <IFrameInsert
                                    promotion={promotion}
                                    configKey={PAGE_KEY}
                                />

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );

    }

}