import React from 'react';
import {getRandomUrlAppendage} from "__globals/global-functions";

var addOn = getRandomUrlAppendage("rd");

export const PageBackgroundImages = props => {

    return (
        <React.Fragment>
            <div className="view-desktop"><img
                src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${props.pageKey}-screen.jpg${addOn}`}/>
            </div>
            <div className="view-phone" style={{display: 'none'}}><img
                src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}phone/${props.pageKey}-screen.jpg${addOn}`}/>
            </div>
        </React.Fragment>
    );

}