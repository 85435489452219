import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Card} from './Card';

import { useBlocker } from 'react-router-dom';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	logIt,
	empty,
	getRandomUrlAppendage
} from '__globals/global-functions';

import { addCss, addCSSClass, removeCSSClass } from '__globals/css-functions';

// import {
// 	usePrompt
// } from '__globals/usePrompt.tsx';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

import _IWGameResult from "_pages/InstantWin/_Results/_IWGameResult/_IWGameResult";

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import {sendAction} from "_features/external-integration/external-integration-slice";
import PageFlow from "../../__routes/PageFlow";
import {PageBackgroundImages} from "../../../_components/PageBackgroundImages/PageBackgroundImages";
import {PageCopy} from "../../../_components/PageCopy/PageCopy";
import {PromocracyChooser} from "_pages/Variations/Promocracy/PromocracyChooser/PromocracyChooser";
import {retrievePromocracyParticipant} from "../../../__globals/promocracy-functions";
import {postEntry, processClearPromocracyUpdate} from "../../../_features/entries/entry-slice";

let addOn = getRandomUrlAppendage("rd");

export const IWGameMatch = props => {

	logIt( GC.PAGE_IW_MATCH + " PAGE IS CALLED", null, false );
	const PAGE_DIV_NAME = "iw_game_match"; // use underscores
	const PAGE_KEY = "iwGameMatch"; // use camel case
	const PAGE_IMAGE_KEY = "iw-game-match"; // use dashes
	const PAGE_JSON_KEY = GC.PAGE_IW_MATCH; // use init caps (IWGameMatch)

	const dispatch = useDispatch();
	
	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at IWGameMatch", promotion, true );
	
	let entry = useSelector(state => state.entry);
	//logIt( "ENTRY at IWGameMatch", entry, true);

	let gameClaim = useSelector(state => state.entry.gameClaim);
	//logIt( "GAME CLAIM at IWGameMatch", gameClaim, true);

	let error = useSelector(state => state.entry.error);
	logIt( "ERROR AT IWGameMatch", error, true );

	let entryUpdated = useSelector(state => state.entry.entryUpdated);
	//logIt( "entryUpdated AT IWGameMatch", error, true );

	let winFormUpdated = useSelector(state => state.entry.winFormUpdated);
	//logIt( "winFormUpdated AT IWGameMatch", error, true );

	let promocracyUpdateComplete = useSelector( state => state.entry.promocracyUpdateComplete );

	/** LOCAL VARIABLES */
	const [allowUsePrompt, setAllowUsePrompt] = useState( false);

    const [flipCount, setFlipCount] = useState( 0 );
	const [resultImage, setResultImage] = useState( '' );
	const [resultCSS, setResultCSS] = useState( '' );

	const [animationGo, setAnimationGo] = useState( false );
	const [goNext, setGoNext] = useState(false);

	const [chooserOn, setChooserOn] = useState(false);
	const [promocracyLoopBack, setPromocracyLoopBack] = useState(false);
	const [promocracyCount, setPromocracyCount] = useState(0);

	//const prompt = usePrompt( (allowUsePrompt && (flipCount >= 0)), "If you leave this page, you will invalidate your game play.  Choose 'Cancel' to return and finish the game. Click 'OK' to continue leaving.");

	/** HELPER FUNCTIONS **/
    const flipCard = (cardNo) => {
    	let divName = "card" + cardNo;
    	addCSSClass(divName, "is-flipped");
    	addCSSClass(divName, "shadow-off");
		let fc = flipCount;
		fc++;
		setFlipCount( fc );
    }
	
	const showResults = () => {
		setTimeout(function() {
			addCSSClass( "game_results_screen", "result-screen-fade-in" );
			addCSSClass( "game_results_options", "result-options-fade-in" );
			addCSSClass( "game_cards", "result-match-game" );
			addCSSClass( "game_match", "result-game-done" );
			setAnimationGo( true );
			setChooserOn( true );
			if ( gameClaim?.parameters?.result === GC.RESULT_STANDARD ) {
				setFlipCount(-2);
			}
			setPromocracyLoopBack( false );
		}, promotion.configuration.config.games.match.resultDelay);			
	}

	const showForm = () => {
		addCSSClass( "redeem_form", "result-redeem-fade-in" );
		addCSSClass( "redeem_button", "btn-redeem-out" );
		addCSSClass( "form_win_screen_show_results_button", "btn-redeem-out" );
	}

	const handleAction = ( action, value ) => {
		if ( action === "THANKYOU" ) {
			setFlipCount( -1 );
		}
		dispatch( sendAction(promotion, action, value) );
	}

	const handlePromocracyParticipantPick = ( participantKey ) => {
		let promocracyParticipant = retrievePromocracyParticipant( promotion.configuration, participantKey );
		logIt( "promocracyParticipant at handlePromocracyParticipantPick", promocracyParticipant, true );
		if ( !empty(promocracyParticipant) ) {
			let newEntry = JSON.parse(JSON.stringify(entry.entry));
			newEntry["token"] = undefined;
			newEntry.entry["promocracyID"] = newEntry.entry.emailAddress + "_" + promocracyParticipant["key"];
			newEntry.entry["promocracyLogoImage"] = promocracyParticipant.logoImage;
			newEntry.entry["promocracyDisplayName"] = promocracyParticipant.displayName;
			newEntry.authToken = newEntry.authToken;
			logIt( "newEntry at handlePromocracyParticipantPick", newEntry, true );
			let pc = promocracyCount;
			setPromocracyCount( pc++ );
			// TODO: Figure out how this works with adding a second "game"
			//let gameName = "MatchToWin" + "_" + participantKey;
			//alert(gameName);
			dispatch( postEntry(newEntry, null, null, promotion.configuration.name, "MatchToWin", null, true, promocracyParticipant) );
		}
	}

	const resetPromocracy = () => {
		let divName;
		for ( let i=0; i <= 9;i++ ) {
			divName = "card" + i;
			removeCSSClass(divName, "is-flipped");
			removeCSSClass(divName, "shadow-off");
		}
		removeCSSClass( "game_results_screen", "result-screen-fade-in" );
		removeCSSClass( "game_results_options", "result-options-fade-in" );
		removeCSSClass( "game_cards", "result-match-game" );
		removeCSSClass( "game_match", "result-game-done" );
		setFlipCount( 0 );
		setPromocracyLoopBack( false );
		dispatch( processClearPromocracyUpdate() );
	}

	/** END HELPER FUNCTIONS **/

	/** USE EFFECT HOOKS **/
	useEffect(() => {
		if ( !empty(promotion) ) {
			setAllowUsePrompt( promotion.configuration?.config?.games?.allowUsePrompt );
			if ( flipCount > 0 ) {
				setGoNext( false );
				setFlipCount(0)
			}
		}
	}, [promotion]);

	useEffect(() => {
		if ( flipCount >= promotion.configuration.config.games.match.cardCount ) {
			showResults();
		} else if ( flipCount === -1  ) {
			setGoNext( true );
		}
    }, [flipCount]);

	useEffect(() => {
		if ( !empty(gameClaim) ) {
			setResultImage( gameClaim.parameters.resultImage );
			setResultCSS( gameClaim.parameters.resultCss );
		}
	}, [gameClaim]);

	useEffect(() => {
		if ( (promotion?.retrieved) && !empty(resultCSS) ) {
			addCss( promotion, resultCSS );
		}
	}, [resultCSS]);

	useEffect(() => {
		if ( !empty(error) ) {
			alert( JSON.stringify(error) );
			//setGoNext( true );
		}
	}, [error] );

	useEffect(() => {
		if ( !empty(entryUpdated) && (entryUpdated === true) ) {
			//goNextPage( promotion, GC.PAGE_IW_MATCH, error );
			setFlipCount( -1 );
		}
	}, [entryUpdated] );

	useEffect(() => {
		if ( !empty(winFormUpdated) && (winFormUpdated === true) ) {
			setFlipCount( -1 );
		}
	}, [winFormUpdated] );

	useEffect( () => {
		if ( promocracyUpdateComplete ) {
			if (promocracyCount < 3) {
				setPromocracyLoopBack(true);
			}
		}
	}, [promocracyUpdateComplete, promocracyCount] );

	useEffect( () => {
		if ( promocracyLoopBack ) {
			resetPromocracy()
		}
	}, [promocracyLoopBack] );

	/** END USE EFFECT HOOKS **/


	if ( goNext ) {

		return( <PageFlow page={GC.PAGE_IW_MATCH} error={error} promocracyLoopBack={promocracyLoopBack} /> );

	} else {

		return (

			<React.Fragment>

				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
				<div id={PAGE_DIV_NAME + "_page"}>

					<table>
						<tbody>
						<tr>
							<td className="page-visual">

								<PageBackgroundImages
									promotion={promotion}
									pageKey={"iw-match"}
								/>

							</td>
							<td className="page-content">

								<div id={PAGE_DIV_NAME + "_content"}>

									<PageCopy
										promotion={promotion}
										configKey={PAGE_KEY}
										jsonKey={GC.PAGE_IW_MATCH}
										entry={entry}
									/>

								</div>


								<div id='game_match_board'>

									<div id='game_cards'>

										<div id='dv_no_refresh_instructions'>
											Do not refresh your browser,
											you will lose this game play.
										</div>

										{(promotion.configuration.config.games.match.cardCount >= 1) &&
											<Card promotion={promotion} cardNo="1" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 2) &&
											<Card promotion={promotion} cardNo="2" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 3) &&
											<Card promotion={promotion} cardNo="3" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 4) &&
											<Card promotion={promotion} cardNo="4" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 5) &&
											<Card promotion={promotion} cardNo="5" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 6) &&
											<Card promotion={promotion} cardNo="6" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 7) &&
											<Card promotion={promotion} cardNo="7" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 8) &&
											<Card promotion={promotion} cardNo="8" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

										{(promotion.configuration.config.games.match.cardCount >= 9) &&
											<Card promotion={promotion} cardNo="9" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage} entry={entry}/>}

									</div>
								</div>

								{/** Component holding all the game results **/}
								<_IWGameResult promotion={promotion}
											   gameClaim={gameClaim}
											   entry={entry}
											   handleAction={handleAction}
											   showForm={showForm}
											   animationGo={animationGo}/>


								<div id="game_results_screen">
									<div className="view-desktop">
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
									</div>
									<div className="view-phone" style={{display: 'none'}}>
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/${resultImage}`}/>
									</div>
								</div>

								<PromocracyChooser
									promotion={promotion}
									chooserOn={chooserOn}
									entry={entry}
									handlePromocracyParticipantPick={handlePromocracyParticipantPick}
								/>


							</td>
						</tr>
						</tbody>
					</table>


					<CustomContent/>

				</div>

			</React.Fragment>

		);

	}

}




