import { createSlice } from '@reduxjs/toolkit';

import {
    dgGameStart,
    dgGameStartServer,
    dgGameEnd,
    dgGameEndServer,
    dgNotifyAction
} from '_api/DollarGeneralAPI';

import {getGameName, logIt} from '__globals/global-functions';
import {updateEntry} from "../entries/entry-slice";
import {
    executeAction,
    isInternalAction
} from "__globals/actions";


const externalIntegrationSlice = createSlice({
    name: 'externalintegration',
    initialState:{
        retrieved:false,
        configuration: {}
    },
    reducers: {
        processNotifyStartGame(state, action) {
            if(action.payload !== undefined)
            {
                // ??
            }
        },
        processNotifyEndGame(state, action) {
            if(action.payload !== undefined)
            {
                // ??
            }
        }
    }
});

export const {
    processNotifyStartGame,
    processNotifyEndGame
} = externalIntegrationSlice.actions;

export const notifyStartGame = ( promotion, entry, gameStartTime, callServer ) => async (dispatch, getState) => {
    if ( promotion.configuration?.config?.customIntegration?.indexOf("DollarGeneral") >= 0 ) {
        let result = await dgGameStart( entry.token, gameStartTime );
        if ( callServer ) {
            let serverResult = await dgGameStartServer(promotion, entry.token);
        }
        let addEntryObj = {
            entry: {
                "clickedFirst": true
            }
        }
        dispatch( updateEntry( addEntryObj ) );
    }

    // payload
    dispatch( processNotifyStartGame() );
}

export const notifyEndGame = ( promotion, gameClaim, actionLog, token, gameStartTime ) => async (dispatch, getState) => {
    let gameName = getGameName( promotion.configuration );
    let useGameClaimCommit = gameClaim.releaseAbandonedClaims;
    if ( promotion.configuration?.config?.customIntegration?.indexOf("DollarGeneral") >= 0 ) {
        let result = await dgGameEnd( gameClaim, actionLog, token, gameName, useGameClaimCommit, gameStartTime );
        let serverResult = await dgGameEndServer( promotion, gameClaim, token, gameStartTime );
    }

    // payload
    dispatch( processNotifyEndGame() );
}

export const sendAction = ( promotion, action, value ) => async (dispatch, getState) => {
    if ( isInternalAction(action) ) {
        executeAction( action, value );
        return;
    }
    if ( promotion.configuration?.config?.customIntegration?.indexOf("DollarGeneral") >= 0 ) {
        let result = await dgNotifyAction(action, value);
    } else if ( promotion.configuration?.config?.customIntegration?.indexOf("SandRewardsTest") >= 0 ) {
        alert( "This would integrate with an external app" );
    }
}


export default externalIntegrationSlice.reducer;