import { createSlice } from '@reduxjs/toolkit';

import { logIt, empty } from '__globals/global-functions';

import { 
	postLoginUser,
	postForgotPassword,
	postResetPassword,
	postRetrieveAccountData,
	postUpdateAccountData,
	postRetrieveSecurityQuestion,
	postRetrieveGroupedEntries,
	postRetrievePromotionEntries
} from '_api/AuthenticationAPI';

const authenticationSlice = createSlice({
	name: 'authentication',
	initialState: {},
	reducers: {
		processUserLogin(state, action) {
			state.authToken = action.payload.result.message;
		},
		processForgotPassword(state, action) {
			if ( !empty(action.payload.result.status) ) {
				state.forgotPWStatus = "ok";
				if ( !empty(action.payload.result.message) ) {
					state.resetId = action.payload.result.message
				}
			}
		},
		processResetPassword(state, action) {
			if ( !empty(action.payload.result.status) ) {
				state.resetPWStatus = "ok";
			}
		},
		processRetrieveAccountData(state, action) {
			state.accountData = action.payload.result;
		},
		processUpdateAccountData(state, action) {
			state.accountData = action.payload.result;
		},
		processRetrieveSecurityQuestion(state, action) {
			state.securityQuestion = action.payload.result.securityQuestion;
		},
		processRetrieveGroupedEntries(state, action) {
			state.entriesObject = action.payload.result;
		},
		processRetrievePromotionEntries(state, action) {
			state.promoEntriesObject = action.payload.result;
		},
		processSetAuthtoken( state, action ) {
			if ( !empty(action.payload.authToken) ) {
				state.authToken = action.payload.authToken;
			}
		},
		processServerError(state, action) {
			state.error = action.payload;
			logIt( "processServerError PAYLOAD", action.payload, true );		  }
	  }
});

export const {
	processUserLogin,
	processForgotPassword,
	processResetPassword,
	processSetAuthtoken,
	processRetrieveAccountData,
	processUpdateAccountData,
	processRetrieveSecurityQuestion,
	processRetrieveGroupedEntries,
	processRetrievePromotionEntries,
	processServerError
} = authenticationSlice.actions;

export const userLogin = ( userData ) => async (dispatch, getState) => {
    const result = await postLoginUser( userData );
	logIt( "result at userLogin", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processUserLogin(payload) );
	}
}

export const forgotPassword = ( userData ) => async (dispatch, getState) => {
    const result = await postForgotPassword( userData );
	logIt( "result at forgotPassword", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processForgotPassword(payload) );
	}
}

export const resetPassword = ( userData ) => async (dispatch, getState) => {
    const result = await postResetPassword( userData );
	logIt( "result at resetPassword", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processResetPassword(payload) );
	}
}

export const setAuthToken = ( authToken ) => async (dispatch, getState) => {
	let payload = {};
	payload.authToken = authToken;
	dispatch( processSetAuthtoken(payload) );
}

export const retrieveAccountData = ( tokenJSON ) => async (dispatch, getState) => {
    const result = await postRetrieveAccountData( tokenJSON );
	logIt( "result at retrieveAccountData", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processRetrieveAccountData(payload) );
	}
}

export const updateAccountData = ( accountData ) => async (dispatch, getState) => {
    const result = await postUpdateAccountData( accountData );
	logIt( "result at updateAccountData", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processUpdateAccountData(payload) );
	}
}

export const retrieveSecurityQuestion = ( jsonData ) => async (dispatch, getState) => {
    const result = await postRetrieveSecurityQuestion( jsonData );
	logIt( "result at retrieveSecurityQuestion", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processRetrieveSecurityQuestion(payload) );
	}
}

export const retrieveGroupedEntries = ( jsonData ) => async (dispatch, getState) => {
    const result = await postRetrieveGroupedEntries( jsonData );
	logIt( "result at retrieveGroupedEntries", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processRetrieveGroupedEntries(payload) );
	}
}

export const retrievePromotionEntries = ( jsonData ) => async (dispatch, getState) => {
    const result = await postRetrievePromotionEntries( jsonData );
	logIt( "result at retrievePromotionEntries", result, true );
	if ( empty(result) || result.status === "error" ) {
		dispatch( processServerError(result) );
	} else {
		let payload = {};
		payload.result = result;
		dispatch( processRetrievePromotionEntries(payload) );
	}
}


export default authenticationSlice.reducer;


