import axios from 'axios';
import {	logIt,
			inspectFormData } from '__globals/global-functions';

//const hostName = window.REACT_APP_AUTHENTICATION_DOMAIN;
const hostName = window.REACT_APP_PROMOTION_DOMAIN;
const prefix = "https://";

export async function getGalleryImages( entryGroupId, token, page, size )
{
	let response;
	let url;
	if ( token !== null ) {
		url = prefix + hostName + '/entries-admin/' + entryGroupId;
	    if ( size > 0 ) {
	    	url += "?page=" + page + "&size=" + size;
	    }
		logIt( "URL in getGalleryImages", url, false );
		const config = {
				headers: { 
					'Authorization': `Bearer ${token}`
	            },
	            responseType: 'application/json' 
		}
		response = await axios.get( url, config );
    } else {
		url = prefix + hostName + '/entries/' + entryGroupId;
	    if ( size > 0 ) {
	    	url += "?page=" + page + "&size=" + size;
	    }
		logIt( "URL in getGalleryImages", url, false );
    	response = await axios.get( url );
    }
	logIt( "RESPONSE in getGalleryImages", response, true );
	let gallery = [];
	let curEntry = {};
	//alert("building gallery")
	for ( var i=0; i < response.data.length; i++ ) {
		curEntry = {};
		curEntry.id = response.data[i].id;
		curEntry.firstName = response.data[i].data.firstName;
		curEntry.lastName = response.data[i].data.lastName;
		curEntry.caption = response.data[i].data.caption;
		curEntry.galleryImage = response.data[i].data.galleryImage;
		gallery[i] = curEntry;
	}
	logIt( "RESPONSE in getGalleryImages", gallery, true );
    return gallery;
}

export async function postImageStatus( statusObj )
{
    const url = prefix + hostName + '/image-status';
    const {data} = await axios.post( url, statusObj );
    return data;
}

export async function postUploadFile( formData )
{
	//logIt( "POST UPLOAD FILE formData", formData, false );
	const url = prefix + hostName + '/upload-file';
	const {data} = await axios.post(url, formData);
	return data;
}