import React from "react";

// RETURN THE CARD
export const Card = props => {
    return (
        <div className="card-wrapper">
            <div id={"card"+props.cardNo} className={"card card-"+props.cardNo}>
                <div className="card-face card-back" onClick={() => props.flipCard( props.cardNo )} >
                    {props.promotion.variables.promocracyOn ?
                        (<div id="dv_promocracy-logo">
                            <img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/promocracy_logos/${props.entry.variables["promocracyLogoImage"]}${props.addOn}`}/>
                        </div>) :
                        (<div>
                            <img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/game-match-card-back.jpg${props.addOn}`} />
                        </div>)
                    }
                </div>
                <div className="card-face card-front">
                    <div><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${props.resultImage}`} /></div>
                </div>
            </div>
        </div>
    );
}