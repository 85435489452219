import React, {useEffect, useState} from "react";

import {empty, logIt} from "__globals/global-functions";
import {useSelector} from "react-redux";
import {getJsonContent, setContent} from "../../../__globals/copy-functions";
import CustomCarousel from "./CustomCarousel";
import StandardContent from "./StandardContent";

import * as GC from "../../../__globals/GLOBAL-CONSTANTS";
import {SECTION_CONTENT} from "../../../__globals/GLOBAL-CONSTANTS";
import Carousel from "react-bootstrap/Carousel";


export const CustomContent = props => {

    logIt( "CUSTOM CONTENT WRAPPER IS CALLED", null, false );

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at IWGameMatch", promotion, true );

    const contents = useSelector(state => state.promotion.configuration.config.variations?.customContent?.customContents);
    //logIt( "contents at CustomContent", contents, true );

    const [customContentOn, setCustomContentOn] = useState(false);
    const [customContentCount, setCustomContentCount] = useState(0);

    const getContentItems = () => {
        return (
            <div id="dv_custom_content">
                {getContents()}
            </div>
        );
    }

    const getContents = () => {
        let index = -1;
        let items = contents.map((content, key) => {
            index++;
            if ( content.type === GC.CUSTOM_CONTENT_TYPE_STANDARD ) {
                return (
                    <StandardContent promotion={promotion} contentKey={content.key} />
                );
            } else if ( content.type === GC.CUSTOM_CONTENT_TYPE_CAROUSEL ) {
                return (
                    <CustomCarousel slides={content.customCarousel.slides} promotion={promotion} contentKey={content.key}/>
                );
            }
        });
        return items;
    }


    /** USE EFFECT HOOKS **/
    useEffect(() => {
        if ( !empty(promotion) ) {
            setCustomContentOn(promotion.configuration.config?.variations?.customContent?.customContentOn);
            setCustomContentCount(promotion.configuration?.config?.variations?.customContent?.customContentCount);

            // if ((customContentOn && (customContentCount > 0))) {
            //     let custom01 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom01", "<h2>DEFAULT CONTENT 01</h2>");
            //     setContent("dv_custom_content_01", custom01);
            // }
            //
            // if ((customContentOn && (customContentCount > 1))) {
            //     let custom02 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom02", "<h2>DEFAULT CONTENT 02</h2>");
            //     setContent("dv_custom_content_02", custom02);
            // }
            //
            // if ((customContentOn && (customContentCount > 2))) {
            //     let custom03 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom03", "<h2>DEFAULT CONTENT 03</h2>");
            //     setContent("dv_custom_content_03", custom03);
            // }
            //
            // if ((customContentOn && (customContentCount > 3))) {
            //     let custom04 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom04", "<h2>DEFAULT CONTENT 04</h2>");
            //     setContent("dv_custom_content_04", custom04);
            // }
            //
            // if ((customContentOn && (customContentCount > 4))) {
            //     let custom05 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom05", "<h2>DEFAULT CONTENT 05</h2>");
            //     setContent("dv_custom_content_05", custom05);
            // }

        }
    }, [promotion]);
    //
    // useEffect(() => {
    //     if ( !empty(customContentCount) && !empty(customContentOn) &&  customContentOn && (customContentCount > 0) ) {
    //         if ((customContentOn && (customContentCount > 0))) {
    //             let custom01 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom01", "<h2>DEFAULT CONTENT 01</h2>");
    //             setContent("dv_custom_content_01", custom01);
    //         }
    //
    //         if ((customContentOn && (customContentCount > 1))) {
    //             let custom02 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom02", "<h2>DEFAULT CONTENT 02</h2>");
    //             setContent("dv_custom_content_02", custom02);
    //         }
    //
    //         if ((customContentOn && (customContentCount > 2))) {
    //             let custom03 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom03", "<h2>DEFAULT CONTENT 03</h2>");
    //             setContent("dv_custom_content_03", custom03);
    //         }
    //
    //         if ((customContentOn && (customContentCount > 3))) {
    //             let custom04 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom04", "<h2>DEFAULT CONTENT 04</h2>");
    //             setContent("dv_custom_content_04", custom04);
    //         }
    //
    //         if ((customContentOn && (customContentCount > 4))) {
    //             //let custom05 = getJsonContent(promotion, GC.CUSTOM_CONTENT, "custom05", "<h2>DEFAULT CONTENT 05</h2>");
    //             //setContent("dv_custom_content_05", custom05);
    //         }
    //     }
    // }, [customContentOn, customContentCount]);


    if ( customContentOn ) {
        return (
            <div id="dv_custom_content_wrapper">
                {getContentItems()}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }



}
export default CustomContent;