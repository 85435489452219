import { createSlice } from '@reduxjs/toolkit';

import { getEntryGroups } from '_api/PromotionAPI';

import { logIt } from '__globals/global-functions';


const entryGroupSlice = createSlice({
 name: 'entrygroup',
 initialState:{
	 retrieved:false,
	 configuration: {}
 },
 reducers: {
  processRemoteEntryGroup(state, action) {
   if(action.payload !== undefined)
   {
   	state.entryGroups = action.payload;
   	state.retrieved = true;
   }
  }
 }
});

export const {
	processRemoteEntryGroup
} = entryGroupSlice.actions;

export const fetchEntryGroups = ( promoId, token ) => async (dispatch, getState) => {
  if(getState().entrygroup && !getState().entrygroup.retrieved)
  {
    const result = await getEntryGroups( promoId, token );
    logIt( "RESULT at fetchEntryGroup", result, true );
    dispatch( processRemoteEntryGroup( result ) );
  }
}


export default entryGroupSlice.reducer;