import React, {useEffect, useState} from 'react';

export function Selection( props ) {
	
	useEffect(() => {
		if ( document.getElementsByClassName(props.questionKey).length > 0 ) {
			document.getElementsByClassName(props.questionKey)[0].innerHTML = props.question;
		}
		if ( document.getElementsByClassName(props.answerKey).length > 0 ) {
			document.getElementsByClassName(props.answerKey)[0].innerHTML = props.answer;
		}
	}, [props.question] );
	
	return (
		<div id="div-selection">
			<div id="div-selection-question" className={props.questionKey}>
				
			</div>
			<div id="div-selection-answer" className={props.answerKey}>
				
			</div>
		</div>
	);
	
}

export default Selection;

