//import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom'


import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

//import promotion from './reducers'
//import { createStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

const history = createBrowserHistory();

//Initialize google analytics page view tracking
history.listen(location => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const store = configureStore({
	reducer: rootReducer
});

ReactDOM.render(
	<Provider store={store} >
	  <GoogleReCaptchaProvider
	    reCaptchaKey="6LfS2dsUAAAAAIOjcCDAyrl9OC8nApC_wjcT5aFG"
	    //language="[optional_language]"
	  >
		  <BrowserRouter basename={"/"} future={{ v7_startTransition: true }}>
			  <App />
		  </BrowserRouter>
        </GoogleReCaptchaProvider>
	</Provider>,
    document.getElementById('root')
);

unregister();
