import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	logIt,
	empty } from '__globals/global-functions';

import {
	getJsonContent,
	setContent
} from '__globals/copy-functions';

let sanitizeHtml = require('sanitize-html');


export const About = props => {

	logIt( "ABOUT PAGE IS CALLED", "", false );
	
	const dispatch = useDispatch();
	
	// STATE VARIABLES
	const promotion = useSelector(state => state.promotion);
	logIt( "PROMOTION at SelectChallenge", promotion, true );
	
	const entry = useSelector(state => state.entry);
	logIt( "ENTRY at SelectChallenge", entry, true );
	
	const token = useSelector(state => state.entry.token);
	logIt( "TOKEN at SelectChallenge", token, true );
	
	const error = useSelector(state => state.challengepromo.error);
	logIt( "ERROR at SelectChallenge", error, true );
	
	
	// LOCAL VARIABLES
	const [errorMsgDisplay, setErrorMsgDisplay] = useState("");
	
	const fieldsInUse = promotion.configuration.config.fieldsInUse;
	const currentLanguage = promotion.configuration.currentLanguage;
	
	
	// FUNCTIONS

	

    // HOOKS
	useEffect(() => {
		let aboutPageContent = getJsonContent( promotion, "About", "aboutPage", "About Page Here" );
		setContent( "msg_content", aboutPageContent );
	}, []);

	useEffect(() => {
		if ( !empty(error) ) {
			setErrorMsgDisplay( error.errors[0].message );
		}
	}, [error]);

	
	// FATAL ERROR CHECK
	// TODO: Put this at App.js level
	 if ( entry.fatalError ) {
		 return(<div>FATAL ERROR: {entry.message}</div>);
	} else {
		
		
		// RETURN PAGE
		return (
				
			<React.Fragment>
				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title} />
				<div id="about_page">
				
				<table>
				<tbody>
				<tr>
					<td className="page-visual">
						<div className="view-desktop">
							<img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/about-screen.jpg`} />
						</div>
						<div className="view-phone" style={{ display:'none' }}>
							<img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/about-screen.jpg`} />
						</div>
					</td>
					<td className="page-content">

<div id="msg_content"></div>
		            
	                </td>
				</tr>
				</tbody>
				</table>
				
				</div>
			</React.Fragment>
			
		);
	}
}