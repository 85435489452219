import React from 'react'

import Lottie from 'react-lottie-player'

export const WinningAnimation = props => {

    return (
        <Lottie
            loop={props.loop}
            animationData={props.winningLottieJson}
            play
            style={{ width: props.width, height: props.height }}
        />
    );

}