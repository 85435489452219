/**
 * NAME: global-functions.js
 * DESCRIPTION: A global set of functions that can be used in a general way
 * 				across many parts of the app.  These should be things that are
 * 				universal and not specific to any area of functionality.	.
 * AUTHOR: Chuck Gorder
 * CREATED: [original]
 */

export function decodeMessage( messageURL ) {
	const MOD = 3;
	const html = fetch( messageURL )
		.then(response => response.text())
		.then(htmlStr => {
			const gridElements = getGridElements( htmlStr );
			let grid = createGridArray( gridElements, MOD );
			let rows = buildPrintRows( grid, MOD );
			printRows( rows );
		})
		.catch(error => {
			console.error('ERROR:', error);
		});
}

function getGridElements( htmlStr ) {
	const parser = new DOMParser();
	const theDoc = parser.parseFromString(htmlStr, "text/html");
	const spanList = theDoc.getElementsByTagName('table')[0].getElementsByTagName("span");
	return spanList;
}

function createGridArray( gridElements, mod ) {
	let counter = 1;
	let rowCounter = 1;
	let grid = [];
	let xCoord = -1;
	let yCoord = -1;
	let charVal = " ";
	for (const span of gridElements) {
		if ( (counter % mod) === 0  ) {
			if ( counter > mod ) {
				xCoord = parseInt(gridElements[counter - mod].textContent);
				yCoord = parseInt(gridElements[counter - mod + 2].textContent);
				charVal = gridElements[counter - mod + 1].textContent;
				if ( typeof grid[yCoord] === 'undefined' ) {
					grid[yCoord] = [];
				}
				grid[yCoord][xCoord] = charVal;
			}
			rowCounter++;
		}
		counter++;
	}
	return grid;
}

function buildPrintRows( grid, mod ) {
	let row = "";
	let counter = 0;
	let rows = [];
	for ( let i=(grid.length-1);i >=0 ; i-- ) {
		for (let j = 0; j < grid[i].length; j++) {
			if ( grid[i][j] ) {
				row += grid[i][j];
			} else {
				row += " ";
			}
		}
		rows[counter] = row;
		counter++;
		row = "";
	}
	return rows;
}

function printRows( rows ) {
	for ( let i=rows.length;i > 0; i-- ) {
		console.log( rows[i-1] );
	}
}

import {
	addCSSClass,
	removeCSSClass
} from "./css-functions";

import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import {ALWAYS_ALLOWED_PAGE_LIST} from "__globals/GLOBAL-CONSTANTS.js";

export const DEBUG = window.REACT_DEBUG;



{/** Wrapper around console log to add JSON formatting and a DEBUG switch  **/}
export function logIt( title, body, isJSON ) {
	if ( DEBUG ) {
		console.log( title );
		if ( isJSON ) {
			console.log( JSON.stringify( body, null, 4 ) );
		} else {
			console.log( body );
		}
	}
}

//eslint-disable-next-line
String.prototype.replaceAll = function(search, replacement) {
	if ( replacement === undefined ) {
		replacement = "";
	}
    var target = this;
    return target.split(search).join(replacement);
};

export function isTestMode( PROMO_CONFIG ) {
	if ( !empty(PROMO_CONFIG.testPassword) ) {
		return true;
	} else {
		return false;
	}
}

export function getGameName(configuration) {
	let gameName = "";
	if (configuration.type.value === "iw-wheel") {
		gameName = configuration.config.games.wheel.gameName;
	} else if (configuration.type.value === "iw-match") {
		gameName = configuration.config.games.match.gameName;
	} else if (configuration.type.value === "iw-slot") {
		gameName = configuration.config.games.slot.gameName;
	} else if (configuration.type.value === "iw-match-configurable") {
		gameName = configuration.config.games.match.gameName;
	}
	return gameName;
}

export function showHiderules() {
	let rulesOverlay = document.getElementById('rules_overlay');
	let toggle = false;
	for ( var i=0; i < rulesOverlay.classList.length; i++ ) {
		if ( rulesOverlay.classList[i] === 'showDiv' ) {
			toggle = true;
		}
	}
	if ( toggle ) {
		removeCSSClass('rules_overlay', 'showDiv');
		addCSSClass('rules_overlay', 'hideDiv');
		removeCSSClass('rules_overlay_cover', 'showDiv');
		addCSSClass('rules_overlay_cover', 'hideDiv');
	} else {
		removeCSSClass('rules_overlay', 'hideDiv');
		addCSSClass('rules_overlay', 'showDiv');
		removeCSSClass('rules_overlay_cover', 'hideDiv');
		addCSSClass('rules_overlay_cover', 'showDiv');
	}
	//window.scrollTo(0, 0);
}

export function getRandomNo() {
	var rand = Math.floor((Math.random()*1000000)+1);
	return rand;
}

export function getRandomUrlAppendage( paramName ) {
	var rand = Math.floor((Math.random()*1000000)+1);
	var urlAppendage = "?" + paramName + "=" + rand;
	return urlAppendage;
}

export function retrieveOriginalPath( props ) {
	let oPath = "";
	if ( !empty(props) ) {
		if ( !empty(props.originalPath) && props.originalPath.length > 2) {
			oPath = props.originalPath.substring(2);
		} else {
			if ( !empty(window.location.hash) && window.location.hash.length > 2) {
				oPath = window.location.hash.substring(2);
			}
		}
	}
	return oPath;
}

export function getJsonFromUrl(url) {
	  if(!url) url = window.location.search;
	  //var query = url.substr(1);
	  var result = {};
	  var parts = url.split("?");
	  var query = "";
	  if (parts.length === 2 ) {
		  query = parts[1];
	  }
	  if ( query === "" ) {
		  return result;
	  }
	  query.split("&").forEach(function(part) {
	  var item = part.split("=");
	    result[item[0]] = decodeURIComponent(item[1]);
	  });
	  return result;
}

export function getSlashSource() {
	let url = window.location.pathname;
	let source = url.substring( 1 );
	let parts = url.split("/");
	if (parts.length > 2 ) {
		source = parts[1];
	}
	return source.toUpperCase();
}

export function getSlashSourceFromUrlString( urlStr ) {
	let slashSource = "";
	if ( empty(urlStr) ) {
		return "";
	}
	if ( urlStr.indexOf( "https://") === 0 ) {
		urlStr = urlStr.substring(8);
	} else if ( urlStr.indexOf( "http://") === 0 ) {
		urlStr = urlStr.substring(7);
	} else {
		return slashSource;
	}
	//let source = url.substring( 1 );
	let parts = urlStr.split("/");
	if (parts.length >= 2 ) {
		slashSource = parts[1];
	}
	return slashSource.toUpperCase();
}

Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
}

export function getUTCTimeInMillis() {

    // create Date object for current location
    let d = new Date();
    let dstAdjust = 0;
    if (d.isDstObserved()) { 
        dstAdjust = 60;
    }
   
    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    let utc = d.getTime() + ((d.getTimezoneOffset() + dstAdjust) * 60000);
    return utc;
}

export function inspectFormData( formData ) {
	if ( DEBUG ) {
		console.log("INSPECTING FORM DATA")
		for (var pair of formData.entries()) {
		    console.log(pair[0]+ ', ' + pair[1]); 
		}
	}
}

export function empty( obj ) {
	if ( obj !== undefined && obj !== null && obj !== "" ) {
		return false;
	}
	return true;
}

export function isObjEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function getRandomInt( min, max ) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;	
}

export function retrieveStateList( promotion ) {
	let statesConfig = promotion.configuration.config.states;
	let s = buildState( "AL", "Alabama" );
	return s;
}

function buildState( code, name ) {
	return `<option value='${code}'>${name}</option>`;
}

export function doesVariationExist( variations, variation ) {
	if ( empty(variations) || empty(variation) ) {
		return false;
	}
	if ( !empty( variations[variation] ) ) {
		return true;
	}
	return false;
}

export function isVariationOn( variations, variation ) {
	// TODO: FIX THIS AND MAKE IT GENERIC
	if ( variation === GC.VARIATION_PICK_PRIZE ) {
		if ( variations[variation]["pickYourPrizeOn"] === true ) {
			return true;
		}
	}
	if ( variation === GC.VARIATION_CUSTOM_CONTENT ) {
		if ( variations[variation]["customContentOn"] === true ) {
			return true;
		}
	}
	if ( variation === GC.VARIATION_RAFFLE ) {
		if ( variations[variation]["raffleOn"] === true ) {
			return true;
		}
	}
	if ( variation === GC.VARIATION_FILE_UPLOAD ) {
		if ( variations[variation]["fileUploadOn"] === true ) {
			return true;
		}
	}
	return false;
}

export function isNoReg( promotion ) {
	if ( !empty(promotion.configuration?.config?.fieldsInUse?.noRegistration) &&
		promotion.configuration?.config?.fieldsInUse?.noRegistration === true ) {
		return true;
	}
	return false;
}

export function isPickYourPrize( promotion ) {
	if ( doesVariationExist(promotion.configuration?.config?.variations, GC.VARIATION_PICK_PRIZE) &&
			isVariationOn(promotion.configuration?.config?.variations, GC.VARIATION_PICK_PRIZE) ) {
		return true;
	}
	return false;
}

export function isRafflePromo( promotion ) {
	if ( doesVariationExist(promotion.configuration?.config?.variations, GC.VARIATION_RAFFLE) &&
		isVariationOn(promotion.configuration?.config?.variations, GC.VARIATION_RAFFLE) ) {
		return true;
	}
	return false;
}

export function isFileUpload( promotion ) {
	if ( doesVariationExist(promotion.configuration?.config?.variations, GC.VARIATION_FILE_UPLOAD) &&
		isVariationOn(promotion.configuration?.config?.variations, GC.VARIATION_FILE_UPLOAD) ) {
		return true;
	}
	return false;
}

export function useSplash( promotion ) {
	if ( !empty(promotion.configuration.config?.pages?.useSplash) &&
		(promotion.configuration.config?.pages?.useSplash === true ) ) {
		return true;
	}
	return false;
}


function replaceGameClaimVar( gameClaim, parameter, replaced ) {
	let rc = replaced;
	if (!empty(gameClaim?.parameters)) {
		if (!empty(gameClaim?.parameters[parameter])) {
			rc = replaced.replaceAll(
				"[[${" + parameter + "}]]",
				gameClaim.parameters[parameter]
			);
		}
	}
	return rc;
}

function replaceAllVariables( promotion, variables, content ) {

	if ( empty(variables) ) {
		return content;
	}
	let newContent = content;
	Object.keys(variables).forEach(function(key) {
		if (
			typeof variables[key] === 'object' &&
			!Array.isArray(variables[key]) &&
			variables[key] !== null
		) {
			// object
		} else {
			//newContent = replacePromoVar( promotion, variables[key], newContent );
			newContent = newContent.replaceAll(
				"[[${" + key + "}]]",
				variables[key]
			);
		}
	});

	return newContent;

}


export function replaceVars( content, promotion, entry, gameClaim, variables ) {

	if ( empty(content) ) {
		return "";
	}
	let replacedContent = content;

	if ( empty(promotion) ){
		logIt( "replaceVars", "FATAL ERROR: promotion was undefined at replaceVars", false );
	} else {
		replacedContent = replaceAllVariables( promotion, promotion.variables, replacedContent );
		if ( !empty(entry) ) {
			replacedContent = replaceAllVariables(promotion, entry.variables, replacedContent);
		}
	}

	// TODO: FOLD GAME CLAIM VARIABLES IN???
	if ( gameClaim === undefined || gameClaim === null || gameClaim === "" ) {
		logIt( "replaceVars", "game claim was undefined", false );
	} else {
		replacedContent = replaceGameClaimVar( gameClaim, "prizeWon", replacedContent );
		 //VERIFICATION CODE
		if ( !empty(gameClaim?.verificationCode) ) {
			replacedContent = replacedContent.replaceAll(
				"[[${verificationCode}]]",
				gameClaim.verificationCode
			);
		}
		// BAR CODE URL
		if ( !empty(gameClaim?.couponCode) ) {
			let bcu = "http://promotion-service.readysetpromo.com/generate-barcode/" + gameClaim.couponCode;
			replacedContent = replacedContent.replaceAll(
				"[[${barCodeUrl}]]",
				bcu
			);
		}
		 //COUPON CODE
		if ( !empty(gameClaim?.couponCode) ) {
			replacedContent = replacedContent.replaceAll(
				"[[${couponCode}]]",
				gameClaim.couponCode
			);
		}
		// RESULT NAME
		if ( !empty(gameClaim?.result) ) {
			replacedContent = replacedContent.replaceAll(
				"[[${resultName}]]",
				gameClaim.result
			);
		}
	}

	return replacedContent;
	
}

export function strDoesArrayElementExist( anArray, aString ) {
	for ( var i=0; i < anArray.length; i++ ) {
		if ( anArray[i] === aString ) {
			return true;
		}
	}
	return false;
}

export function isPageAlwaysAllowed( page ) {
	for ( var i=0; i < GC.ALWAYS_ALLOWED_PAGE_LIST.length; i++ ) {
		if ( page.toLowerCase() === GC.ALWAYS_ALLOWED_PAGE_LIST[i] ) {
			return true;
		}
	}
	return false;
}


