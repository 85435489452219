import React, { useState, useEffect } from 'react';
import QRCode from "react-qr-code";

import {empty, getRandomUrlAppendage, logIt} from "__globals/global-functions";
import {createAddEntryForTicketProviderScan} from "../../__globals/promocracy-functions";
import {updateEntry} from "../../_features/entries/entry-slice";

var addOn = getRandomUrlAppendage("rd");

export const QRGenerator = props => {

    const [qrKey, setQrKey] = useState("QRKey");
    const changeQR = (event) => {
        setQrKey(event.target.value);
    };
    const [domain, setDomain] = useState("https://rallytoremember.org/");
    const changeDomain = (event) => {
        setDomain(event.target.value);
    };


    useEffect(() => {
        if ( !empty(qrKey) ) {
            //alert(qrKey)
        }

    }, [qrKey]);

    return (
        <React.Fragment>
            <div style={{ background: 'white', padding: '16px' }}>
                <QRCode value={domain + qrKey} />
            </div>
            <div>&nbsp;</div>
            <div>
                <input name="qr-key-val" type="text" value={qrKey} onChange={changeQR} />
            </div>
            <div>
                <input name="domain-val" type="text" value={domain} onChange={changeDomain} />
            </div>
            <div>
                <input name="submit" type="button" value="Generate"  />
            </div>
        </React.Fragment>
    );

}