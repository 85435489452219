import React, { useEffect, useState } from 'react';

import { 
	empty
} from '__globals/global-functions';

export const PostalCode = props => { 
	
	/* LOCAL VARIABLES */
	const [currentValue, setCurrentValue] = useState('');
	const onChangeCurrentValue = e => { setCurrentValue(e.target.value) }

	const [tabIndex, setTabIndex] = useState(4);
	
	const [placeholder, setPlaceholder] = useState("POSTAL/ZIP CODE");
	
	const [errorMsgRequired, setErrorMsgRequired] = useState("Please include your postal/zip code.");
	
	const [required, setRequired] = useState(true);

	
	/* FUNCTIONS */
	/* Checks the validations for this element */
	const checkValidation = () => {
		if ( required ) {
			if ( empty(currentValue) ) {
				return errorMsgRequired;
			} else {
				return null;
			}
		}
	}
	

	/* HOOKS */
	/* Check validation and send data to the parent whenever the currentValue changes */
	useEffect(() => {
		let valMessage = checkValidation();
		let isValid = true;
		if ( !empty(valMessage) ) {
			isValid = false;
		}
		let vJSON = {};
		vJSON.name = props.name;
		vJSON.val = currentValue;
		vJSON.isValid = isValid;
		vJSON.valMessage = valMessage;
		props.updateFormValues( vJSON );
	}, [currentValue, errorMsgRequired] );
	
	/* Set additional fields from the promotion "fieldsInUse" config */
	useEffect(() => {
		if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
			if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
				setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
				setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].required) ) {
				setRequired( props.fieldsInUse[props.name].required );
			}
			if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
				setTabIndex( props.fieldsInUse[props.name].tabIndex );
			}
		}
	}, [props.fieldsInUse] );
	
	return (
		<div id={"dv_" + props.name} className={"cls-" + props.name}>
			<input 
				name={props.name}
				type="text" 
				tabIndex={tabIndex} 
				placeholder={placeholder}
				value={currentValue} 
				onChange={onChangeCurrentValue} 
			/>
		</div>
	);
		

}

export default PostalCode;

