import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	logIt,
	empty,
	getRandomUrlAppendage
} from '__globals/global-functions';

import {
	getJsonContent, getJsonResultCopy,
	setContent
} from '__globals/copy-functions';

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import { updateEntry, processClearEntryUpdated } from '_features/entries/entry-slice';
//import {goNextPage} from "__globals/page-flow";
import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import PageFlow from "../../__routes/PageFlow";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	dataLayer: {
		userId: '001',
		userProject: 'RSP',
		page: 'PickYourPrize',
		checkLoginIframe: false
	},
	dataLayerName: 'RSP'
}

var addOn = getRandomUrlAppendage("rd");
	
export const PickYourPrize = props => {

	logIt( "PICK YOUR PRIZE PAGE IS CALLED", "", false );

	TagManager.dataLayer(tagManagerArgs);

	const dispatch = useDispatch();

	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at PickYourPrize", promotion, true );
	
	const prizePicks = useSelector(state => state.promotion.configuration.config.variations.pickYourPrize.prizes);
	//logIt( "PRIZE PICKS at PickYourPrize", prizePicks, true );

	const entry = useSelector( state => state.entry );
	//logIt( "UPDATED ENTRY AT PickYourPrize", entry, true );

	let error = useSelector(state => state.entry.error);
	//logIt( "ERROR AT PickYourPrize", error, true );

	let entryUpdated = useSelector(state => state.entry.entryUpdated);
	//logIt( "entryUpdated AT PickYourPrize", error, true );

	// LOCAL VARIABLES
	const [showTitle, setShowTitle] = useState( false);
	const [showCopy, setShowCopy] = useState( false);
	const [showButtons, setShowButtons] = useState( undefined);
	const [prizePickList, setPrizePickList] = useState(undefined);
	const [goNext, setGoNext] = useState(false);
	
	const getPrizePicks = () => {
		let index = -1;
		let items = prizePicks.map((pPick, key) => {
			index++;
			if ( showButtons ) {
				return (
					<div key={pPick.key} onClick={() => handlePrizePick(pPick.key)}>
						<div className="pick-prize-img">
							<img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}all/${promotion.configuration.config.variations.pickYourPrize.prizes[index].imageName}`}/>
						</div>
						<div className="pick-prize-description">
							{promotion.configuration.config.variations.pickYourPrize.prizes[index].prizeDescription}
						</div>
						<div className="pick-prize-btn">
							{promotion.configuration.config.variations.pickYourPrize.prizes[index].buttonCopy}
						</div>
					</div>
				);
			} else {
				return (
					<div key={pPick.key} onClick={() => handlePrizePick(pPick.key)}>
						<div className="pick-prize-img">
							<img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}all/${promotion.configuration.config.variations.pickYourPrize.prizes[index].imageName}`}/>
						</div>
						<div className="pick-prize-description">
							{promotion.configuration.config.variations.pickYourPrize.prizes[index].prizeDescription}
						</div>
					</div>
				);
			}
		});
		return items;
	}
	const handlePrizePick = ( prizeKey ) => {
		let addEntry = {"entry":{}};
		addEntry.entry.prizePick = prizeKey;
		let items = prizePicks.map((pPick, key) => {
			if ( pPick.key == prizeKey) {
				addEntry.entry.prizeDescription = pPick.prizeDescription;
			}
		});
		logIt( "TEST PRIZE PICK ENTRY", addEntry, true );
		dispatch( updateEntry(addEntry) );
	}
	
	useEffect(() => {

		setShowTitle( promotion.configuration?.config?.variations?.pickYourPrize?.showTitle );
		setShowCopy( promotion.configuration?.config?.variations?.pickYourPrize?.showCopy );
		setShowButtons( promotion.configuration?.config?.variations?.pickYourPrize?.showButtons );

	}, [promotion]);

	useEffect(() => {

		if ( showButtons && promotion && prizePicks && (prizePickList === undefined) ) {
			let ppl = getPrizePicks();
			setPrizePickList( ppl );
		}

	}, [showButtons]);

	{/** Put in copy based on show flags */}
	useEffect(() => {
		if ( showTitle && !empty(promotion) ) {
			//alert("about to do title")
			//logIt( "*** ENTRY VARS", entry.variables, true );
			let pypTitle = getJsonContent(promotion, GC.PAGE_PICK_PRIZE, "title", "<h2>DEFAULT TITLE</h2>", entry );
			setContent("pyp_title", pypTitle);
		}
	}, [showTitle]);
	useEffect(() => {
		if ( showCopy && !empty(promotion) ) {
			let pypCopy = getJsonContent(promotion, GC.PAGE_PICK_PRIZE, "copy", "<h2>DEFAULT COPY</h2>", entry );
			setContent("pyp_copy", pypCopy);
		}
	}, [showCopy]);
	{/** END of put in copy based on show flags */}

	// useEffect(() => {
	// 	//alert(JSON.stringify(entry))
	// 	if ( entryUpdated && !empty(entry) ) {
	// 		if ( entry?.variables?.prizePick ) {
	//
	// 		}
	// 	}
	// }, [entryUpdated] );

	useEffect(() => {
		if ( !empty(entry) && !empty(entry?.variables?.prizePick) ) {
			if ( entryUpdated) {
				dispatch( processClearEntryUpdated() );
			} else {
				setGoNext( true );
			}
		}
	}, [entryUpdated] );

	useEffect(() => {
		if ( !empty(error) ) {
			setGoNext( true );
		}
	}, [error] );


	if ( goNext ) {

		return( <PageFlow page={GC.PAGE_PICK_PRIZE} error={error} /> );

	} else {

		return (
			<React.Fragment>
				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>

				<div id="pick_your_prize_page">

					<table>
						<tbody>
						<tr>
							<td className="page-visual">
								<div className="view-desktop"><img
									src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/pick-your-prize-screen.jpg${addOn}`}/>
								</div>
								<div className="view-phone" style={{display: 'none'}}><img
									src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/pick-your-prize-screen.jpg${addOn}`}/>
								</div>
							</td>
							<td className="page-content">

								<div id='pick_your_prize_content'>

									{showTitle && <div id="pyp_title">Title PYP</div>}
									{showCopy && <div id="pyp_copy">Copy PYP</div>}

									<div id="prize-list">
										{prizePickList}
									</div>

								</div>


							</td>
						</tr>
						</tbody>
					</table>

					<CustomContent/>

				</div>

			</React.Fragment>
		);

	}
	
}