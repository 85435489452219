import React, {useEffect, useState} from "react";

import {LottieAnimation} from "./LottieAnimation";
import {empty, logIt} from "__globals/global-functions";
import {addCSSClass, removeCSSClass} from "__globals/css-functions";

export const GameIntroLottieAnimationWrapper = props => {

    logIt( "LOTTIE ANIMATION WRAPPER IS CALLED", null, false );

    const [gameIntroLottieJson, setGameIntroLottieJson] = useState();

    const [introAnimationLottieGameBoardShowTime, setIntroAnimationLottieGameBoardShowTime] = useState(props.promotion.configuration.config?.games?.gameIntroAnimation?.isLottie.gameBoardShowTime || 100);
    const [introAnimationLottieGameMaskFadeTime, setIntroAnimationLottieGameMaskFadeTime] = useState(props.promotion.configuration.config?.games?.gameIntroAnimation?.isLottie.gameMaskFadeTime || 100);
    const [introAnimationLottieAnimationFadeTime, setIntroAnimationLottieAnimationFadeTime] = useState(props.promotion.configuration.config?.games?.gameIntroAnimation?.isLottie.animationFadeTime || 100);
    const [introAnimationLoop, setIntroAnimationLoop] = useState(props.promotion.configuration.config?.games?.introAnimation?.isLottie.loop || false);
    const [introAnimationWidth, setIntroAnimationWidth ] = useState(props.promotion.configuration.config?.games?.introAnimation?.isLottie.width || 100);
    const [introAnimationHeight, setIntroAnimationHeight ] = useState(props.promotion.configuration.config?.games?.introAnimation?.isLottie.height || 100);

    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < 1024
    );

    /** Sets up game intro animation */
    useEffect(() => {
        let retrieveUrl = `https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/json/animation/lottie/desktop/game-animation.json`;
        if ( isMobile ) {
            retrieveUrl = `https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/json/animation/lottie/phone/game-animation.json`;
        }
        if ( !empty(props.promotion) ) {
            (async () => {
                const response = await fetch(
                    retrieveUrl
                );
                const parsed = await response.json();
                setGameIntroLottieJson(parsed);
            })();
            // showTitle and showCopy were here
        }

    }, [props.promotion]);

    useEffect(() => {

        if ( !empty(gameIntroLottieJson) ) {
            if (document.getElementById("game_match_board")) {
                setTimeout(function () {
                    removeCSSClass("game_match_board", "hide");
                    addCSSClass("game_match_board", "show");
                }, introAnimationLottieGameBoardShowTime);
            }
            if (document.getElementById("dv_game_mask")) {
                setTimeout(function () {
                    addCSSClass("dv_game_mask", "fade-out");
                }, introAnimationLottieGameMaskFadeTime);
            }
            if (document.getElementById("dv_intro_lottie_animation")) {
                setTimeout(function () {
                    addCSSClass("dv_intro_lottie_animation", "fade-out");
                }, introAnimationLottieAnimationFadeTime);
            }
        }

    }, [gameIntroLottieJson]);

    return (
        <div id="dv_game_intro_lottie_animation">
            {(props.introAnimationOn && gameIntroLottieJson) ? (
                <div id="dv_intro_lottie_animation">
                    <LottieAnimation
                        loop={introAnimationLoop}
                        lottieJson={gameIntroLottieJson}
                        width={introAnimationWidth}
                        height={introAnimationHeight}
                    />
                </div>
            ) : (
                <div id="dv_no_game_intro_lottie_animation"></div>
            )}
        </div>
    );
}
export default GameIntroLottieAnimationWrapper;