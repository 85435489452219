import React, {useEffect, useState} from 'react';

import QuestionGroup from './QuestionGroup/QuestionGroup';

import { 	
	logIt,
	empty
} from '__globals/global-functions';

var sanitizeHtml = require('sanitize-html');
	
export function TriviaComponent( props ) {



	const handleChoice = (event) => {
		let answerKey = event.currentTarget.getAttribute("data-answer-key");
		let isCorrect = event.currentTarget.getAttribute("data-is-correct");
		props.handleAdvanceQuestion( answerKey, isCorrect );
	}

	const handlePresetChoice = (answerKey, isCorrect) => {
		//alert(answerKey)
		props.handleAdvanceQuestion( answerKey, isCorrect );
	}
	
	const handlePrev = (event) => {
		
	}
	
	const handleNext = (event) => {
		props.handleAdvanceQuestion();
	}
	
	const handleFinish = (event) => {
		props.handleFinish();
	}
	
	useEffect(() => {
		if ( !empty(props.questionGroup.questions[props.questionIndex].button) ) {
			if ( !empty(document.getElementById("next_button")) ) {
				let cleanedNextButton = sanitizeHtml( props.questionGroup.questions[props.questionIndex].button, {
					allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'style' ])
				});
				document.getElementById("next_button").innerHTML = cleanedNextButton;
			}
			if ( !empty(document.getElementById("finish_button")) ) {
				let cleanedFinishButton = sanitizeHtml( props.questionGroup.questions[props.questionIndex].button, {
					allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'style' ])
				});
				document.getElementById("finish_button").innerHTML = cleanedFinishButton;
			}
		}
	}, [props.questionGroup]);

	useEffect( () => {
		if ( !empty(props.questionGroup) && !empty(props.externalAnswer) ) {
			let aIndex = parseInt(props.externalAnswer) - 1;
			if (aIndex > 0) {
				let externalAnswer = props.questionGroup.questions[props.questionIndex].answers[aIndex];
				let qKey = props.questionGroup.questions[props.questionIndex].key;
				let combinedKey = qKey + "-" + externalAnswer.key;
				if ( !empty(externalAnswer) ) {
					handlePresetChoice( combinedKey, externalAnswer.isCorrect );
				}
			}

		}
	}, [props.externalAnswer]);

	if ( !empty(props.triviaJSON) && !empty(props.questionGroup) ) {
	return (
		<div id="trivia_component">
			<QuestionGroup 
				questionGroup={props.questionGroup}
				question={props.questionGroup.questions[props.questionIndex].copy}
				questionKey={props.questionGroup.questions[props.questionIndex].key}
				handleChoice={handleChoice}
				triviaJSON={props.triviaJSON}
				questionIndex={props.questionIndex}
			 	mode={props.mode}
				answer={props.answer}
				answerKey={props.answerKey}
			/>
			<div id="trivia_flow_buttons">
				{ props.showPrev && <div id="prev_button" onClick={handlePrev} style={{display:"none"}}>Prev</div>}
				<div id="between_buttons"></div>
				{ props.showNext && <div id="next_button" onClick={handleNext} className="flow-button">NEXT</div>}
				{ props.showFinish && <div id="finish_button" onClick={handleFinish} className="flow-button">FINISH</div>}
			</div>
		</div>
	);
	} else {
		return (
			<div></div>
		);
	}
	
}

export default TriviaComponent;

