import axios from 'axios';

import { logIt } from '__globals/global-functions';
			
const promotionDomain = window.REACT_APP_PROMOTION_DOMAIN;
const protocol = "https://";


export async function postUserChosenChallenge( jsonData )
{
	const url = protocol + promotionDomain + '/update-account-data';
    const {data} = await axios.post(url, jsonData);
    return data;
}



