

export function addCss (promotion, css) {
    let fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("type", "text/css")
    fileref.setAttribute("href", `https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/css/${css}`);
    document.getElementsByTagName("head")[0].appendChild(fileref);
}

export function addCSSClass(divName, className) {
    var element = document.getElementById(divName);
    if ( element ) {
        element.classList.add(className);
    }
    var elements = document.getElementsByClassName(divName);
    if ( elements ) {
        for ( var i=0; i < elements.length; i++ ) {
            elements[i].classList.add(className);
        }
    }
}

export function removeCSSClass(divName, className) {
    var element = document.getElementById(divName);
    if ( element ) {
        element.classList.remove(className);
    }
    var elements = document.getElementsByClassName(divName);
    if ( elements ) {
        for ( var i=0; i < elements.length; i++ ) {
            elements[i].classList.remove(className);
        }
    }
}