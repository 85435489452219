/**
 * NAME: RedeemPrizeForm
 * DESCRIPTION: Form that pops up for winners to redeem a prize
 * AUTHOR: Chuck Gorder
 * CREATED: 2022-06-17
 *
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	empty,
	logIt
} from '__globals/global-functions';

import {
	addCSSClass,
	removeCSSClass
} from '__globals/css-functions';

import {
	postWinFormEntry,
	processErrMsgDisplay
} from '_features/entries/entry-slice';
import {getJsonContent, setContent} from "../../../../../__globals/copy-functions";
import * as GC from "../../../../../__globals/GLOBAL-CONSTANTS";


export const RedeemPrizeForm = props => {
	
	logIt( "RedeemPrizeForm IS CALLED", "", false );
	
	const dispatch = useDispatch();

	//const entry = useSelector(state => state.entry);
	//logIt( "ENTRY AT RedeemPrizeForm", entry, true );

	let errorMsgDisplay = useSelector(state => state.entry.errorMsgDisplay);
	const setErrorMsgDisplay = (errMsg) => {
		dispatch( processErrMsgDisplay(errMsg) );
	}


	{/** LOCAL VARIABLES */}
    const [firstName, setFirstName] = useState( props.variables?.firstName );
    const [lastName, setLastName] = useState( props.variables?.lastName );
    const [emailAddress, setEmailAddress] = useState( props.variables?.emailAddress );

    const [address1, setAddress1] = useState('');
    const onChangeAddress1 = e => { setAddress1(e.target.value) }
    
    const [address2, setAddress2] = useState('');
    const onChangeAddress2 = e => { setAddress2(e.target.value) }
    
    const [city, setCity] = useState('');
    const onChangeCity = e => { setCity(e.target.value) }
    
    const [stateOrProvince, setStateOrProvince] = useState( props.variables?.state );
    
    const [postalCode, setPostalCode] = useState('');
    const onChangePostalCode = e => { setPostalCode(e.target.value) }

	{/** FUNCTIONS */}
    const prepWinForm = (e) => {
    	e.preventDefault();
    	if ( !validateEntry() ) {
    		return;
    	}
    	let entryObj = {
    		entry: {
    			"firstName": firstName,
    			"lastName": lastName,
    			"emailAddress": emailAddress,
    			"address1": address1,
    			"address2": address2,
    			"city": city,
    			"stateOrProvince": stateOrProvince,
    			"postalCode": postalCode,
    		}
    	}
		if ( props.gameClaim !== undefined && props.gameClaim.parameters.emailSend !== undefined) {
			let emailSend = {};
			emailSend.emailTemplate = props.gameClaim.parameters.emailSend.emailTemplate;
			emailSend.emailSubject = props.gameClaim.parameters.emailSend.emailSubject;
			dispatch( postWinFormEntry( entryObj, emailSend ) );
		} else {
			dispatch( postWinFormEntry( entryObj ) );
		}
    }

	{/**  VALIDATION */}
    const validateEntry = () => {
    	let valMessage = validate( "winform" );
    	if ( valMessage !== "" ) {
    		setErrorMsgDisplay( valMessage );
    		return false;
    	}
    	return true;
    }
    
    const validate = (dialog) => {
    	
    	// address1
    	if ( dialog === "winform" ) {
    		removeCSSClass("address1", "form-error");
    		if ( address1 === '' || address1 === undefined ) {
	    		addCSSClass("address1", "form-error");
	    		return "Please provide your complete mailing address";
	    	}
    	}
    	// city
    	if ( dialog === "winform" ) {
    		removeCSSClass("city", "form-error");
    		if ( city === '' || city === undefined ) {
	    		addCSSClass("city", "form-error");
	    		return "Please provide your city";
	    	}
    	}
    	// postal code
    	if ( dialog === "winform" ) {
    		removeCSSClass("postalCode", "form-error");
    		if ( postalCode === '' || postalCode === undefined || postalCode === false ) {
	    		addCSSClass("postalCode", "form-error");
	    		return "Please provide your zip code";
	    	}
    	}
    	return '';
    }
    
	return (

		<div id="win_redeem_prize_form">
			<div id="redeem_form">
				<h1>Just complete the form below with your address to receive your prize!</h1>
				<form>
					<div className="form-filled">
		               <div id="firstName" className="firstname">
		               		{firstName}
		               </div>
		               <div id="lastName" className="lastname">
		               		{lastName}
		               </div>
		            </div>
		            <div className="emailAddress">
		            	<div id="emailAddress" className="form-filled">
		            		{emailAddress}
		            	</div>
		            </div>
		            <div id="address1" className="address1 form-error">
		            	<input name="address1" placeholder="MAILING ADDRESS" tabIndex="5" type="text"  onChange={onChangeAddress1}/>
		            </div>
		            <div id="address2" className="address2">
		            	<input name="address2" placeholder="MAILING ADDRESS 2" tabIndex="6" type="text"  onChange={onChangeAddress2}/>
		            </div>
		            <div id="city" className="city  form-error">
		            	<input name="city" placeholder="CITY" tabIndex="7" type="text"  onChange={onChangeCity}/>
		            </div>
		            <div className="state_and_zip">
		            	<div className="state">
		                   <div id="state" className="form-filled">
		                   		{stateOrProvince}
						   </div>
		                </div>
		                <div id="postalCode" className="zip form-error">
		                	<input name="postalCode" placeholder="ZIP" tabIndex="9" type="text"  onChange={onChangePostalCode}/>
		                </div>
		            </div>
		            <div className="form-submit" onClick={prepWinForm}>
		            	<input id="button" name="button" className="view-desktop" src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/btn-form-redeem-submit.png`} type="image" />
		            	<input id="button" name="button" className="view-mobile" src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}phone/btn-form-redeem-submit.png`} type="image" />
		        		{ errorMsgDisplay !== undefined ? (
	            			<div className="error">{errorMsgDisplay}</div>
	            		) : (
	            			<div className="error"></div>
	            		)}
		             </div>
		         </form>
			  </div>
		</div>
			
	);
	
}