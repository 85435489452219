import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {empty, logIt} from "__globals/global-functions";

import {TriviaComponent} from './TriviaComponent/TriviaComponent';
import {postTriviaAnswersEntry} from "../../../_features/entries/entry-slice";

export const Trivia = props => {

    logIt( "TRIVIA WRAPPER IS CALLED", null, false );

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at IWGameMatch", promotion, true );

    const [ externalAnswer, _setExternalAnswer ] = useState(-1);
    const setExternalAnswer = (aid) => {
        if ( empty(aid) ) {
            aid = -1;
        } else {
            aid = parseInt( aid );
        }
        _setExternalAnswer( aid );
    }

    const [ externalQuestionID, _setExternalQuestionID ] = useState(-1);
    const setExternalQuestionID = (qid) => {
        if ( empty(qid) ) {
            qid = -1;
        } else {
            qid = parseInt( qid );
        }
        _setExternalQuestionID( qid );
    }

    const [ useNavigation, _setUseNavigation ] = useState(false);
    const setUseNavigation = (useN) => {
        if ( empty(useN) ) {
            useN = false;
        }
        _setUseNavigation( useN );
    }

    const [ triviaOn, setTriviaOn ] = useState(false);

    const [ defaultQGID, setDefaultQGID ] = useState(1);

    const [ triviaJSON, setTriviaJSON ] = useState('');

    const [ questionGroup, setQuestionGroup ] = useState('');

    const [ questionIndex, _setQuestionIndex ] = useState(0);
    const setQuestionIndex = (idx) => {
        if ( empty(idx) ) {
            idx = 0;
        }
        _setQuestionIndex( idx );
    }

    const [ questionCount, setQuestionCount ] = useState(0);

    const [ mode, _setMode ] = useState("question");
    const setMode = (md) => {
        if ( empty(md) ) {
            md = "question"
        }
        _setMode( md );
    }

    const [ showPrev, _setShowPrev ] = useState( false );
    const setShowPrev = (sPrev) => {
        if ( empty(sPrev) ) {
            sPrev = false
        }
        _setShowPrev( sPrev );
    }

    const [ showNext, _setShowNext ] = useState( false );
    const setShowNext = (sNext) => {
        if ( empty(sNext) ) {
            sNext = false
        }
        _setShowNext( sNext );
    }

    const [ showFinish, _setShowFinish ] = useState( false );
    const setShowFinish = (sFinish) => {
        if ( empty(sFinish) ) {
            sFinish = false
        }
        _setShowFinish( sFinish );
    }

    const [ answer, _setAnswer ] = useState( "" );
    const setAnswer = (ans) => {
        if (ans === undefined ) {
            ans = ""
        }
        _setAnswer( ans );
    }

    const [ answerKey, _setAnswerKey ] = useState( "" );
    const setAnswerKey = (ansKey) => {
        if (ansKey === undefined ) {
            ansKey = ""
        }
        _setAnswerKey( ansKey );
    }

    const [ userAnswers, _setUserAnswers ] = useState( [] );
    const setUserAnswers = (uAnswers) => {
        if ( empty(uAnswers) ) {
            uAnswers = [];
        }
        _setUserAnswers( uAnswers );
    }

    /** HELPER FUNCTIONS **/
    const retrieveQuestionGroup = () => {
        let d = new Date();
        let nowUTC = d.getTime();
        let qGroups = triviaJSON.questionGroups;
        logIt( "QUESTION GROUPS in retrieveQuestionGroup", qGroups, true );
        if ( externalQuestionID > 0 ) {
            return qGroups[externalQuestionID-1].questionGroup;
        } else {
            for (var i = 0; i < qGroups.length; i++) {
                if ((qGroups[i].questionGroup.startDatetime < nowUTC) && (qGroups[i].questionGroup.endDatetime > nowUTC)) {
                    return qGroups[i].questionGroup;
                }
            }
        }
        return null;
    }

    const retrieveDefaultQuestionGroup = () => {
        let qGroups = triviaJSON.questionGroups;
        return qGroups[defaultQGID-1].questionGroup;
    }

    const handleAdvanceQuestion = ( answerKey, isCorrect ) => {
        let answer = "";
        if ( !empty(answerKey) ) {
            let uas = userAnswers;
            uas.push( answerKey + "|" + isCorrect );
            setUserAnswers( uas );
            answer = findAnswerForAnswerKey( answerKey );
        }
        setAnswer( answer );
        setAnswerKey( answerKey );
        if ( mode === "question" ) {
            setMode( "answer" );
            if ( useNavigation ) {
                if ((questionIndex + 1) === questionCount) {
                    setShowFinish(true);
                    setShowNext(false);
                } else {
                    setShowFinish(false);
                    setShowNext(true);
                }
            }
        } else {
            setMode( "question" );
            let nextIndex = questionIndex + 1;
            setQuestionIndex( nextIndex );
            if ( useNavigation ) {
                setShowNext(false);
            }
        }
    }

    const handleFinish = () => {
        alert("finish here")
        //dispatch( postTriviaAnswersEntry(userAnswers) );
        // let cachedTriviaState = {};
        // window.localStorage.cachedTriviaState = JSON.stringify( cachedTriviaState );
    }

    const findAnswerForAnswerKey = (answerKey) => {
        let pieces = answerKey.split("-");
        let qKey = pieces[0];
        let aKey = pieces[1];
        let questions = questionGroup.questions;
        let retAnswer = "";
        questions.forEach( function (question, index) {
            if ( question.key === qKey ) {
                question.answers.forEach( function (answer, aIndex) {
                    if ( answer.key === aKey ) {
                        retAnswer = answer.selectionCopy;
                    }
                });
            }
        });
        return retAnswer;
    }

    // const findAnswerForIDs = (questionID, answerID) => {
    //     // let pieces = answerKey.split("-");
    //     // let qKey = pieces[0];
    //     // let aKey = pieces[1];
    //     let questions = questionGroup.questions;
    //     let retAnswer = "";
    //     questions.forEach( function (question, index) {
    //         // alert(index)
    //         // alert(question.index)
    //         // alert(questionID)
    //         if ( question.index === questionID ) {
    //             question.answers.forEach( function (answer, aIndex) {
    //                 //alert(answer.index)
    //                 //alert(answerID)
    //                 if ( answer.index == answerID ) {
    //                     //alert("match")
    //                     retAnswer = answer; //.selectionCopy;
    //                 }
    //             });
    //         }
    //     });
    //     return retAnswer;
    // }
    /** END HELPER FUNCTIONS **/

    /** USE EFFECT HOOKS **/
    useEffect(() => {
        if ( !empty(promotion) ) {
            setTriviaOn( promotion.configuration.config?.variations?.trivia?.triviaOn );
            setDefaultQGID( promotion.configuration.config?.variations?.trivia?.defaultQuestionGroupID );
            setUseNavigation( promotion.configuration.config?.variations?.trivia?.useNavigation );
            setExternalAnswer( promotion.configuration.extans );
            setExternalQuestionID( promotion.configuration.tqid );
        }
    }, [promotion]);

    useEffect( () => {
        if ( triviaOn === true ) {
            let copiedTrivia = JSON.parse(JSON.stringify(promotion.configuration.triviaCopy));
            logIt( "TRIVIA JSON at useEffect", copiedTrivia, true );
            if ( !empty(copiedTrivia) ) {
                setTriviaJSON( copiedTrivia );
            }
        }
    }, [triviaOn]);

    useEffect( () => {
        if ( !empty(triviaJSON) ) {
            let currentQuestionGroup = retrieveQuestionGroup();
            //alert(JSON.stringify(currentQuestionGroup));

            if ( empty(currentQuestionGroup) ) {
                // set a default question
                currentQuestionGroup = retrieveDefaultQuestionGroup();
            }
            let qCount = currentQuestionGroup.questionCount;
            setQuestionCount( qCount );
            setQuestionGroup( currentQuestionGroup );
        }
    }, [triviaJSON]);

    // useEffect( () => {
    //     if ( !empty(questionGroup) && (questionGroup.questions.length > 0) ) {
    //         let answerID = promotion.configuration.extans;
    //         //let questionID = questionGroup.questions[0].index;
    //         if ( !empty(answerID) ) {
    //             setExternalAnswer( answerID );
    //             // let answer = findAnswerForIDs(questionID, answerID);
    //             // //alert(JSON.stringify(answer));
    //             // setAnswer(answer);
    //             // setAnswerKey(answer.key);
    //             // setMode("question");
    //             // setQuestionIndex(questionID);
    //             // handleAdvanceQuestion( answer.key, answer.isCorrect)
    //         }
    //
    //
    //     }
    // }, [questionGroup]);

    useEffect(() => {
        if ( !empty(mode) ) {
            if ( mode === "answer" ) {
                props.setDisplayForm(true);
            }
        }
    }, [mode]);

    useEffect(() => {
        if ( !empty(useNavigation) ) {
            if ( useNavigation === true ) {
                setShowPrev( true );
                setShowNext( true );
                setShowFinish( true );
            } else {
                setShowPrev( false );
                setShowNext( false );
                setShowFinish( false );
            }
        }
    }, [useNavigation]);
    /** END USE EFFECT HOOKS **/

    if ( triviaOn ) {
        return (
            <div id="dv_trivia_wrapper">
                { (!empty(questionGroup)) ? (
                    <TriviaComponent
                        triviaJSON={triviaJSON}
                        questionGroup={questionGroup}
                        questionIndex={questionIndex}
                        mode={mode}
                        handleAdvanceQuestion={handleAdvanceQuestion}
                        handleFinish={handleFinish}
                        showPrev={showPrev}
                        showNext={showNext}
                        showFinish={showFinish}
                        answer={answer}
                        answerKey={answerKey}
                        externalAnswer={externalAnswer}
                        useNavigation={useNavigation}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }

}
export default Trivia;