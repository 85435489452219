import React from 'react';
import Helmet from 'react-helmet';

const TitleComponent = ({ title }) => {
    var defaultTitle = '⚛️ app';
    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
            <link rel="icon" type="image/png" href="" sizes="0x0" />
        </Helmet>
    );
};

export { TitleComponent };