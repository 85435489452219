import React from 'react';

import {Routes, Route} from 'react-router-dom';

// COMMON ROUTES
// import { Maintenance } from '_pages/GameFlowPages/Maintenance/Maintenance';
import PageFlow from '_pages/__routes/PageFlow';

import { OfficialRules } from '_pages/_CorePages/OfficialRules/OfficialRules';
import { Prizes } from '_pages/_CorePages/Prizes/Prizes';
import { ComingSoon } from '_pages/GameFlowPages/ComingSoon/ComingSoon';
import { PromoEnded } from '_pages/GameFlowPages/PromoEnded/PromoEnded';
import { About } from '_pages/OptionalPages/About/About';
import { AlreadyPlayed } from '_pages/GameFlowPages/AlreadyPlayed/AlreadyPlayed';
import { AlreadyEntered } from '_pages/GameFlowPages/AlreadyEntered/AlreadyEntered';
import { AlreadyWon } from '_pages/GameFlowPages/AlreadyWon/AlreadyWon';
import { Splash } from "../OptionalPages/Splash/Splash";
import { Error } from '_pages/GameFlowPages/Error/Error';

import { Enter } from '_pages/_CorePages/Enter/Enter';
import { EnterNoReg } from '_pages/_CorePages/Enter/EnterNoReg';

import { PickYourPrize } from "_pages/Variations/PickYourPrize/PickYourPrize";

import { ThankYou } from '_pages/_CorePages/ThankYou/ThankYou';
import { IWGameMatchConfigurable } from '_pages/InstantWin/IWGameMatchConfigurable/IWGameMatchConfigurable';
import { IWGameMatch } from '_pages/InstantWin/IWGameMatch/IWGameMatch';
import { IWGameWheel } from '_pages/InstantWin/IWGameWheel/IWGameWheel';
import { IWGameSlot } from '_pages/InstantWin/IWGameSlot/IWGameSlot';
import { IWGameNoClickReveal } from '_pages/InstantWin/IWGameNoClickReveal/IWGameNoClickReveal';
import { IWGamePokerDice } from '_pages/InstantWin/IWGamePokerDice/IWGamePokerDice';

import { LocalMap } from '_pages/LocalWinsPages/LocalMap/LocalMap';
import {VerifyEntry} from "../_CorePages/VerifyEntry/VerifyEntry";

import {RaffleScan} from "_pages/Raffle/RaffleScan/RaffleScan";
import {RaffleScanned} from "_pages/Raffle/RaffleScanned/RaffleScanned";
import {AlreadyScanned} from "_pages/Raffle/AlreadyScanned/AlreadyScanned";
import {ClearRaffleTickets} from "_pages/Raffle/ClearRaffleTickets/ClearRaffleTickets";
import {TicketValidator} from "_pages/Raffle/TicketValidator/TicketValidator";
import {GenerateQRCode} from "_pages/Raffle/GenerateQRCode/GenerateQRCode";

import {FileUpload} from "_pages/Variations/FileUpload/FileUpload";

import {Affiliate} from "_pages/Variations/Affiliate/Affiliate";

import {TemplatePage} from "_pages/___TEMPLATE-PAGE/TemplatePage";

// import { FAQ } from '_pages/OptionalPages/FAQ/FAQ';
// import { GNPRedir } from '_pages/GameFlowPages/GNPRedir/GNPRedir';


const BrowserRouterRoutes = ( props ) => {

    return (
        <Routes>

            <Route path="/*"  element={<PageFlow page={"App"} />}/>

            <Route path="/official-rules" element={<OfficialRules />} />
            <Route path="/prizes" element={<Prizes />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/promo-ended" element={<PromoEnded />} />
            <Route path="/about" element={<About />} />
            <Route path="/already-played" element={<AlreadyPlayed />} />
            <Route path="/already-entered" element={<AlreadyEntered />} />
            <Route path="/already-won" element={<AlreadyWon />} />
            <Route path="/splash" element={<Splash />} />
            <Route path="/error" element={<Error />} />
            <Route path="/verify-entry" element={<VerifyEntry />} />


            <Route path="/enter" element={<Enter />} />
            <Route path="/enter-nr" element={<EnterNoReg />} />
            <Route path="/MLB" element={<PageFlow page={"App"} />}/>

            <Route path="/pick-your-prize" element={<PickYourPrize />} />

            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/iw-match-c" element={<IWGameMatchConfigurable />} />
            <Route path="/iw-match" element={<IWGameMatch />} />
            <Route path="/iw-wheel" element={<IWGameWheel />} />
            <Route path="/iw-slot" element={<IWGameSlot />} />
            <Route path="/iw-reveal" element={<IWGameNoClickReveal />} />
            <Route path="/iw-poker-dice" element={<IWGamePokerDice />} />

            <Route path="/local-map" element={<LocalMap />} />

            <Route path="/raffle-scan" element={<RaffleScan />} />
            <Route path="/raffle-scanned" element={<RaffleScanned />} />
            <Route path="/already-scanned" element={<AlreadyScanned />} />
            <Route path="/clear-raffle-tickets" element={<ClearRaffleTickets />} />
            <Route path="/ticket-validator" element={<TicketValidator />} />

            <Route path="/affiliate" element={<Affiliate />} />

            <Route path="/file-upload" element={<FileUpload />} />

            <Route path="/template-page" element={<TemplatePage />} />
            <Route path="/generate-qr-code" element={<GenerateQRCode />} />

        </Routes>

    );

}

export default BrowserRouterRoutes;

