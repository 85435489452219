import { createSlice } from '@reduxjs/toolkit';

import { logIt } from '__globals/global-functions';

import { 	
	getVerifyGWPCode
} from '_api/GWPAPI';

const gwpSlice = createSlice({
	  name: 'gwp',
	  initialState:{},
	  reducers: {
		  processGWPVerify(state, action) {
			  state.validation = action.payload;
			  logIt( "processGWPVerify PAYLOAD", action.payload, true );
		  },
		  processProductConfig(state, action) {
			  logIt( "processProductConfig PAYLOAD", action.payload, true );
			  state.productChoices = action.payload;
			  state.productChoicesMade = true;
		  },
		  processErrMsgDisplay(state, action) {
			  logIt( "processErrMsgDisplay PAYLOAD", action.payload, true );
			  state.errorMsgDisplay = action.payload.errors[0].message;
		  },
		  processClearStatus(state, action) {
			  logIt( "processClearStatus STATE", state, true );
			  state.error = undefined;
			  state.result = undefined;
		  }
	  }
});

export const {
	processGWPVerify,
	processProductConfig,
	processErrMsgDisplay,
	processClearStatus
} = gwpSlice.actions;


export const verifyGWPCode = ( code, token ) => async (dispatch, getState) => {
	const result = await getVerifyGWPCode( code, token );
	logIt( "RESULT in verifyGWPCode", result, true );
    if ( result.status === "error" ) {
    	dispatch(processErrMsgDisplay(result));
    } else {
    	result.code = code;
    	dispatch( processGWPVerify( result ) );
    }
}

export default gwpSlice.reducer;



