import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { Route, Redirect, useParams } from 'react-router-dom';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import { 	
	logIt, 
	replaceVars,
	empty,
	getRandomUrlAppendage,
	getRandomNo
} from '__globals/global-functions';

import { 	
	getGameName
} from '__globals/iw-functions';

import { 
	//postEntry,
	//postSimpleEntry,
	//postInstantWinEntry,
	//postGiftWithPurchaseEntry,
	postNonRegistrationEntry,
	fetchToken 
} from '_features/entries/entry-slice';

import { 	
	goNextPage
} from '__globals/page-flow';
	
import { processJSON } from '_features/promotion/promotion-slice';

var sanitizeHtml = require('sanitize-html');


export const EnterNoReg = props => {
	
	const dispatch = useDispatch();
	
	const promotion = useSelector(state => state.promotion);
	logIt( "PROMOTION at EnterNoReg", promotion, true );
	
	let { uid } = useParams();
	
	const entry = useSelector(state => state.entry);
	logIt( "ENTRY AT EnterNoReg", entry, true );
	
	let gameClaim = entry.gameClaim;
	logIt( "GAME CLAIM AT EnterNoReg", gameClaim, true);
	
	let error = entry.error;
	logIt( "ERROR AT EnterNoReg", error, true);

	useEffect(() => {
		if ( !empty(promotion?.configuration?.config?.fieldsInUse?.noRegistration) ) {
			if ( promotion.configuration.config.fieldsInUse.noRegistration ) {
				let userId = promotion.configuration.urlParams.uid; // uid from url params
				if ( empty(userId) ) {
					goNextPage( promotion, "Error", {"message":null,"errors":[{"field":"","message":"No User ID"}],"status":"error"} );
				}
				let entryObj = {
					entry: {}
				};
				entryObj.entry.userId  = userId;
				let gameName = getGameName( promotion );
				dispatch( postNonRegistrationEntry(entryObj, gameName) );
			}
		}
	}, [promotion] );
	
	useEffect(() => {
		if ( !empty(gameClaim) ) {
			goNextPage( promotion, "EnterNoReg" );
		}
	}, [gameClaim] );
	
	useEffect(() => {
		if ( !empty(error) ) {
			goNextPage( promotion, "Error", error );
		}
	}, [error] );


	return (<div id="dv_loading" className="background"><div className='loading'><div className='loading-copy'>loading:<div className='scrollingwords'><span>promotion</span><span>interface</span><span>prizes</span><span>rules</span><span>probabilities</span><span>winning plays</span></div></div></div></div>);

	// return( <React.Fragment></React.Fragment> );
    
}

export default EnterNoReg;