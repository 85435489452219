import React, {useEffect, useState} from "react";

import {empty, logIt} from "__globals/global-functions";
import {getJsonContent, setContent} from "../../../__globals/copy-functions";
import * as GC from "../../../__globals/GLOBAL-CONSTANTS";
import {useSelector} from "react-redux";





export const StandardContent = props => {

    logIt( "STANDARD CONTENT IS CALLED", "", false );

    const setStandardContent = (promotion, contentKey) => {
        let standardContent = getJsonContent(promotion, GC.CUSTOM_CONTENT, contentKey, "<h2>DEFAULT CONTENT</h2>");
        let divElement = "dv_standard_content_"+props.contentKey
        setContent(divElement, standardContent);
    }

    useEffect(() => {
        if ( !empty(props.promotion) ) {
            setStandardContent( props.promotion, props.contentKey)
        }
    }, [props.promotion]);

    return (
        <div id={"dv_standard_content_"+props.contentKey}>
        </div>
    );
}

export default StandardContent;