import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";

import {
    empty,
    logIt
} from '__globals/global-functions';
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {IFrameInsert} from "../../../_components/IFrameInsert/IFrameInsert";

export const Affiliate = props => {

    logIt(GC.PAGE_AFFILIATE + " PAGE IS CALLED", null, false);
    const PAGE_DIV_NAME = "affiliate"; // use underscores
    const PAGE_KEY = "affiliate"; // use camel case
    const PAGE_IMAGE_KEY = "affiliate"; // use dashes
    const PAGE_JSON_KEY = "Affiliate"; // use init caps

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_AFFILIATE, promotion, true );

    let entry = useSelector(state => state.entry);
    logIt( "ENTRY at Affiliate", entry, true);

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const doAction = (a, v) => {
        if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch(executeAction(a, v));
        }
    }

    if (goNext) {

        return (<PageFlow page={GC.PAGE_AFFILIATE} error={null} action={action} value={value}/>);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                </div>

                                <IFrameInsert
                                    promotion={promotion}
                                    configKey={PAGE_KEY}
                                    entry={entry}
                                />

                                <PromocracyLogo promotion={promotion}/>

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );

    }

}