import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";

import * as GC from "__globals/GLOBAL-CONSTANTS";

import {
	empty,
	getRandomUrlAppendage,
	logIt
} from '__globals/global-functions';

import TagManager from "react-gtm-module";

const tagManagerArgs = {
	dataLayer: {
		userId: '001',
		userProject: 'RSP',
		page: 'OfficialRules',
		checkLoginIframe: false
	},
	dataLayerName: 'RSP'
}

var addOn = getRandomUrlAppendage("rd");


export const OfficialRules = props => {

	logIt(GC.PAGE_OFFICIAL_RULES + " PAGE IS CALLED", null, false);
	const PAGE_DIV_NAME = "official_rules"; // use underscores
	const PAGE_KEY = "officialRules"; // use camel case
	const PAGE_IMAGE_KEY = "official-rules"; // use dashes
	const PAGE_JSON_KEY = GC.PAGE_OFFICIAL_RULES; // use init caps

	const dispatch = useDispatch();

	TagManager.dataLayer(tagManagerArgs);

	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at " + GC.PAGE_OFFICIAL_RULES, promotion, true );

	// LOCAL VARIABLES
	const [action, setAction] = useState('');
	const [value, setValue] = useState('');
	const [goNext, setGoNext] = useState(false);

	const doAction = (a, v) => {
		if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
			setAction(a);
			setValue(v);
			setGoNext(true);
		} else {
			dispatch(executeAction(a, v));
		}
	}

	if (goNext) {

		return (<PageFlow page={GC.PAGE_OFFICIAL_RULES} error={null} action={action} value={value}/>);

	} else {

		return (
			<React.Fragment>
				<TitleComponent title={promotion?.configuration?.promoCopy?.GLOBAL?.title}/>
				<div id={PAGE_DIV_NAME + "_page"}>

					<table>
						<tbody>
						<tr>
							<td className="page-visual">

								<PageBackgroundImages
									promotion={promotion}
									pageKey={PAGE_IMAGE_KEY}
								/>

							</td>
							<td className="page-content">


								<PageCopy
									promotion={promotion}
									configKey={PAGE_KEY}
									jsonKey={PAGE_JSON_KEY}
								/>

								{/** CONTENT WILL BE HERE **/}

								<ActionButtons
									promotion={promotion}
									configKey={PAGE_KEY}
									jsonKey={PAGE_JSON_KEY}
									doAction={doAction}
								/>


							</td>
						</tr>
						</tbody>
					</table>

					<CustomContent/>

				</div>
			</React.Fragment>

		);

	}

}

export default OfficialRules;

