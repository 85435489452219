import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {GameCard} from './GameCard/GameCard'; 

import {
	logIt,
	empty
} from '__globals/global-functions';

import {
	addCSSClass,
	removeCSSClass
} from '__globals/css-functions';

/**
 * NAME: TileBlock
 * DESCRIPTION: A container for GameCards and OfferCards for a configurable
 * 				match game.
 * AUTHOR: Chuck Gorder
 * CREATED: 2022-06-17
 * 
 */
export const TileBlock = props => {
	
	logIt( "TILE BLOCK IS CALLED", "", false );
	
	const dispatch = useDispatch();
	
	// STATE VARIABLES
	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at TileBlock", promotion, true );
	
	
	// LOCAL VARIABLES
	/* This group appends the action log and tracks the sequence */
	const [actionLog, setActionLog] = useState([]);
	const [actionLogSequence, setActionLogSequence] = useState(0);
	const [winSequenceCount, setWinSequenceCount] = useState(0);
	const [cardCount, setCardCount] = useState(0);
	const winTriggered = useRef(false);

	const appendActionLog = ( logEntry ) => {
		let al = actionLog;
		let s = actionLogSequence + 1;
		logEntry.sequence = s;
		al.push( logEntry );
		setActionLog( al );
		setActionLogSequence( s );
	}
	
	//FUNCTIONS
	/* callback to record card flips */
	const cardFlipped = ( clickInfo ) => {
		logIt( "Card Flipped", clickInfo, true );
		appendActionLog( clickInfo );
		logIt( "Action Log", actionLog, true );
		if ( clickInfo.winSequence > 0 ) {
			let newWSC = winSequenceCount + 1;
			setWinSequenceCount( newWSC );
		}
		if ( cardCount === 0 ) {
			props.sendNotifyStartGame();
		}
		let cc = cardCount;
		cc++;
		setCardCount( cc );
	}

	const lightUpWinningTiles = () => {
		for ( let i=0; i < props.totalCards; i++ ) {
			let divName = "card_" + props.cards[i].cardKey;
			if ( props.cards[i].winSequence > 0 ) {
				addCSSClass(divName, "win-flash");
			}
		}
	}

	const flipAllCards = () => {
		for ( let i=0; i < props.totalCards; i++ ) {
			//let divName = "card_" + (i+1);
			let divName = "card_" + props.cards[i].cardKey;
			setTimeout(function() {
				addCSSClass(divName, "is-flipped");
				addCSSClass(divName, "shadow-off");
			}, (i*150) );
		}
	}

	{/** Check the win sequence for an early exit */}
	useEffect(() => {
		// Already won
		if(winTriggered.current) return;
		// Flipped last card already
		if ( cardCount >= promotion.configuration.config.games.match.cardCount ) {
			return;
		}
		// Flip the cards
		if ( winSequenceCount === 3 ) {
			winTriggered.current = true;
			lightUpWinningTiles();
			flipAllCards();
			props.showResults( actionLog );
		}
	}, [winSequenceCount]);

	useEffect(() => {
		props.setFlipCount( cardCount );
		if ( cardCount >= promotion.configuration.config.games.match.cardCount ) {
			props.showResults( actionLog );
		}
	}, [cardCount]);
		
	// RETURN TILE BLOCK
	return (
			
		<div id="card_grid_wrapper" >
				
			
			{(props.totalCards >= 1) && <GameCard
				cardKey = {props.cards[0].cardKey}
				cardBackName = {props.cards[0].cardBackName}
				cardFrontName = {props.cards[0].cardFrontName}
				cardPosition = {props.cards[0].cardPosition}
				winSequence = {props.cards[0].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[0].offerKey}
				promotion = {promotion}
			/>}
	
			{(props.totalCards >= 2) && <GameCard
				cardKey = {props.cards[1].cardKey}
				cardBackName = {props.cards[1].cardBackName}
				cardFrontName = {props.cards[1].cardFrontName}
				cardPosition = {props.cards[1].cardPosition}
				winSequence = {props.cards[1].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[1].offerKey}
				promotion = {promotion}
			/>}

			{(props.totalCards >= 3) && <GameCard
				cardKey = {props.cards[2].cardKey}
				cardBackName = {props.cards[2].cardBackName}
				cardFrontName = {props.cards[2].cardFrontName}
				cardPosition = {props.cards[2].cardPosition}
				winSequence = {props.cards[2].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[2].offerKey}
				promotion = {promotion}
			/>}

			{(props.totalCards >= 4) && <GameCard
				cardKey = {props.cards[3].cardKey}
				cardBackName = {props.cards[3].cardBackName}
				cardFrontName = {props.cards[3].cardFrontName}
				cardPosition = {props.cards[3].cardPosition}
				winSequence = {props.cards[3].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[3].offerKey}
				promotion = {promotion}
			/>}
			
			{(props.totalCards >= 5) && <GameCard
				cardKey = {props.cards[4].cardKey}
				cardBackName = {props.cards[4].cardBackName}
				cardFrontName = {props.cards[4].cardFrontName}
				cardPosition = {props.cards[4].cardPosition}
				winSequence = {props.cards[4].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[4].offerKey}
				promotion = {promotion}
			/>}

			{(props.totalCards >= 6) && <GameCard
				cardKey = {props.cards[5].cardKey}
				cardBackName = {props.cards[5].cardBackName}
				cardFrontName = {props.cards[5].cardFrontName}
				cardPosition = {props.cards[5].cardPosition}
				winSequence = {props.cards[5].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[5].offerKey}
				promotion = {promotion}
			/>}

			{(props.totalCards >= 7) && <GameCard
				cardKey = {props.cards[6].cardKey}
				cardBackName = {props.cards[6].cardBackName}
				cardFrontName = {props.cards[6].cardFrontName}
				cardPosition = {props.cards[6].cardPosition}
				winSequence = {props.cards[6].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[6].offerKey}
				promotion = {promotion}
			/>}

			{(props.totalCards >= 8) && <GameCard
				cardKey = {props.cards[7].cardKey}
				cardBackName = {props.cards[7].cardBackName}
				cardFrontName = {props.cards[7].cardFrontName}
				cardPosition = {props.cards[7].cardPosition}
				winSequence = {props.cards[7].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[7].offerKey}
				promotion = {promotion}
			/>}

			{(props.totalCards >= 9) && <GameCard
				cardKey = {props.cards[8].cardKey}
				cardBackName = {props.cards[8].cardBackName}
				cardFrontName = {props.cards[8].cardFrontName}
				cardPosition = {props.cards[8].cardPosition}
				winSequence = {props.cards[8].winSequence}
				totalCards = {props.totalCards}
				cardFlipped = {cardFlipped}
				offerKey = {props.cards[8].offerKey}
				promotion = {promotion}
			/>}
			
		</div>
		
	);

}

export default TileBlock;