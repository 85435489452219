import React, { useState } from 'react';
import { APIProvider, Map, Marker, AdvancedMarker, ControlPosition } from '@vis.gl/react-google-maps';
import MarkerWithInfoWindow from './MarkerWithInfoWindow';
import CustomMapControl from './CustomMapControl';
import MapHandler from './MapHandler';
import { ClusteredMarkers } from './ClusteredMarkers';
const MapComponent = ({ promocracyMapJSON }) => {

    const [selectedPlace, setSelectedPlace] = useState(null);
    const markersList = promocracyMapJSON?.vegas?.map((item, idx) => <MarkerWithInfoWindow key={item.name + idx} position={{ lat: item.geometry.coordinates[1], lng: item.geometry.coordinates[0] }} name={item.name} />)

    return (
        <APIProvider apiKey="AIzaSyCGcMNyhGuHjD0mYGRFkXuyWuL5yGF8_B4">
            <Map
                mapId="map"
                style={{ width: '100vw', height: '100vh' }}
                defaultCenter={{ lat: 36.107216, lng: -115.175804 }}
                defaultZoom={12}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
            >
                {/* <CustomMapControl
                    controlPosition={ControlPosition.TOP}
                    onPlaceSelect={setSelectedPlace}
                />
                <MapHandler place={selectedPlace} />
                <ClusteredMarkers trees={promocracyMapJSON?.vegas} /> */}
                {markersList}
            </Map>
        </APIProvider>
    );
}

export default MapComponent