import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
	getJsonContent,
	setContent
} from '__globals/copy-functions';

export const Error = props => {
	
	const promotion = useSelector(state => state.promotion);
	
	const lostLinkWebsite = () => {
		window.location = promotion.configuration.promoCopy.GLOBAL.websiteLink;
	}
	
	useEffect(() => {
		
		let errorPageMessage = getJsonContent( promotion, "Global", "errorPageMessage", "<table><tr><td><h1>Oops!</h1><h2>Something seems to have gone wrong.  Please try again later. </h2></td></tr></table>" );
		setContent( "error_page_message", errorPageMessage );

	});
	
	return (
		<div id="error_page">
			<div id="msg_page" className="msg-thankyou">
				<table>
					<tbody>
					<tr>
						<td className="page-content">


<div id="error_page_message">Oops! Looks like there was an error.</div>
		                    
		                    
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
					
    );
    
}

