import React from "react";

// DIE CONFIG
const DICE = {

    "DIE_01_01":"dieFACE_01_LOGO.png",
    "DIE_01_02":"dieFACE_02_7s.png",
    "DIE_01_03":"dieFACE_03_Jd.png",
    "DIE_01_04":"dieFACE_04_5d.png",
    "DIE_01_05":"dieFACE_05_Ac.png",
    "DIE_01_06":"dieFACE_06_9c.png",

    "DIE_02_01":"dieFACE_01_LOGO.png",
    "DIE_02_02":"dieFACE_02_Ah.png",
    "DIE_02_03":"dieFACE_03_4s.png",
    "DIE_02_04":"dieFACE_04_8d.png",
    "DIE_02_05":"dieFACE_05_6c.png",
    "DIE_02_06":"dieFACE_06_7h.png",

    "DIE_03_01":"dieFACE_01_LOGO.png",
    "DIE_03_02":"dieFACE_02_5h.png",
    "DIE_03_03":"dieFACE_03_Qd.png",
    "DIE_03_04":"dieFACE_04_10h.png",
    "DIE_03_05":"dieFACE_05_8c.png",
    "DIE_03_06":"dieFACE_06_Ks.png",

    "DIE_04_01":"dieFACE_01_LOGO.png",
    "DIE_04_02":"dieFACE_02_Qh.png",
    "DIE_04_03":"dieFACE_03_10c.png",
    "DIE_04_04":"dieFACE_04_Kd.png",
    "DIE_04_05":"dieFACE_05_4c.png",
    "DIE_04_06":"dieFACE_06_9s.png",

    "DIE_05_01":"dieFACE_01_LOGO.png",
    "DIE_05_02":"dieFACE_02_As.png",
    "DIE_05_03":"dieFACE_03_9d.png",
    "DIE_05_04":"dieFACE_04_6s.png",
    "DIE_05_05":"dieFACE_05_4d.png",
    "DIE_05_06":"dieFACE_06_Jh.png",

    "DIE_06_01":"dieFACE_01_LOGO.png",
    "DIE_06_02":"dieFACE_02_2c.png",
    "DIE_06_03":"dieFACE_03_3d.png",
    "DIE_06_04":"dieFACE_04_8s.png",
    "DIE_06_05":"dieFACE_05_6h.png",
    "DIE_06_06":"dieFACE_06_Qc.png",

    "DIE_07_01":"dieFACE_01_LOGO.png",
    "DIE_07_02":"dieFACE_02_Qs.png",
    "DIE_07_03":"dieFACE_03_7c.png",
    "DIE_07_04":"dieFACE_04_9h.png",
    "DIE_07_05":"dieFACE_05_Kc.png",
    "DIE_07_06":"dieFACE_06_10d.png",

    "DIE_08_01":"dieFACE_01_LOGO.png",
    "DIE_08_02":"dieFACE_02_Js.png",
    "DIE_08_03":"dieFACE_03_6d.png",
    "DIE_08_04":"dieFACE_04_5c.png",
    "DIE_08_05":"dieFACE_05_Ad.png",
    "DIE_08_06":"dieFACE_06_4h.png",

    "DIE_09_01":"dieFACE_01_LOGO.png",
    "DIE_09_02":"dieFACE_02_Kh.png",
    "DIE_09_03":"dieFACE_03_Jc.png",
    "DIE_09_04":"dieFACE_04_8h.png",
    "DIE_09_05":"dieFACE_05_10s.png",
    "DIE_09_06":"dieFACE_06_7d.png"

}

const DIE_FACE_STYLES = {
    "FACE_1": "die-face-front", // the logo
    "FACE_2": "die-face-back",
    "FACE_3": "die-face-left",
    "FACE_4": "die-face-right",
    "FACE_5": "die-face-bottom",
    "FACE_6": "die-face-top"
}

// RETURN THE DIE
export const Die = props => {
    if ( props.dieType === "spin" ) {
        return (
            <div className="die-wrapper" id={"die_0" + props.dieNo}>
                <div id={"spin_die_0" + props.dieNo} className="die" onClick={() => props.rollDie(props.dieNo, props.dieFaceNo)}>
                    <div className="die-face die-face-front"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_01"]}${props.addOn}`}/>
                    </div>
                    <div className="die-face die-face-back"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_02"]}${props.addOn}`}/>
                    </div>
                    <div className="die-face die-face-right"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_03"]}${props.addOn}`}/>
                    </div>
                    <div className="die-face die-face-left"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_04"]}${props.addOn}`}/>
                    </div>
                    <div className="die-face die-face-top"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_05"]}${props.addOn}`}/>
                    </div>
                    <div className="die-face die-face-bottom"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_06"]}${props.addOn}`}/>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="beat_die-wrapper" id={"beat_die_0" + props.beatDieNo}>
                <div id={"nospin_die_0" + props.beatDieNo} className={"beat-die "+DIE_FACE_STYLES["FACE_"+props.dieFaceNo]}>
                    <div className="beat-die-face die-face-front"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_01"]}${props.addOn}`}/>
                    </div>
                    <div className="beat-die-face die-face-back"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_02"]}${props.addOn}`}/>
                    </div>
                    <div className="beat-die-face die-face-right"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_03"]}${props.addOn}`}/>
                    </div>
                    <div className="beat-die-face die-face-left"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_04"]}${props.addOn}`}/>
                    </div>
                    <div className="beat-die-face die-face-top"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_05"]}${props.addOn}`}/>
                    </div>
                    <div className="beat-die-face die-face-bottom"><img
                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/${DICE["DIE_0" + props.dieNo + "_06"]}${props.addOn}`}/>
                    </div>
                </div>
            </div>
        );
    }
}