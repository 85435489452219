import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
    logIt,
    empty,
    getRandomUrlAppendage
} from '__globals/global-functions';

import { addCss, addCSSClass, removeCSSClass } from '__globals/css-functions';

import {
    getJsonContent,
    setContent
} from '__globals/copy-functions';

import {
    goNextPage
} from '__globals/page-flow';

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

// import {
//     usePrompt
// } from '__globals/usePrompt.tsx';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

import _IWGameResult from "_pages/InstantWin/_Results/_IWGameResult/_IWGameResult";

import {sendAction} from "_features/external-integration/external-integration-slice";
import {PAGE_IW_NO_CLICK_REVEAL} from "__globals/GLOBAL-CONSTANTS.js";
import {
    buildCouponEntryUpdateFromGameClaim,
    buildEmailSendFromGameClaim,
    handleEmailSend
} from "../../../__globals/email-functions";
import {updateEntry} from "../../../_features/entries/entry-slice";
import PageFlow from "../../__routes/PageFlow";

let addOn = getRandomUrlAppendage("rd");

export const IWGameNoClickReveal = props => {

    logIt( "INSTANT WIN GAME NO CLICK REVEAL PAGE IS CALLED", "", false );

    const dispatch = useDispatch();

    const [nextPage, setNextPage] = useState(undefined);

    const state = useSelector( state => state );
    //logIt( "STATE at IWGameNoClickReveal", state, true );

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at IWGameNoClickReveal", promotion, true );

    let entry = useSelector(state => state.entry);
    //logIt( "ENTRY at IWGameNoClickReveal", entry, true);

    let gameClaim = useSelector(state => state.entry.gameClaim);
    //logIt( "GAME CLAIM at IWGameNoClickReveal", gameClaim, true);

    let variables = useSelector(state => state.entry.variables);
    //logIt( "VARIABLES at IWGameNoClickReveal", gameClaim, true);

    let error = useSelector(state => state.entry.error);
    //logIt( "ERROR AT IWGameNoClickReveal", error, true );

    let entryUpdated = useSelector(state => state.entry.entryUpdated);

    let winFormUpdated = useSelector(state => state.entry.winFormUpdated);
    //logIt( "winFormUpdated AT IWGameNoClickReveal", error, true );

    /** LOCAL VARIABLES */
    const [allowUsePrompt, setAllowUsePrompt] = useState( false);

    const [showTitle, setShowTitle] = useState( false);
    const [showCopy, setShowCopy] = useState( true);

    const [resultImage, setResultImage] = useState( '' );
    const [resultCSS, setResultCSS] = useState( '' );

    //const [wheelSpun, setWheelSpun] = useState( false );
    const [goNext, setGoNext] = useState(false);
    const [doGoNext, setDoGoNext] = useState( false );

    const [animationGo, setAnimationGo] = useState( false );

    //const prompt = usePrompt(  (allowUsePrompt && !wheelSpun), "If you leave this page, you will invalidate your game play.  Choose 'Cancel' to return and finish the game. Click 'OK' to continue leaving.");

    /** HELPER FUNCTIONS **/
    // const spinWheel = () => {
    //     addCSSClass( "game_wheel_wheel", "spin-wheel" );
    //     addCSSClass( "game_wheel_pointer", "animate-pointer" );
    //     addCSSClass( "spin_button", "btn-spin-out" );
    //     addCSSClass( "wheel_game_content", "div-move-behind" );
    //     if ( gameClaim?.parameters?.result === GC.RESULT_STANDARD ) {
    //         setWheelSpun(true);
    //     }
    //     showResults();
    // }

    const showResults = () => {
        setTimeout(function() {
            addCSSClass( "game_results_screen", "result-screen-fade-in" );
            addCSSClass( "game_results_options", "result-options-fade-in" );
            addCSSClass( "game_no_click_reveal", "result-game-done" );
            //setWheelSpun( false );
            setAnimationGo( true );
            //if ( !entryUpdated ) {
                let addEntry = buildCouponEntryUpdateFromGameClaim(gameClaim);
                let emailSend = buildEmailSendFromGameClaim(gameClaim);
                dispatch( updateEntry( addEntry, emailSend ) );
           // }
        }, promotion.configuration.config.games.reveal.resultDelay);
    }

    const showForm = () => {
        addCSSClass( "redeem_form", "result-redeem-fade-in" );
        addCSSClass( "redeem_button", "btn-redeem-out" );
        addCSSClass( "form_win_screen_show_results_button", "btn-redeem-out" );
    }

    const handleAction = ( action, value ) => {
        // if ( action === "THANKYOU" ) {
        //     setWheelSpun( true );
        // }
        dispatch( sendAction(promotion, action, value) );
    }
    /** END HELPER FUNCTIONS **/

    /** USE EFFECT HOOKS **/
    useEffect(() => {
        if ( !empty(promotion) ) {

            //let btnSpinWheel = getJsonContent( promotion, GC.PAGE_IW_WHEEL, "spinButton", "<h2>SPIN WHEEL</h2>");
            //setContent("spin_button", btnSpinWheel);

            setAllowUsePrompt( promotion.configuration?.config?.games?.allowUsePrompt );
            setShowTitle( promotion.configuration?.config?.pages?.gamePage?.showTitle );
            setShowCopy( promotion.configuration?.config?.pages?.gamePage?.showCopy );

        }
    }, [promotion]);

    useEffect(() => {
        if ( !empty(gameClaim) ) {
            setResultImage( gameClaim.parameters.resultImage );
            setResultCSS( gameClaim.parameters.resultCss );
        }
    }, [gameClaim]);

    useEffect(() => {
        if ( (promotion?.retrieved) && !empty(resultCSS) ) {
            addCss( promotion, resultCSS );
            showResults(); //immediately go to result
        }
    }, [resultCSS]);

    useEffect(() => {
        if ( !empty(error) ) {
            setGoNext( true );
        }
    }, [error] );

    // useEffect(() => {
    // 	if ( !empty(entryUpdated) && (entryUpdated === true) ) {
    // 		goNextPage( promotion, GC.PAGE_IW_WHEEL, error );
    // 	}
    // }, [entryUpdated] );

    {/** Put in copy based on show flags */}
    useEffect(() => {
        if ( showTitle && !empty(promotion) ) {
            let titleCopy = getJsonContent(promotion, GC.PAGE_IW_NO_CLICK_REVEAL, "title", "<h2>DEFAULT TITLE</h2>", entry);
            setContent("title_copy", titleCopy);
        }
    }, [showTitle]);

    useEffect(() => {
        if ( showCopy && !empty(promotion) ) {
            let copyCopy = getJsonContent(promotion, GC.PAGE_IW_NO_CLICK_REVEAL, "copy", "<h2>DEFAULT COPY</h2>", entry);
            setContent("copy_copy", copyCopy);
        }
    }, [showCopy]);

    useEffect(() => {
        if ( !empty(winFormUpdated) && (winFormUpdated === true) ) {
            setGoNext( true );
        }
    }, [winFormUpdated] );

    useEffect(() => {
        if ( !empty(doGoNext) && (doGoNext === true) ) {
            setGoNext( true );
        }
    }, [doGoNext] );

    useEffect(() => {
        if ( !empty(winFormUpdated) && (winFormUpdated === true) ) {
            //setWheelSpun( true );
            setDoGoNext( true );
        }
    }, [winFormUpdated] );

    /** END USE EFFECT HOOKS **/

    if ( goNext ) {

        return( <PageFlow page={GC.PAGE_IW_NO_CLICK_REVEAL} error={error} /> );

    } else {

        return (

            <React.Fragment>

                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>

                <div id="game_no_click_reveal">
                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">
                                <div className="view-desktop">
                                    <img
                                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/game-screen-play.jpg${addOn}`}/>
                                </div>
                                <div className="view-phone" style={{display: 'none'}}>
                                    <img
                                        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/game-screen-play.jpg${addOn}`}/>
                                </div>
                            </td>
                            <td className="page-content">

                                <div id='no_click_reveal_game_content'>
                                    {showTitle && <div id="title_copy">Title One</div>}
                                    {showCopy && <div id="copy_copy">Copy One</div>}
                                </div>


                                {/** Component holding all the game results **/}
                                <_IWGameResult promotion={promotion} gameClaim={gameClaim} entry={entry}
                                               handleAction={handleAction} showForm={showForm}
                                               animationGo={animationGo}/>

                                <div id="game_results_screen">
                                    <div className="view-desktop">
                                        <img
                                            src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
                                    </div>
                                    <div className="view-phone" style={{display: 'none'}}>
                                        <img
                                            src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/${resultImage}`}/>
                                    </div>
                                </div>


                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );
    }

}



