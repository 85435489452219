import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { 
	empty
} from '__globals/global-functions';

export const EmailAddress = props => { 
	
	/* LOCAL VARIABLES */
	const [currentValue, setCurrentValue] = useState('');
	const onChangeCurrentValue = e => { setCurrentValue(e.target.value) }

	const [tabIndex, setTabIndex] = useState(2);
	
	const [placeholder, setPlaceholder] = useState("EMAIL ADDRESS");
	
	const [errorMsgRequired, setErrorMsgRequired] = useState("Please include your email address.");
	
	const [errorMsgFormat, setErrorMsgFormat] = useState("Your email address does not appear to be valid.");
	
	const [required, setRequired] = useState(true);

	
	/* FUNCTIONS */
	/* Checks the validations for this element */
	const checkValidation = () => {
		if ( required ) {
			if ( empty(currentValue) ) {
				return errorMsgRequired;
			}
		}
		try {
			var mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
			let regex = new RegExp(mailformat, 'i');
			if( regex.test( currentValue ) == false ){
				return errorMsgFormat;
			}
		} catch(err) {
			//"regex error"
		}
		return null;
	}
	

	/* HOOKS */
	/* Check validation and send data to the parent whenever the currentValue changes */
	useEffect(() => {
		let valMessage = checkValidation();
		let isValid = true;
		if ( !empty(valMessage) ) {
			isValid = false;
		}
		let vJSON = {};
		vJSON.name = props.name;
		vJSON.val = currentValue;
		vJSON.isValid = isValid;
		vJSON.valMessage = valMessage;
		props.updateFormValues( vJSON );
	}, [currentValue, errorMsgRequired] );
	
	/* Set additional fields from the promotion "fieldsInUse" config */
	useEffect(() => {
		if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
			if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
				setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
				setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].required) ) {
				setRequired( props.fieldsInUse[props.name].required );
			}
			if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
				setTabIndex( props.fieldsInUse[props.name].tabIndex );
			}
		}
	}, [props.fieldsInUse] );
	
	return (
		<div id={"dv_" + props.name} className={"cls-" + props.name}>
			<input 
				name={props.name}
				type="text" 
				tabIndex={tabIndex} 
				placeholder={placeholder}
				value={currentValue} 
				onChange={onChangeCurrentValue} 
			/>
		</div>
	);
		

}

export default EmailAddress;

