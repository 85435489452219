import React from 'react';
import PropTypes from 'prop-types';

import { 
	logIt, 
	empty
} from '__globals/global-functions';

import {
	addCSSClass
} from '__globals/css-functions';

/*
 * NAME: GameCard
 * DESCRIPTION: A card the flips to reveal the underlying value
 * 				in an instant win match game.
 * AUTHOR: Chuck Gorder
 * CREATED: 2022-06-16
 * 
 */
export const GameCard = props => {
	
	// FUNCTIONS
	/* Flips the card when the card is clicked */
    const flipCard = (key, position) => {
    	let divName = "card_" + key;
    	addCSSClass(divName, "is-flipped");
    	addCSSClass(divName, "shadow-off");
    	let cardInfo = {};
    	cardInfo.key = key;
    	cardInfo.position = position;
    	cardInfo.totalCards = props.totalCards;
    	cardInfo.cardBackName = props.cardBackName;
    	cardInfo.cardFrontName = props.cardFrontName;
    	cardInfo.winSequence = props.winSequence
    	props.cardFlipped( cardInfo );
    }
    
    /* PropTypes - sanity check the passed in props */
	GameCard.propTypes = {
		cardKey: PropTypes.string.isRequired,
		cardBackName: PropTypes.string.isRequired,
		cardFrontName: PropTypes.string.isRequired,
		cardPosition: PropTypes.number.isRequired,
		totalCards: PropTypes.number.isRequired,
		cardFlipped: PropTypes.func.isRequired
	}
	

    
	// RETURN THE GAME CARD
	return (
			
		<div className="card-wrapper">
			<div id={"card_" + props.cardKey} className={"card card-" + props.cardPosition} >
				<div className="card-face card-back" onClick={() => flipCard( props.cardKey, props.cardPosition )} >
					<div><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/cards/game-match-card-back-${props.cardBackName}`} /></div>
				</div>
				<div className='card-face card-front'>
					<div><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/cards/game-match-card-${props.cardFrontName}`} /></div>
				</div>
			</div>
			{/* <div className="offer-wrapper hide">
				<div><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/${props.promotion.configuration.languagePath}desktop/offers/game-match-offer-${props.offerKey}.jpg`} /></div>
			</div> */}
		</div>
		
	);
	
}

export default GameCard;