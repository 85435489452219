import { useDispatch } from 'react-redux';
import {empty} from "./global-functions";
import {checkDateVariations} from "./date-functions";

export function buildEmailSendFromGameClaim( gameClaim ) {

    let emailSend = {};
//alert(JSON.stringify(gameClaim))
    if ( !empty(gameClaim) ) {
        emailSend.emailTemplate = gameClaim.parameters?.emailSend?.emailTemplate;
        emailSend.emailSubject = gameClaim.parameters?.emailSend?.emailSubject;
    }

    return emailSend;

}

export function buildCouponEntryUpdateFromGameClaim( gameClaim ) {

    let addEntryObj = {};

    let cStart = "";
    if ( !empty(gameClaim.parameters["couponStartDate"]) ) {
        cStart = gameClaim.parameters["couponStartDate"];
    }
    let fDate = checkDateVariations(cStart);
    if (checkDateVariations !== false) {
        cStart = fDate;
    }
    let cEnd = "";
    if ( !empty(gameClaim.parameters["couponEndDate"]) ) {
        cEnd = gameClaim.parameters["couponEndDate"];
    }
    let pWon = gameClaim.parameters["prizeWon"];
    addEntryObj.entry = {
        "couponStartDate": cStart,
        "couponEndDate": cEnd,
        "prizeWon": pWon
    };

    return addEntryObj;

}