export function buildError( message, field ) {
    let newError = createBlankError();
    newError = addError( newError, message, field );
    return newError;
}
function createBlankError() {
    let blankError = {};
    blankError.message = null;
    blankError.errors = [];
    blankError.status = "error";
}
function addError( error, message, field ) {
    let addedError = error;
    let err = {};
    err.field = field;
    err.message = message;
    addedError.errors.push( err );
    return addedError;
}