import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";

import {
    empty,
    logIt
} from '__globals/global-functions';
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {
    clearRaffleTicketStorage,
    createAddEntryForTicketProviderScan,
    retrieveRaffleTicketTotal
} from "../../../__globals/promocracy-functions";
import {updateEntry} from "../../../_features/entries/entry-slice";

export const TicketValidator = props => {

    logIt(GC.PAGE_TICKET_VALIDATOR + " PAGE IS CALLED", null, false);
    const PAGE_DIV_NAME = "ticket_validator"; // use underscores
    const PAGE_KEY = "ticketValidator"; // use camel case
    const PAGE_IMAGE_KEY = "ticket-validator"; // use dashes
    const PAGE_JSON_KEY = "TicketValidator"; // use init caps

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_TICKET_VALIDATOR, promotion, true );

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const [totalTickets, setTotalTickets] = useState( 0 );
    const [ticketsValidatedAndCleared, setTicketsValidatedAndCleared] = useState( false );

    const handleTicketClearAction = () => {
        clearRaffleTicketStorage();
        setTicketsValidatedAndCleared( true );
    }

    const doAction = (a, v) => {
        if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch(executeAction(a, v));
        }
    }

    useEffect(() => {
        if ( !empty(promotion) ) {
            let tt = retrieveRaffleTicketTotal();
            setTotalTickets( tt );
        }

    }, [promotion]);

    if (goNext) {

        return (<PageFlow page={GC.PAGE_TICKET_VALIDATOR} error={null} action={action} value={value}/>);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />

                                    { !ticketsValidatedAndCleared ? (
                                        <div id="dv_total_tickets">
                                            <div id="dv_total_tickets">TOTAL TICKETS: <span>{totalTickets}</span></div>
                                            <div id="dv_validate_button" onClick={handleTicketClearAction}>Validate Tickets</div>
                                        </div>
                                    ):(
                                        <div id="dv_tickets_cleared" >Your tickets have been validated and cleared.<span>Thank you for participating!</span></div>
                                    )}

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                </div>

                                <PromocracyLogo promotion={promotion}/>

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );

    }

}