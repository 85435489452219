import Carousel from 'react-bootstrap/Carousel';

import {logIt} from "../../../__globals/global-functions";
import React from "react";
import {useSelector} from "react-redux";
import {getJsonContent, getJsonSlideContent, setContent} from "__globals/copy-functions";
import CarouselSlide from "./CarouselSlide";
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {CUSTOM_CAROUSEL} from "__globals/GLOBAL-CONSTANTS";
//import ExampleCarouselImage from 'components/ExampleCarouselImage';
//import CustomContent from "./CustomContent";

export const CustomCarousel = props => {

    logIt( "CUSTOM CAROUSEL IS CALLED", "", false );

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at PickYourPrize", promotion, true );

    //const slides = useSelector(state => state.promotion.configuration.config.variations.customContent.customCarousel.slides);
    //logIt( "slides at CustomCarousel", slides, true );

    const getCarouselItems = () => {
        return (
            <Carousel>
                {getSlides()}
            </Carousel>
        );
    }

    const getSlides = () => {
        // let titleCopy = getJsonContent(promotion, GC.PAGE_IW_MATCH, "title", "<h2>DEFAULT TITLE</h2>", entry);
        // setContent("title_copy", titleCopy);
        let index = -1;
        //alert(slides);
        let items = props.slides.map((slide, key) => {
            index++;
            return (
                <Carousel.Item key={slide.key}>
                    <CarouselSlide promotion={promotion} contentKey={props.contentKey} slideKey={slide.key} />
                </Carousel.Item>
            )
        });
        return items;
    }

    const getSlideContent = (slideKey) => {
        let slideContent = getJsonSlideContent(promotion, GC.CUSTOM_CONTENT, props.contentKey, slideKey,"<h2>DEFAULT SLIDE</h2>");
        return slideContent;
    }

    return (
        <div id={"dv_carousel_"+props.contentKey}>
            {getCarouselItems()}
        </div>

    );
}

export default CustomCarousel;

