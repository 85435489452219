import React from 'react'

import Lottie from 'react-lottie-player'

export const LottieAnimation = props => {

    return (
        <Lottie
            loop={props.loop}
            animationData={props.lottieJson}
            play
            style={{ width: props.width, height: props.height }}
        />
    );

}