import React, {useEffect, useState} from 'react';

import {
    empty,
    getRandomUrlAppendage,
    logIt,
} from "__globals/global-functions";

var addOn = getRandomUrlAppendage("rd");

export const IFrameInsert = props => {

    logIt( "IFrameInsert component is called", null, false );

    const [currentPage, setCurrentPage] = useState(undefined);

    const [iframeOn, setIframeOn] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('www.readysetpromo.com');
    const [iframeName, setIframeName] = useState('RSP_iFrame');
    const [iframeTitle, setIframeTitle] = useState('This is an RSP iFrame Component');

    /** SET THE CURRENT PAGE **/
    useEffect(() => {
        if (!empty(props.promotion)) {
            if ( !empty(props.promotion.configuration?.config?.pages[props.configKey]) ) {
                setCurrentPage( props.promotion.configuration?.config?.pages[props.configKey] );
            }
        }
    }, [props.promotion]);

    /** SET THE IFRAME VARIABLES  **/
    useEffect(() => {
        if (!empty(currentPage)) {
            if ( !empty(currentPage.iframe) ) {
                setIframeOn(currentPage.iframe.iframeOn);
                setIframeName(currentPage.iframe.iframeName);
                setIframeTitle(currentPage.iframe.iframeTitle);
                if (!empty(props.entry)) {
                    // TODO: This seems very odd that there are three levels here??
                    //alert(JSON.stringify(props.entry.entry.entry.emailAddress));
                    // TODO: get parameters dynamically from the config
                    let finalFrameURL = currentPage.iframe.iframeUrl + "?userId=" + props.entry.entry.authToken;
                    //alert( finalFrameURL );
                    setIframeUrl( finalFrameURL );
                } else {
                    setIframeUrl(currentPage.iframe.iframeUrl);
                }
            }
        }
    }, [currentPage, props.entry]);


    if (iframeOn) {
        return (
            <React.Fragment>
                <div id="dv_iframe_insert">
                    <iframe src={iframeUrl} name={iframeName} title={iframeTitle}></iframe>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment></React.Fragment>
        )
    }

}