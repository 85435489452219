import axios from 'axios';

import {empty, getGameName, logIt} from '__globals/global-functions';
import {setFormattedTime} from '__globals/date-functions';
import {commitGameClaim} from "./PromotionAPI";

const promotionDomain = window.REACT_APP_PROMOTION_DOMAIN;
const gameHostName = window.REACT_APP_GAME_DOMAIN;
const protocol = "https://";

/**
 * DOLLAR GENERAL APP - Local web bridge calls
 */
export async function dgGameStart( token, gameStart ) {
    //alert("gs called")
    let message = { "action": "Game Start", "screen":"gamePlayMatch" }
    message.startTime = gameStart;
    //message.token = token;
    logIt( "message at dgGameStart", message, true );
    await postDGMessage( message )
}

export async function dgGameEnd( gameClaim, actionLog, token, gameName, useCommit, gameStartTime ) {
    //alert("game end called: " + useCommit)
    let message = { "action": "Game End", "screen":"gamePlayMatch" }
    if ( gameClaim.losing ) {
        message.result = "Lose";
    } else {
        message.result = "Win";
        message.prize = gameClaim.couponCode;
    }
    message.impressions = setImpressions( actionLog );
    message.clicks = setClicks( actionLog );
    message.startTime = gameStartTime;
    message.endTime = setFormattedTime();
    logIt( "message at dgGameEnd", message, true );
    await postDGMessage( message );
    if ( useCommit ) {
        await commitGameClaim(gameName, token);
    }
}

export async function dgNotifyAction( action, value ) {
    let message = {};
    message.action = action;
    message.value = value;
    logIt( "message at dgNotifyAction", message, true );
    await postDGMessage( message );
}


async function postDGMessage( message ) {
    let strMsg = "ERR - NO MESSAGE";
    //alert(JSON.stringify(message));
    if ( !empty(message) ) {
        strMsg = JSON.stringify(message);
    }
    if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.gameHandler != undefined) {
            window.webkit.messageHandlers.gameHandler.postMessage( strMsg );
        }
    }
    if (window.gameHandler != undefined) {
        window.gameHandler.postMessage( strMsg );
    }
}


/**
 * DOLLAR GENERAL - Server calls
 */
export async function dgGameStartServer( promotion, token ) {
    let message = {};
    message.Token = token;
    message.GameUserId = promotion.configuration.uid;
    message.GameId = setGameId( promotion );
    message.StartTime = setFormattedTime();
    logIt( "message at dgGameStartServer", message, true );
    return postDGGameStartServer( message );
}

export async function postDGGameStartServer( jsonData )
{
    const url = protocol + gameHostName + '/post-dg-game-start';
    const {data} = await axios.post(url, jsonData);
    return data;
}

export async function dgGameEndServer( promotion, gameClaim, token, gameStartTime ) {
    let message = {};
    message.Token = token;
    message.GameUserId = promotion.configuration.uid;
    message.GameId = setGameId( promotion );
    if ( gameClaim.losing ) {
        message.GameResult = "LOSE"; // + gameClaim.result;
        //message.XCode = ""; // Hard-coded Test Code
    } else {
        message.GameResult = "WON"; // + gameClaim.result ;
        message.XCode = gameClaim.couponCode; // Hard-coded Test Code
    }
    message.StartTime = gameStartTime;
    message.EndTime = setFormattedTime();
    logIt( "message at dgGameEndServer", message, true );
    return postDGGameEndServer( message );
}

export async function postDGGameEndServer( jsonData )
{
    const url = protocol + gameHostName + '/post-dg-game-end';
    const {data} = await axios.post(url, jsonData);
    return data;
}

function setGameId( promotion ) {
    let parts = promotion.configuration.name.split( "_" );
    let gameId = parts[0] + "-" + parts[1] + "-" + parts[2];
    if ( parts.length >= 5) {
        gameId = gameId + "_" + parts[3] + parts[4] + "_" + promotion.configuration.config.games.match.gameName;
    } else if ( parts.length === 4 ) {
        gameId = gameId + "_" + parts[3] + "_" + promotion.configuration.config.games.match.gameName;
    } else {
        gameId = gameId + "_PARSING-ERROR_" + promotion.configuration.config.games.match.gameName;
    }
    return gameId;
}

function setImpressions( actionLog ) {
    let impressions = "";
    for ( let i = 0; i < actionLog.length; i++ ) {
        impressions += actionLog[i].cardFrontName; // TODO: trim off the extension?
        if ( i < (actionLog.length - 1) ) {
            impressions += ",";
        }
    }
    return impressions;
}

function setClicks( actionLog ) {
    let clicks = "";
    for ( let i = 0; i < actionLog.length; i++ ) {
        clicks += actionLog[i].position;
        if ( i < (actionLog.length - 1) ) {
            clicks += ",";
        }
    }
    return clicks;
}