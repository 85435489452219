import { createSlice } from '@reduxjs/toolkit';

import { logIt } from '__globals/global-functions';

import {
	postUserChosenChallenge
} from '_api/ChallengePromoAPI';

import {
	postRetrieveAccountData
} from '_api/AuthenticationAPI';



const challengePromoSlice = createSlice({
	name: 'challengepromo',
	initialState:{},
	reducers: {
		processErrMsgDisplay(state, action) {
			logIt( "processErrMsgDisplay PAYLOAD", action.payload, false );
			state.errorMsgDisplay = action.payload;
		},
		processClearStatus(state, action) {
			logIt( "processClearStatus STATE", state, true );
			state.error = undefined;
			state.result = undefined;
		}
	  }
});


export const {
	processErrMsgDisplay,
	processClearStatus
} = challengePromoSlice.actions;



export default challengePromoSlice.reducer;



