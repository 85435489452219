import React, {useEffect, useState} from 'react';

var sanitizeHtml = require('sanitize-html');

export function Question( props ) {
	
	useEffect(() => {
		let cleanedQuestion = sanitizeHtml( props.question, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'style' ])
		});
		document.getElementsByClassName(props.questionKey)[0].innerHTML = cleanedQuestion;
	});
	
	return (
		<div id="div-question" className={props.questionKey}>
			
		</div>
	);
	
}

export default Question;




