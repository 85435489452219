import React, {useEffect, useState} from "react";

import {empty, logIt} from "__globals/global-functions";
import {getJsonContent, getJsonSlideContent, setContent} from "../../../__globals/copy-functions";
import * as GC from "__globals/GLOBAL-CONSTANTS";



export const CarouselSlide = props => {

    logIt( "STANDARD CONTENT IS CALLED", "", false );

    const setSlideContent = (promotion, contentKey, slideKey) => {
        let slideContent = getJsonSlideContent(promotion, GC.CUSTOM_CONTENT, contentKey, slideKey,"<h2>DEFAULT SLIDE</h2>");
        let divElement = "dv_slide_" + slideKey;
        setContent(divElement, slideContent);
    }

    useEffect(() => {
        if ( !empty(props.promotion) ) {
            setSlideContent( props.promotion, props.contentKey, props.slideKey)
        }
    }, [props.promotion]);

    return (
        <div id={"dv_slide_"+props.slideKey}>
        </div>
    );
}

export default CarouselSlide;