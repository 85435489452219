import React, {useEffect, useState, useRef} from 'react';

import {
    empty,
    logIt
} from "__globals/global-functions";

import {
    getJsonContent,
    setContent,
    getJsonResultCopy
} from "__globals/copy-functions";

import { WinningAnimationWrapper } from '_pages/Variations/Animation/WinningAnimation/WinningAnimationWrapper';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import {addCSSClass} from "../../../../__globals/css-functions";

export const WinAppIntegration = props => {

    logIt( "WIN APP INTEGRATION PAGE IS CALLED", null, false );

    // LOCAL VARIABLES
    const [showTitle, setShowTitle] = useState( false);
    const [showResultCopy, setShowResultCopy] = useState( true);
    const [showCopy, setShowCopy] = useState( false);
    const [showActionOne, setShowActionOne] = useState( true);
    const [showActionTwo, setShowActionTwo] = useState( false);
    const [showActionThree, setShowActionThree] = useState( false);

    const handleActionButton1 = () => {
        let action = props.gameClaim?.parameters?.resultScreen?.actionOne?.action;
        let value = props.gameClaim?.parameters?.resultScreen?.actionOne?.value;
        props.handleAction( action, value );
    }

    const handleActionButton2 = () => {
        let action = props.gameClaim?.parameters?.resultScreen?.actionTwo?.action;
        let value = props.gameClaim?.parameters?.resultScreen?.actionTwo?.value;
        props.handleAction( action, value );
    }

    const handleActionButton3 = () => {
        let action = props.gameClaim?.parameters?.resultScreen?.actionThree?.action;
        let value = props.gameClaim?.parameters?.resultScreen?.actionThree?.value;
        props.handleAction( action, value );
    }

    //const action1Ref = useRef( <div id="ai_win_screen_button_1" onClick={handleActionButton1}>Action One</div> );

    /** Sets the result css from the game claim */
    useEffect(() => {
        if ( !empty(props.gameClaim) && !empty(props.promotion) ) {
            setShowTitle( props.gameClaim?.parameters?.resultScreen?.showTitle );
            setShowResultCopy( props.gameClaim?.parameters?.resultScreen?.showResultCopy );
            setShowCopy( props.gameClaim?.parameters?.resultScreen?.showCopy );
            setShowActionOne( props.gameClaim?.parameters?.resultScreen?.actionOne?.show );
            setShowActionTwo( props.gameClaim?.parameters?.resultScreen?.actionTwo?.show );
            setShowActionThree( props.gameClaim?.parameters?.resultScreen?.actionThree?.show );
        }
    }, [props.gameClaim, props.promotion]);

    {/** Put in copy based on show flags */}
    useEffect(() => {
        if ( showTitle && !empty(props.promotion) ) {
            let aiWinScreenTitle = getJsonContent(props.promotion, GC.RESULT_INTEGRATION, "title", "<h2>DEFAULT TITLE</h2>", props.entry );
            setContent("ai_win_screen_title", aiWinScreenTitle);
        }
    }, [showTitle]);
    useEffect(() => {
        if ( showResultCopy && !empty(props.promotion) && !empty(props.gameClaim) ) {
            let resultCopy = getJsonResultCopy(props.promotion, GC.RESULT_INTEGRATION, props.gameClaim, "<h2>ERROR</h2>", props.entry );
            setContent("ai_win_screen_result_copy", resultCopy);
        }
    }, [showResultCopy]);
    useEffect(() => {
        if ( showCopy && !empty(props.promotion) ) {
            let aiWinScreenCopy = getJsonContent(props.promotion, GC.RESULT_INTEGRATION, "copy", "<h2>DEFAULT COPY</h2>", props.entry );
            setContent("ai_win_screen_copy", aiWinScreenCopy);
        }
    }, [showCopy]);
    useEffect(() => {
        if ( showActionOne && !empty(props.promotion) ) {
            let aiWinScreenButton1 = getJsonContent(props.promotion, GC.RESULT_INTEGRATION, "button1", "<h2>DEFAULT BTN 1</h2>", props.entry );
            setContent("ai_win_screen_button_1", aiWinScreenButton1);
//action1Ref.current.click.handler = handleActionButton1;
        }
    }, [showActionOne]);
    useEffect(() => {
        if ( showActionTwo && !empty(props.promotion) ) {
            let aiWinScreenButton2 = getJsonContent(props.promotion, GC.RESULT_INTEGRATION, "button2", "<h2>DEFAULT BTN 2</h2>", props.entry );
            setContent("ai_win_screen_button_2", aiWinScreenButton2);
        }
    }, [showActionTwo]);
    useEffect(() => {
        if ( showActionThree && !empty(props.promotion) ) {
            let aiWinScreenButton3 = getJsonContent(props.promotion, GC.RESULT_INTEGRATION, "button3", "<h2>DEFAULT BTN 3</h2>", props.entry );
            setContent("ai_win_screen_button_3", aiWinScreenButton3);
        }
    }, [showActionThree]);
    {/** END of put in copy based on show flags */}

    // RETURN THE GAME RESULT
    return (
        <div id='win_app_integration' >
            {showTitle && <div id="ai_win_screen_title">Title One</div>}
            {showCopy && <div id="ai_win_screen_copy">Copy One</div>}
            {showResultCopy && <div id="ai_win_screen_result_copy">Result Copy</div>}
            {showActionOne && <div id="ai_win_screen_button_1" onClick={handleActionButton1}>Action One</div>}
            {showActionTwo && <div id="ai_win_screen_button_2" onClick={handleActionButton2}>Action Two</div>}
            {showActionThree && <div id="ai_win_screen_button_3" onClick={handleActionButton3}>Action Three</div>}

            <WinningAnimationWrapper
                promotion={props.promotion}
                animationGo={props.animationGo}
            />

        </div>
    );
}

export default WinAppIntegration;