import React, {useEffect, useState} from 'react';

var sanitizeHtml = require('sanitize-html');

export function Answer( props ) {
	
	useEffect(() => {
		let cleanedAnswer = sanitizeHtml( props.answer, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'style' ])
		});
		document.getElementsByClassName(props.questionKey + "-" + props.answerKey)[0].innerHTML = cleanedAnswer;
	});
	
	return (
		<div 
			id="div-answer" 
			className={props.questionKey + "-" + props.answerKey}
			onClick={props.handleChoice} 
			data-answer-key={props.answerKey} 
			data-is-correct={props.isCorrect}
		>
			
		</div>
	);
	
}

export default Answer;

