import React, {useEffect, useState} from "react";

import {WinningAnimation} from "./WinningAnimation";
import {empty, logIt} from "../../../../__globals/global-functions";
import {addCSSClass} from "../../../../__globals/css-functions";

export const WinningAnimationWrapper = props => {

    logIt( "WINNING ANIMATION WRAPPER IS CALLED", null, false );

    const [winningLottieJson, setWinningLottieJson] = useState();

    const [winningAnimationOn, setWinningAnimationOn] = useState(props.promotion.configuration.config?.games?.winningAnimation?.animationOn || false);
    const [winningAnimationType, setWinningAnimationType] = useState(props.promotion.configuration.config?.games?.winningAnimation?.animationType || false);

    const [winningAnimationLoop, setWinningAnimationLoop] = useState(props.promotion.configuration.config?.games?.winningAnimation?.isLottie.loop || false);
    const [winningAnimationFadeTime, setWinningAnimationFadeTime] = useState(props.promotion.configuration.config?.games?.winningAnimation?.isLottie.animationFadeTime || 5000);
    const [winningAnimationWidth, setWinningAnimationWidth ] = useState(props.promotion.configuration.config?.games?.winningAnimation?.isLottie.width || 100);
    const [winningAnimationHeight, setWinningAnimationHeight ] = useState(props.promotion.configuration.config?.games?.winningAnimation?.isLottie.height || 100);

    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < 1024
    );

    useEffect(() => {
        if ( props.animationGo === true ) {
            setTimeout(function() {
                addCSSClass( "dv_lottie_winning_animation", "fade-out" );
            }, winningAnimationFadeTime );
        }
    }, [props.animationGo] );

    /** Sets up winning animation */
    useEffect(() => {
        let retrieveUrl = `https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/json/animation/lottie/desktop/winning-animation.json`;
        if ( isMobile ) {
            retrieveUrl = `https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/json/animation/lottie/phone/winning-animation.json`;
        }
        if ( !empty(props.promotion) ) {
            (async () => {
                const response = await fetch(
                    retrieveUrl
                );
                const parsed = await response.json();
                setWinningLottieJson(parsed);
            })();
        }
    }, [props.promotion]);

    return (
        <div id="dv_winning_animation">
            <div id="dv_lottie_winning_animation">
                {(winningAnimationOn && (winningAnimationType === "lottie") && winningLottieJson && props.animationGo) ? (
                    <div id="dv_animation">
                        <WinningAnimation
                            loop={winningAnimationLoop}
                            winningLottieJson={winningLottieJson}
                            width={winningAnimationWidth}
                            height={winningAnimationHeight}
                        />
                    </div>
                ) : (
                    <div id="dv_no_animation"></div>
                )}
            </div>
        </div>
    );
}
export default WinningAnimationWrapper;