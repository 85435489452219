export function useCssAnimation( promoConfig ) {
    if ( promoConfig?.games?.gameIntroAnimation?.animationOn === true ) {
        if (promoConfig?.games?.gameIntroAnimation?.animationType === "css") {
            return true;
        }
    }
    if ( promoConfig?.games?.winningAnimation?.animationOn === true ) {
        if (promoConfig?.games?.winningAnimation?.animationType === "css") {
            return true;
        }
    }
    if ( promoConfig?.games?.cardAnimation?.animationOn === true ) {
        if ( promoConfig?.games?.cardAnimation?.animationType === "css" ) {
            return true;
        }
    }
    return false;
}