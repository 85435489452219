import React, { useState, useEffect } from 'react';
import {getRandomUrlAppendage} from "__globals/global-functions";

var addOn = getRandomUrlAppendage("rd");

export const ProviderImage = props => {
    return (
        <React.Fragment>
            {props.promotion?.configuration && <div id="dv_provider_image">
                <img
                    src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/provider_images/${props.providerImageName}${addOn}`}/>
            </div>}
        </React.Fragment>
    );

}