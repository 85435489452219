import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { 
	empty
} from '__globals/global-functions';

export const OptIn = props => {

	/* LOCAL VARIABLES */
	const [currentValue, setCurrentValue] = useState(false);
	const onChangeCurrentValue = e => { setCurrentValue(e.target.checked) }

	const [tabIndex, setTabIndex] = useState(1);
	
	const [placeholder, setPlaceholder] = useState("Do you want to opt-in");
	
	const [errorMsgRequired, setErrorMsgRequired] = useState("Please check the opt-in box.");
	
	const [required, setRequired] = useState(false);

	/* TODO: NEED TO ADD DEFAULT CHECKED TO THE OPT IN */

	/* FUNCTIONS */
	/* Checks the validations for this element */
	const checkValidation = () => {
		if ( required ) {
			if ( !currentValue ) {
				return errorMsgRequired;
			} else {
				return null;
			}
		}
	}
	
	/* HOOKS */
	/* Check validation and send data to the parent whenever the currentValue changes */
	useEffect(() => {
		let valMessage = checkValidation();
		let isValid = true;
		if ( !empty(valMessage) ) {
			isValid = false;
		}
		let vJSON = {};
		vJSON.name = props.name;
		vJSON.val = currentValue;
		vJSON.isValid = isValid;
		vJSON.valMessage = valMessage;
		props.updateFormValues( vJSON );
	}, [currentValue, errorMsgRequired] );

	/* Set additional fields from the promotion "fieldsInUse" config */
	useEffect(() => {
		if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
			if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
				setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
				setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].required) ) {
				setRequired( props.fieldsInUse[props.name].required );
			}
			if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
				setTabIndex( props.fieldsInUse[props.name].tabIndex );
			}
			if ( !empty(props.fieldsInUse[props.name].defaultChecked) ) {
				setCurrentValue( props.fieldsInUse[props.name].defaultChecked );
			}
		}
	}, [props.fieldsInUse] );

	return (
		<div id={"dv_" + props.name} className={"cls-" + props.name}>
			<label className="checkboxStyle">
				<div id={"dv_" + props.name + "_override"}>{placeholder}</div>
				<input
					name={props.name}
					type="checkbox"
					tabIndex={tabIndex}
					value={currentValue}
					onChange={onChangeCurrentValue}
					checked={currentValue}
				/><span className="checkmark"></span>
			</label>
		</div>
	);
};

export default OptIn;


