import React, { useEffect, useState } from 'react';

import { 
	empty
} from '__globals/global-functions';

export const State = props => { 
	
	/* LOCAL VARIABLES */
	const [currentValue, setCurrentValue] = useState('');
	const onChangeCurrentValue = e => { setCurrentValue(e.target.value) }

	const [tabIndex, setTabIndex] = useState(4);
	
	const [placeholder, setPlaceholder] = useState("STATE");
	
	const [errorMsgRequired, setErrorMsgRequired] = useState("Please include your state.");
	
	const [required, setRequired] = useState(true);
	
	const [statesConfig, setStatesConfig] = useState( props.statesConfig );

	
	/* FUNCTIONS */
	/* Checks the validations for this element */
	const checkValidation = () => {
		if ( required ) {
			if ( empty(currentValue) ) {
				return errorMsgRequired;
			} else {
				return null;
			}
		}
	}
	

	/* HOOKS */
	/* Check validation and send data to the parent whenever the currentValue changes */
	useEffect(() => {
		let valMessage = checkValidation();
		let isValid = true;
		if ( !empty(valMessage) ) {
			isValid = false;
		}
		let vJSON = {};
		vJSON.name = props.name;
		vJSON.val = currentValue;
		vJSON.isValid = isValid;
		vJSON.valMessage = valMessage;
		props.updateFormValues( vJSON );
	}, [currentValue, errorMsgRequired] );
	
	/* Set additional fields from the promotion "fieldsInUse" config */
	useEffect(() => {
		if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
			if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
				setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
				setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].required) ) {
				setRequired( props.fieldsInUse[props.name].required );
			}
			if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
				setTabIndex( props.fieldsInUse[props.name].tabIndex );
			}
		}
	}, [props.fieldsInUse] );
	
	return (
		<div id={"dv_" + props.name} className={"cls-" + props.name}>
			<div id="state-select" className="select-wrapper">
				<select 
					className="NOTselected" 
					id="select" 
					name={props.name} 
					tabIndex={tabIndex}
					value={currentValue} 
					onChange={onChangeCurrentValue} 
					required>
	    				{ (!statesConfig.canadianProvinces) && <option value=''>{placeholder}</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces) && <option value=''>STATE/PROVINCE</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces) && <option value=''>-- US STATES --</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("AL,") >= 0) && <option value='AL'>Alabama</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("AK,") >= 0) && <option value='AK'>Alaska</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("AZ,") >= 0) && <option value='AZ'>Arizona</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("AR,") >= 0) && <option value='AR'>Arkansas</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("CA,") >= 0) && <option value='CA'>California</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("CO,") >= 0) && <option value='CO'>Colorado</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("CT,") >= 0) && <option value='CT'>Connecticut</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("DE,") >= 0) && <option value='DE'>Delaware</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("DC,") >= 0) && <option value='DC'>District Of Columbia</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("FL,") >= 0) && <option value='FL'>Florida</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("GA,") >= 0) && <option value='GA'>Georgia</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("HI,") >= 0) && <option value='HI'>Hawaii</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("ID,") >= 0) && <option value='ID'>Idaho</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("IL,") >= 0) && <option value='IL'>Illinois</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("IN,") >= 0) && <option value='IN'>Indiana</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("IA,") >= 0) && <option value='IA'>Iowa</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("KS,") >= 0) && <option value='KS'>Kansas</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("KY,") >= 0) && <option value='KY'>Kentucky</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("LA,") >= 0) && <option value='LA'>Louisiana</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("ME,") >= 0) && <option value='ME'>Maine</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MD,") >= 0) && <option value='MD'>Maryland</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MA,") >= 0) && <option value='MA'>Massachusetts</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MI,") >= 0) && <option value='MI'>Michigan</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MN,") >= 0) && <option value='MN'>Minnesota</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MS,") >= 0) && <option value='MS'>Mississippi</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MO,") >= 0) && <option value='MO'>Missouri</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("MT,") >= 0) && <option value='MT'>Montana</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NE,") >= 0) && <option value='NE'>Nebraska</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NV,") >= 0) && <option value='NV'>Nevada</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NH,") >= 0) && <option value='NH'>New Hampshire</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NJ,") >= 0) && <option value='NJ'>New Jersey</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NM,") >= 0) && <option value='NM'>New Mexico</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NY,") >= 0) && <option value='NY'>New York</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("NC,") >= 0) && <option value='NC'>North Carolina</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("ND,") >= 0) && <option value='ND'>North Dakota</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("OH,") >= 0) && <option value='OH'>Ohio</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("OK,") >= 0) && <option value='OK'>Oklahoma</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("OR,") >= 0) && <option value='OR'>Oregon</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("PA,") >= 0) && <option value='PA'>Pennsylvania</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("RI,") >= 0) && <option value='RI'>Rhode Island</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("SC,") >= 0) && <option value='SC'>South Carolina</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("SD,") >= 0) && <option value='SD'>South Dakota</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("TN,") >= 0) && <option value='TN'>Tennessee</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("TX,") >= 0) && <option value='TX'>Texas</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("UT,") >= 0) && <option value='UT'>Utah</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("VT,") >= 0) && <option value='VT'>Vermont</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("VA,") >= 0) && <option value='VA'>Virginia</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("WA,") >= 0) && <option value='WA'>Washington</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("WV,") >= 0) && <option value='WV'>West Virginia</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("WI,") >= 0) && <option value='WI'>Wisconsin</option> }
	    				{ (statesConfig.allStates || statesConfig.statesSubset.indexOf("WY,") >= 0) && <option value='WY'>Wyoming</option> }

	    				{ (statesConfig.allStates && statesConfig.canadianProvinces) && <option value=''>-- CANADIAN PROVINCES --</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("AB,") >= 0) && <option value="AB">Alberta</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("BC,") >= 0) && <option value="BC">British Columbia</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("MB,") >= 0) && <option value="MB">Manitoba</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("NB,") >= 0) && <option value="NB">New Brunswick</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("NL,") >= 0) && <option value="NL">Newfoundland and Labrador</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("NS,") >= 0) && <option value="NS">Nova Scotia</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("NT,") >= 0) && <option value="NT">Northwest Territories</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("NU,") >= 0) && <option value="NU">Nunavut</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("ON,") >= 0) && <option value="ON">Ontario</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("PE,") >= 0) && <option value="PE">Prince Edward Island</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("QC,") >= 0) && <option value="QC">Quebec</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("SK,") >= 0) && <option value="SK">Saskatchewan</option> }
	    				{ (statesConfig.allStates && statesConfig.canadianProvinces && statesConfig.canadianProvincesSubset.indexOf("YT,") >= 0) && <option value="YT">Yukon</option> }


    			</select>
    		</div>
	    </div>	
	);
		

}

export default State;

