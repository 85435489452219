import React, {useEffect, useState} from 'react';

import WinAppIntegration from "../WinningResults/WinAppIntegration";
import WinStandard from "../WinningResults/WinStandard";
import WinRedeemForm from "../WinningResults/WinRedeemForm";
import WinSendDigitalPrize from "../WinningResults/WinSendDigitalPrize";

import LoseStandard from "../LosingResults/LoseStandard";
import LoseAppIntegration from "../LosingResults/LoseAppIntegration";
import LoseOffer from "../LosingResults/LoseOffer";

import {empty} from "__globals/global-functions";

import * as GC from '__globals/GLOBAL-CONSTANTS.js';


export const _IWGameResult = props => {

    // LOCAL VARIABLES
    const [gameResult, setGameResult] = useState( '');

    /** Sets game result variable from game claim */
    useEffect(() => {
        if ( !empty(props.gameClaim) ) {
            setGameResult( props.gameClaim.parameters.result );
        }
    }, [props.gameClaim] );

    // RETURN THE GAME RESULT BASED ON GAME CLAIM
    return (
        <div id="game_results_options">

            {/** Win with standard actions */}
            {( gameResult === GC.RESULT_STANDARD) && <WinStandard promotion={props.promotion} gameClaim={props.gameClaim} handleAction={props.handleAction} entry={props.entry} animationGo={props.animationGo}/>}

            {/** Win with redeem form */}
            { ( gameResult === GC.RESULT_FORM) && <WinRedeemForm promotion={props.promotion} gameClaim={props.gameClaim} handleAction={props.handleAction} showForm={props.showForm} entry={props.entry}  animationGo={props.animationGo} /> }

            {/** Win and send a digital prize - ecard or ecoupon */}
            { ( gameResult === GC.RESULT_DIGITAL) && <WinSendDigitalPrize promotion={props.promotion} gameClaim={props.gameClaim} variables={props.entry.variables} handleAction={props.handleAction} entry={props.entry}  animationGo={props.animationGo}/> }

            {/** Win with external app integration */}
            {( gameResult === GC.RESULT_INTEGRATION) && <WinAppIntegration promotion={props.promotion} gameClaim={props.gameClaim} handleAction={props.handleAction} entry={props.entry}  animationGo={props.animationGo}/>}

            {/** ************ */}

            {/** Lose with standard actions */}
            {( gameResult === GC.RESULT_LOSE) && <LoseStandard promotion={props.promotion} gameClaim={props.gameClaim} handleAction={props.handleAction} entry={props.entry} />}

            {/** Lose with external app integration */}
            {( gameResult === GC.RESULT_LOSE_INTEGRATION) && <LoseAppIntegration promotion={props.promotion} gameClaim={props.gameClaim} handleAction={props.handleAction} entry={props.entry} />}

            {/** Lose with external app integration */}
            {( gameResult === GC.RESULT_LOSE_OFFER) && <LoseOffer promotion={props.promotion} gameClaim={props.gameClaim} handleAction={props.handleAction} entry={props.entry} />}

            {/** TODO: Think about thank you page and email as options in each one */}

        </div>
    );

}

export default _IWGameResult;