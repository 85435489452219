import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import {
    empty
} from '__globals/global-functions';

export const AutoOptIn = props => {

    /* LOCAL VARIABLES */
    const [currentValue, setCurrentValue] = useState(true);
    const onChangeCurrentValue = e => { setCurrentValue(e.target.value) }

    const [tabIndex, setTabIndex] = useState(1);

    // TOD: Need link here
    const [placeholder, setPlaceholder] = useState("By entering this promotion, you agree to receive communications from the sponsor.  You may unsubscribe at any time.");

    const [errorMsgRequired, setErrorMsgRequired] = useState("You must automatically opt in to receive a communication from the sponsor.");

    const [required, setRequired] = useState(true);

    /* FUNCTIONS */
    /* Checks the validations for this element */
    const checkValidation = () => {
        if ( required ) {
            if ( !currentValue ) {
                return errorMsgRequired;
            } else {
                return null;
            }
        }
    }

    /* HOOKS */
    /* Check validation and send data to the parent whenever the currentValue changes */
    useEffect(() => {
        let valMessage = checkValidation();
        let isValid = true;
        if ( !empty(valMessage) ) {
            isValid = false;
        }
        let vJSON = {};
        vJSON.name = props.name;
        vJSON.val = currentValue;
        vJSON.isValid = isValid;
        vJSON.valMessage = valMessage;
        props.updateFormValues( vJSON );
    }, [currentValue, errorMsgRequired] );

    /* Set additional fields from the promotion "fieldsInUse" config */
    useEffect(() => {
        if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
            if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
                setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
            }
            if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
                setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
            }
            if ( !empty(props.fieldsInUse[props.name].required) ) {
                setRequired( props.fieldsInUse[props.name].required );
            }
            if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
                setTabIndex( props.fieldsInUse[props.name].tabIndex );
            }
        }
    }, [props.fieldsInUse] );

    return (
        <div id={"dv_" + props.name} className={"cls-" + props.name}>
            <label className="checkboxStyle">
                <div id={"dv_" + props.name + "_override"}>{placeholder}</div>
            </label>
        </div>
    );
};

export default AutoOptIn;


