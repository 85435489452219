import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import {
	empty,
	showHiderules
} from '__globals/global-functions';
import {current} from "@reduxjs/toolkit";

export const ReadRules = props => {

	/* LOCAL VARIABLES */
	const [currentValue, setCurrentValue] = useState(false);
	const onChangeCurrentValue = e => { setCurrentValue(e.target.checked) }

	const [tabIndex, setTabIndex] = useState(1);

	// TOD: Need link here
	const [placeholder, setPlaceholder] = useState("I have read and agree to the Official Rules");

	const [errorMsgRequired, setErrorMsgRequired] = useState("You must check that you have read and agree to the official rules.");

	const [required, setRequired] = useState(true);

	/* FUNCTIONS */
	/* Checks the validations for this element */
	const checkValidation = () => {
		if ( required ) {
			if ( !currentValue ) {
				return errorMsgRequired;
			} else {
				return null;
			}
		}
	}

	/* HOOKS */
	/* Check validation and send data to the parent whenever the currentValue changes */
	useEffect(() => {
		let valMessage = checkValidation();
		let isValid = true;
		if ( !empty(valMessage) ) {
			isValid = false;
		}
		let vJSON = {};
		vJSON.name = props.name;
		vJSON.val = currentValue;
		vJSON.isValid = isValid;
		vJSON.valMessage = valMessage;
		props.updateFormValues( vJSON );
	}, [currentValue, errorMsgRequired] );

	/* Set additional fields from the promotion "fieldsInUse" config */
	useEffect(() => {
		if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
			if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
				setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
				setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].required) ) {
				setRequired( props.fieldsInUse[props.name].required );
			}
			if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
				setTabIndex( props.fieldsInUse[props.name].tabIndex );
			}
		}
	}, [props.fieldsInUse] );

	return (
		<div id={"dv_" + props.name} className={"cls-" + props.name} onClick={showHiderules}>
			<label className="checkboxStyle">
				<div id={"dv_" + props.name + "_override"}>{placeholder}</div>
				<input
					name={props.name}
					type="checkbox"
					tabIndex={tabIndex}
					value={currentValue}
					onChange={onChangeCurrentValue}
					defaultChecked={currentValue}
				/><span className="checkmark"></span>
			</label>
		</div>
	);
};

export default ReadRules;


