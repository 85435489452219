import React, {useEffect, useState} from 'react';

import Question from './Question/Question';
import Answer from './Answer/Answer';
import Selection from './Selection/Selection';

export function QuestionGroup( props ) {
	
	const getAnswers = () => {
		let currentAnswers = props.questionGroup.questions[props.questionIndex].answers;
		let qKey = props.questionGroup.questions[props.questionIndex].key;
		let answers = currentAnswers.sort((item1, item2) => item1.index - item2.index)
		.map(answer => {
			//alert(qKey + "-" + answer.key)
			return (
				<Answer 
					questionKey={qKey}
					answerKey={qKey + "-" + answer.key}
					answer={answer.copy}
					handleChoice={props.handleChoice}
					isCorrect={answer.isCorrect}
					questionIndex={props.questionIndex}
				/>
			);	
		});
		return answers;
	}
	return (
		<div id="div-question-group">
			{(props.mode === "question") ? (
				<div id="div-question-answer">
					<Question 
						questionKey={props.questionKey}
						question={props.question}
						questionIndex={props.questionIndex}
					/>
					<div id="div-answers">
						{getAnswers()}
					</div>
				</div>
			) : (
				<Selection
					mode={props.mode}
					questionKey={props.questionKey}
					question={props.question}
					questionIndex={props.questionIndex}
					answer={props.answer}
					answerKey={props.answerKey}
				/>
			)}
		
		

		</div>
	);
	
}

export default QuestionGroup;

