import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";


import {
    empty, inspectFormData,
    logIt
} from '__globals/global-functions';
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {processErrMsgDisplay, uploadFile} from "../../../_features/entries/entry-slice";
import {addCSSClass, removeCSSClass} from "../../../__globals/css-functions";

export const FileUpload = props => {

    logIt(GC.PAGE_FILE_UPLOAD + " PAGE IS CALLED", null, false);
    const PAGE_DIV_NAME = "file_upload"; // use underscores
    const PAGE_KEY = "fileUpload"; // use camel case
    const PAGE_IMAGE_KEY = "file-upload"; // use dashes
    const PAGE_JSON_KEY = "FileUpload"; // use init caps

    const dispatch = useDispatch();

    //const state = useSelector( state => state );

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_FILE_UPLOAD, promotion, true );

    const entry = useSelector(state => state.entry);
    logIt( "ENTRY AT " + GC.PAGE_FILE_UPLOAD, entry, true );

    const entryUpdated = useSelector(state => state.entry.entryUpdated, false);
    logIt( "ENTRY UPDATED AT " + GC.PAGE_FILE_UPLOAD, entry, true );

    let error = useSelector(state => state.entry.error);
    logIt( "ERROR AT " + GC.PAGE_FILE_UPLOAD, error, true );

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const [fileName, setFileName] = useState(undefined);
    const [buttonLock, setButtonLock] = useState(false);

    const setErrorMsgDisplay = (errMsg) => {
        dispatch( processErrMsgDisplay(errMsg) );
    }

    const doAction = (a, v) => {
        if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch(executeAction(a, v));
        }
    }

    const handleFileSelection = () => {
        var fileName = document.querySelector('input[type="file"]').value;
        if ( fileName !== undefined && fileName !== '' ) {
            fileName = fileName.substring(fileName.lastIndexOf("\\") + 1);
        }
        setFileName(fileName);
    }

    const onFormSubmit = (data) => {
        if ( !validateUpload() ) {
            return;
        }
        if ( buttonLock ) {
            return;
        }
        setButtonLock( true );
        // TODO - switch image
        let formData = new FormData();
        var fileData = document.querySelector('input[type="file"]').files[0];
        formData.append("file", fileData);
//    	formData.append("caption", caption);
//    	formData.append("usageRights", usageRights);
//    	formData.append("originalFileName", fileName);
        formData.append("name", "fileUpload"); // was 'galleryImage'
        inspectFormData( formData );
        let entryObj = {
            entry: {
                // "caption": caption,
                // "usageRights": usageRights,
                "originalFileName": fileName,
            }
        }
        dispatch( uploadFile(formData, entryObj) );
    }
    const validateUpload = () => {
        let valMessage = validate( "upload" );
        if ( valMessage !== "" ) {
            setErrorMsgDisplay( valMessage );
            return false;
        }
        return true;
    }

    const validate = (dialog) => {

        // file name
        if ( dialog === "upload" ) {
            removeCSSClass("file", "form-error");
            if ( fileName === '' || fileName === undefined ) {
                addCSSClass("file", "form-error");
                //return fieldsInUse.uploadPage.fileName.errorMsg[currentLanguage];
                return "Please choose a file to upload";
            }
        }
        // caption
        // if ( dialog === "upload" ) {
        //     removeCSSClass("caption", "form-error");
        //     if ( caption === '' || caption === undefined ) {
        //         addCSSClass("caption", "form-error");
        //         return fieldsInUse.uploadPage.caption.errorMsg[currentLanguage];
        //     }
        // }
        // usage rights
        // if ( dialog === "upload" ) {
        //     removeCSSClass("usage_rights", "form-error");
        //     if ( usageRights === false || usageRights === undefined ) {
        //         addCSSClass("usage_rights", "form-error");
        //         return fieldsInUse.uploadPage.usageRights.errorMsg[currentLanguage];
        //     }
        // }
        return "";
    }

    useEffect(() => {
        //alert("in")
        if ( !empty(error) && !empty(error.errors) && !empty(error.errors[0]?.message) ) {
            //alert(error.errors[0]?.message)
            setErrorMsgDisplay( error.errors[0].message );
        }
    }, [error]);

    useEffect(() => {
        //alert("in")
        if ( entryUpdated ) {
            setGoNext( true );
        }
    }, [entryUpdated]);

    if (goNext) {

        return (<PageFlow page={GC.PAGE_FILE_UPLOAD} error={null} action={action} value={value}/>);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />

                                    <form encType="multipart/form-data" action="">

                                        <div className='upload'>
                                            <label className='file'>
                                                <input type='file' id='file' aria-label="FILE UPLOAD"
                                                       onChange={handleFileSelection}/>
                                                <span className='file-custom'>{fileName}</span>
                                            </label>
                                        </div>

                                        <div className='form-submit' onClick={onFormSubmit.bind(this)}>
                                            {!buttonLock && <div className='view-desktop'><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/btn-form-upload.png`} /></div>}
                                            {!buttonLock && <div className='view-mobile'><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/btn-form-upload.png`} /></div>}
                                            {buttonLock && <div className='view-desktop'><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/btn-form-loading.png`} /></div>}
                                            {buttonLock && <div className='view-mobile'><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/btn-form-loading.png`} /></div>}
                                        </div>

                                        { !empty(entry.errorMsgDisplay) ? (
                                            <div class="error">{entry.errorMsgDisplay}</div>
                                        ) : (
                                            <div class="error"></div>
                                        )}

                                    </form>

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                </div>

                                <PromocracyLogo promotion={promotion}/>

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );

    }

}