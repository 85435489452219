import axios from 'axios';
import {	
	logIt,
	inspectFormData 
} from '__globals/global-functions';

//const hostName = window.REACT_APP_AUTHENTICATION_DOMAIN;
const promotionDomain = window.REACT_APP_PROMOTION_DOMAIN;
const protocol = "https://";


export async function postSendEmail( emailJSON )
{
    const url = protocol + promotionDomain + '/send-email';
    const {data} = await axios.post(url, emailJSON);
    return data;
}

export async function postValidationEmailEntry( emailJSON )
{
    const url = protocol + promotionDomain + '/send-verify-email';
    const {data} = await axios.post(url, emailJSON);
    return data;
}

