import React, { useState, useCallback } from 'react';
import { AdvancedMarker, useAdvancedMarkerRef, InfoWindow, Marker } from '@vis.gl/react-google-maps';

const MarkerWithInfoWindow = ({ position, name }) => {
    // `markerRef` and `marker` are needed to establish the connection between
    // the marker and infowindow (if you're using the Marker component, you
    // can use the `useMarkerRef` hook instead).
    const [markerRef, marker] = useAdvancedMarkerRef();

    const [infoWindowShown, setInfoWindowShown] = useState(false);

    // clicking the marker will toggle the infowindow
    const handleMarkerClick = useCallback(() => setInfoWindowShown(isShown => !isShown),
        []);

    // if the maps api closes the infowindow, we have to synchronize our state
    const handleClose = useCallback(() => setInfoWindowShown(false), []);

    return (
        <>
            <Marker position={position} ref={markerRef} onClick={handleMarkerClick} />
            {infoWindowShown && (
                <InfoWindow anchor={marker} onClose={handleClose}>
                    <h2>{name}</h2>
                </InfoWindow>
            )}
        </>
    );
};

export default MarkerWithInfoWindow