import React, { useState, useEffect } from 'react';
import {getRandomUrlAppendage} from "__globals/global-functions";

var addOn = getRandomUrlAppendage("rd");

export const PromocracyLogo = props => {

    return (
        <React.Fragment>
            {props.promotion?.variables?.promocracyOn && <div id="dv_promocracy-logo">
                <img
                    src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${props.promotion.configuration.name}/images/promocracy_logos/${props.promotion.variables.promocracyLogoImage}${addOn}`}/>
            </div>}
        </React.Fragment>
    );

}